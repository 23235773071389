// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


import '../../assets/stylesheets/stylesheets.js'

require('bootstrap');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)


//Report Markets adding inputs
document.addEventListener("DOMContentLoaded", function () {
    const addButton = document.getElementById('add-market-btn');
    const dynamicFieldsContainer = document.getElementById('dynamic-report-market-fields');

    if (addButton && dynamicFieldsContainer) {
        addButton.addEventListener('click', function () {
            const maxInputs = 8;
            const currentInputs = dynamicFieldsContainer.querySelectorAll('.market-fields').length;

            // Check if the form is in "create" mode
            const isNewReportForm = document.querySelector('form').getAttribute('action').includes('new');

            if (currentInputs < maxInputs && !isNewReportForm) {
                const timestamp = Date.now(); // Get current timestamp
                const content = `
                    <div class="market-fields mb-3">
                        <h4>Input ${timestamp}</h4>
                        <div class="mb-3 form-label">
                            <label for="report_report_markets_attributes_${timestamp}_name">Name</label>
                            <input type="text" id="report_report_markets_attributes_${timestamp}_name" name="report[report_markets_attributes][${timestamp}][name]" class="form-control" required>
                        </div>
                        <div class="mb-3 form-label">
                            <label for="report_report_markets_attributes_${timestamp}_value">Value</label>
                            <input type="text" id="report_report_markets_attributes_${timestamp}_value" name="report[report_markets_attributes][${timestamp}][value]" class="form-control" required>
                        </div>
                        <div class="mb-3 form-label">
                            <label for="report_report_markets_attributes_${timestamp}_additional">Additional Info</label>
                            <input type="text" id="report_report_markets_attributes_${timestamp}_additional" name="report[report_markets_attributes][${timestamp}][additional]" class="form-control">
                        </div>
                        <button type="button" class="btn btn-close remove-market mb-5"></button>
                    </div>
                `;
                dynamicFieldsContainer.insertAdjacentHTML('beforeend', content);

                if (currentInputs + 1 === maxInputs) {
                    addButton.disabled = true;
                }
                updateInputCount(); // Call function to update input count after adding new input
            }
        });

        dynamicFieldsContainer.addEventListener('click', function (event) {
            if (event.target.classList.contains('remove-market')) {
                event.preventDefault();
                event.target.closest('.market-fields').remove();
                addButton.disabled = false;
                updateInputCount(); // Call function to update input count after removing input
            }
        });

        // Function to update input count
        function updateInputCount() {
            const inputs = dynamicFieldsContainer.querySelectorAll('.market-fields h4');
            inputs.forEach((input, idx) => {
                input.textContent = `Input ${idx + 1}`;
            });
        }
    }
});


//Other materials adding inputs
document.addEventListener("DOMContentLoaded", function () {
    const addButton = document.getElementById('add-material-btn');
    const dynamicFieldsContainer = document.getElementById('dynamic-other-material-fields');

    if (addButton && dynamicFieldsContainer) {
        addButton.addEventListener('click', function () {
            const maxInputs = 4;
            const currentInputs = dynamicFieldsContainer.querySelectorAll('.material-fields').length;

            // Check if the form is in "create" mode
            const isNewReportForm = document.querySelector('form').getAttribute('action').includes('new');

            if (currentInputs < maxInputs && !isNewReportForm) {
                const timestamp = Date.now(); // Get current timestamp
                const content = `
                    <div class="material-fields mb-3">
                        <h4>Input ${timestamp}</h4>
                        <div class="mb-3 form-label">
                            <label for="report_other_materials_attributes_${timestamp}_name">Name</label>
                            <input type="text" id="report_other_materials_attributes_${timestamp}_name" name="report[other_materials_attributes][${timestamp}][name]" class="form-control" required>
                        </div>
                        <div class="mb-3 form-label">
                            <label for="report_other_materials_attributes_${timestamp}_url">URL</label>
                            <input type="text" id="report_other_materials_attributes_${timestamp}_url" name="report[other_materials_attributes][${timestamp}][url]" class="form-control" required>
                        </div>
                        <button type="button" class="btn btn-close remove-material mb-5"></button>
                    </div>
                `;
                dynamicFieldsContainer.insertAdjacentHTML('beforeend', content);

                if (currentInputs + 1 === maxInputs) {
                    addButton.disabled = true;
                }
                updateInputCount(); // Call function to update input count after adding new input
            }
        });

        dynamicFieldsContainer.addEventListener('click', function (event) {
            if (event.target.classList.contains('remove-material')) {
                event.preventDefault();
                event.target.closest('.material-fields').remove();
                addButton.disabled = false;
                updateInputCount(); // Call function to update input count after removing input
            }
        });

        // Function to update input count
        function updateInputCount() {
            const inputs = dynamicFieldsContainer.querySelectorAll('.material-fields h4');
            inputs.forEach((input, idx) => {
                input.textContent = `Input ${idx + 1}`;
            });
        }
    }
});


// Generate random url
document.addEventListener("DOMContentLoaded", function () {
    var generateButton = document.getElementById("generate-url-button");
    var urlField = document.getElementById("document_url");

    if (generateButton && urlField) {
        generateButton.addEventListener("click", function () {
            var randomString = Array(15).fill(null).map(() => Math.random().toString(36).charAt(2)).join('');
            urlField.value = randomString;
        });
    }
});


