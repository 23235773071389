document.addEventListener('DOMContentLoaded', () => {
    loadApexCharts();
});

let loadApexCharts = () => {
    if (document.getElementById('apexcharts-ChartIvyMoney')) {
        loadApexChart(
            'ChartIvyMoney',
            'ivy money',
            '#43d85c',
            '#43d85c',
            '#3c914a',
            'smooth',
            null,
            [
                ['2021-06-24', -0.002],
                ['2021-06-25', -0.004],
                ['2021-06-26', -0.006],
                ['2021-06-27', -0.008],
                ['2021-06-28', -0.01],
                ['2021-06-29', -0.012],
                ['2021-06-30', -0.013],
                ['2021-07-01', -0.015],
                ['2021-07-02', -0.017],
                ['2021-07-03', -0.019],
                ['2021-07-04', -0.021],
                ['2021-07-05', -0.023],
                ['2021-07-06', -0.025],
                ['2021-07-07', -0.027],
                ['2021-07-08', -0.029],
                ['2021-07-09', -0.031],
                ['2021-07-10', -0.033],
                ['2021-07-11', -0.035],
                ['2021-07-12', -0.036],
                ['2021-07-13', -0.038],
                ['2021-07-14', -0.04],
                ['2021-07-15', -0.042],
                ['2021-07-16', -0.044],
                ['2021-07-17', -0.046],
                ['2021-07-18', -0.048],
                ['2021-07-19', -0.05],
                ['2021-07-20', -0.052],
                ['2021-07-21', -0.054],
                ['2021-07-22', -0.056],
                ['2021-07-23', -0.058],
                ['2021-07-24', -0.059],
                ['2021-07-25', -0.061],
                ['2021-07-26', -0.063],
                ['2021-07-27', -0.065],
                ['2021-07-28', -0.067],
                ['2021-07-29', -0.069],
                ['2021-07-30', -0.071],
                ['2021-07-31', -0.073],
                ['2021-08-01', -0.075],
                ['2021-08-02', -0.077],
                ['2021-08-03', -0.079],
                ['2021-08-04', -0.081],
                ['2021-08-05', -0.082],
                ['2021-08-06', -0.084],
                ['2021-08-07', -0.085],
                ['2021-08-08', -0.086],
                ['2021-08-09', -0.087],
                ['2021-08-10', -0.089],
                ['2021-08-11', -0.09],
                ['2021-08-12', -0.091],
                ['2021-08-13', -0.092],
                ['2021-08-14', -0.094],
                ['2021-08-15', -0.095],
                ['2021-08-16', -0.096],
                ['2021-08-17', -0.097],
                ['2021-08-18', -0.098],
                ['2021-08-19', -0.1],
                ['2021-08-20', -0.101],
                ['2021-08-21', -0.102],
                ['2021-08-22', -0.103],
                ['2021-08-23', -0.105],
                ['2021-08-24', -0.106],
                ['2021-08-25', -0.107],
                ['2021-08-26', -0.108],
                ['2021-08-27', -0.11],
                ['2021-08-28', -0.111],
                ['2021-08-29', -0.112],
                ['2021-08-30', -0.113],
                ['2021-08-31', -0.114],
                ['2021-09-01', -0.116],
                ['2021-09-02', -0.117],
                ['2021-09-03', -0.118],
                ['2021-09-04', -0.119],
                ['2021-09-05', -0.121],
                ['2021-09-06', -0.122],
                ['2021-09-07', -0.123],
                ['2021-09-08', -0.124],
                ['2021-09-09', -0.126],
                ['2021-09-10', -0.127],
                ['2021-09-11', -0.128],
                ['2021-09-12', -0.129],
                ['2021-09-13', -0.13],
                ['2021-09-14', -0.132],
                ['2021-09-15', -0.133],
                ['2021-09-16', -0.134],
                ['2021-09-17', -0.135],
                ['2021-09-18', -0.137],
                ['2021-09-19', -0.138],
                ['2021-09-20', -0.139],
                ['2021-09-21', -0.14],
                ['2021-09-22', -0.142],
                ['2021-09-23', -0.143],
                ['2021-09-24', -0.144],
                ['2021-09-25', -0.145],
                ['2021-09-26', -0.146],
                ['2021-09-27', -0.148],
                ['2021-09-28', -0.149],
                ['2021-09-29', -0.15],
                ['2021-09-30', -0.151],
                ['2021-10-01', -0.151],
                ['2021-10-02', -0.15],
                ['2021-10-03', -0.149],
                ['2021-10-04', -0.148],
                ['2021-10-05', -0.147],
                ['2021-10-06', -0.146],
                ['2021-10-07', -0.146],
                ['2021-10-08', -0.145],
                ['2021-10-09', -0.144],
                ['2021-10-10', -0.143],
                ['2021-10-11', -0.142],
                ['2021-10-12', -0.142],
                ['2021-10-13', -0.141],
                ['2021-10-14', -0.14],
                ['2021-10-15', -0.139],
                ['2021-10-16', -0.138],
                ['2021-10-17', -0.137],
                ['2021-10-18', -0.137],
                ['2021-10-19', -0.136],
                ['2021-10-20', -0.135],
                ['2021-10-21', -0.134],
                ['2021-10-22', -0.133],
                ['2021-10-23', -0.133],
                ['2021-10-24', -0.132],
                ['2021-10-25', -0.131],
                ['2021-10-26', -0.13],
                ['2021-10-27', -0.129],
                ['2021-10-28', -0.128],
                ['2021-10-29', -0.128],
                ['2021-10-30', -0.127],
                ['2021-10-31', -0.126],
                ['2021-11-01', -0.125],
                ['2021-11-02', -0.124],
                ['2021-11-03', -0.123],
                ['2021-11-04', -0.123],
                ['2021-11-05', -0.118],
                ['2021-11-06', -0.114],
                ['2021-11-07', -0.11],
                ['2021-11-08', -0.106],
                ['2021-11-09', -0.101],
                ['2021-11-10', -0.097],
                ['2021-11-11', -0.093],
                ['2021-11-12', -0.089],
                ['2021-11-13', -0.084],
                ['2021-11-14', -0.08],
                ['2021-11-15', -0.076],
                ['2021-11-16', -0.072],
                ['2021-11-17', -0.068],
                ['2021-11-18', -0.063],
                ['2021-11-19', -0.059],
                ['2021-11-20', -0.055],
                ['2021-11-21', -0.051],
                ['2021-11-22', -0.046],
                ['2021-11-23', -0.042],
                ['2021-11-24', -0.038],
                ['2021-11-25', -0.034],
                ['2021-11-26', -0.029],
                ['2021-11-27', -0.025],
                ['2021-11-28', -0.021],
                ['2021-11-29', -0.017],
                ['2021-11-30', -0.012],
                ['2021-12-01', -0.008],
                ['2021-12-02', -0.004],
                ['2021-12-03', 0],
                ['2021-12-04', 0.005],
                ['2021-12-05', 0.009],
                ['2021-12-06', 0.013],
                ['2021-12-07', 0.017],
                ['2021-12-08', 0.022],
                ['2021-12-09', 0.026],
                ['2021-12-10', 0.03],
                ['2021-12-11', 0.034],
                ['2021-12-12', 0.039],
                ['2021-12-13', 0.043],
                ['2021-12-14', 0.047],
                ['2021-12-15', 0.051],
                ['2021-12-16', 0.056],
                ['2021-12-17', 0.06],
                ['2021-12-18', 0.064],
                ['2021-12-19', 0.068],
                ['2021-12-20', 0.073],
                ['2021-12-21', 0.077],
                ['2021-12-22', 0.081],
                ['2021-12-23', 0.088],
                ['2021-12-24', 0.095],
                ['2021-12-25', 0.102],
                ['2021-12-26', 0.109],
                ['2021-12-27', 0.116],
                ['2021-12-28', 0.123],
                ['2021-12-29', 0.13],
                ['2021-12-30', 0.137],
                ['2021-12-31', 0.144],
                ['2022-01-01', 0.151],
                ['2022-01-02', 0.158],
                ['2022-01-03', 0.165],
                ['2022-01-04', 0.172],
                ['2022-01-05', 0.179],
                ['2022-01-06', 0.186],
                ['2022-01-07', 0.193],
                ['2022-01-08', 0.2],
                ['2022-01-09', 0.207],
                ['2022-01-10', 0.214],
                ['2022-01-11', 0.221],
                ['2022-01-12', 0.228],
                ['2022-01-13', 0.235],
                ['2022-01-14', 0.242],
                ['2022-01-15', 0.249],
                ['2022-01-16', 0.256],
                ['2022-01-17', 0.263],
                ['2022-01-18', 0.27],
                ['2022-01-19', 0.277],
                ['2022-01-20', 0.284],
                ['2022-01-21', 0.291],
                ['2022-01-22', 0.298],
                ['2022-01-23', 0.305],
                ['2022-01-24', 0.312],
                ['2022-01-25', 0.319],
                ['2022-01-26', 0.326],
                ['2022-01-27', 0.333],
                ['2022-01-28', 0.34],
                ['2022-01-29', 0.347],
                ['2022-01-30', 0.354],
                ['2022-01-31', 0.361],
                ['2022-02-01', 0.368],
                ['2022-02-02', 0.375],
                ['2022-02-03', 0.382],
                ['2022-02-04', 0.391],
                ['2022-02-05', 0.4],
                ['2022-02-06', 0.409],
                ['2022-02-07', 0.419],
                ['2022-02-08', 0.428],
                ['2022-02-09', 0.437],
                ['2022-02-10', 0.446],
                ['2022-02-11', 0.455],
                ['2022-02-12', 0.464],
                ['2022-02-13', 0.473],
                ['2022-02-14', 0.482],
                ['2022-02-15', 0.491],
                ['2022-02-16', 0.5],
                ['2022-02-17', 0.509],
                ['2022-02-18', 0.518],
                ['2022-02-19', 0.528],
                ['2022-02-20', 0.537],
                ['2022-02-21', 0.546],
                ['2022-02-22', 0.555],
                ['2022-02-23', 0.564],
                ['2022-02-24', 0.573],
                ['2022-02-25', 0.582],
                ['2022-02-26', 0.591],
                ['2022-02-27', 0.6],
                ['2022-02-28', 0.609],
                ['2022-03-01', 0.618],
                ['2022-03-02', 0.628],
                ['2022-03-03', 0.637],
                ['2022-03-04', 0.646],
                ['2022-03-05', 0.655],
                ['2022-03-06', 0.664],
                ['2022-03-07', 0.673],
                ['2022-03-08', 0.682],
                ['2022-03-09', 0.691],
                ['2022-03-10', 0.7],
                ['2022-03-11', 0.709],
                ['2022-03-12', 0.719],
                ['2022-03-13', 0.728],
                ['2022-03-14', 0.737],
                ['2022-03-15', 0.746],
                ['2022-03-16', 0.755],
                ['2022-03-17', 0.764],
                ['2022-03-18', 0.773],
                ['2022-03-19', 0.782],
                ['2022-03-20', 0.791],
                ['2022-03-21', 0.801],
                ['2022-03-22', 0.81],
                ['2022-03-23', 0.819],
                ['2022-03-24', 0.828],
                ['2022-03-25', 0.837],
                ['2022-03-26', 0.846],
                ['2022-03-27', 0.855],
                ['2022-03-28', 0.864],
                ['2022-03-29', 0.873],
                ['2022-03-30', 0.883],
                ['2022-03-31', 0.892],
                ['2022-04-01', 0.902],
                ['2022-04-02', 0.913],
                ['2022-04-03', 0.923],
                ['2022-04-04', 0.934],
                ['2022-04-05', 0.944],
                ['2022-04-06', 0.955],
                ['2022-04-07', 0.965],
                ['2022-04-08', 0.976],
                ['2022-04-09', 0.986],
                ['2022-04-10', 0.997],
                ['2022-04-11', 1.007],
                ['2022-04-12', 1.018],
                ['2022-04-13', 1.028],
                ['2022-04-14', 1.039],
                ['2022-04-15', 1.049],
                ['2022-04-16', 1.06],
                ['2022-04-17', 1.07],
                ['2022-04-18', 1.081],
                ['2022-04-19', 1.091],
                ['2022-04-20', 1.102],
                ['2022-04-21', 1.113],
                ['2022-04-22', 1.123],
                ['2022-04-23', 1.134],
                ['2022-04-24', 1.144],
                ['2022-04-25', 1.155],
                ['2022-04-26', 1.165],
                ['2022-04-27', 1.176],
                ['2022-04-28', 1.186],
                ['2022-04-29', 1.197],
                ['2022-04-30', 1.207],
                ['2022-05-01', 1.218],
                ['2022-05-02', 1.228],
                ['2022-05-03', 1.239],
                ['2022-05-04', 1.249],
                ['2022-05-05', 1.26],
                ['2022-05-06', 1.273],
                ['2022-05-07', 1.285],
                ['2022-05-08', 1.298],
                ['2022-05-09', 1.311],
                ['2022-05-10', 1.323],
                ['2022-05-11', 1.336],
                ['2022-05-12', 1.348],
                ['2022-05-13', 1.361],
                ['2022-05-14', 1.374],
                ['2022-05-15', 1.386],
                ['2022-05-16', 1.399],
                ['2022-05-17', 1.412],
                ['2022-05-18', 1.424],
                ['2022-05-19', 1.437],
                ['2022-05-20', 1.45],
                ['2022-05-21', 1.462],
                ['2022-05-22', 1.475],
                ['2022-05-23', 1.487],
                ['2022-05-24', 1.5],
                ['2022-05-25', 1.513],
                ['2022-05-26', 1.525],
                ['2022-05-27', 1.538],
                ['2022-05-28', 1.551],
                ['2022-05-29', 1.563],
                ['2022-05-30', 1.576],
                ['2022-05-31', 1.589],
                ['2022-06-01', 1.601],
                ['2022-06-02', 1.614],
                ['2022-06-03', 1.627],
                ['2022-06-04', 1.639],
                ['2022-06-05', 1.652],
                ['2022-06-06', 1.665],
                ['2022-06-07', 1.677],
                ['2022-06-08', 1.69],
                ['2022-06-09', 1.703],
                ['2022-06-10', 1.715],
                ['2022-06-11', 1.728],
                ['2022-06-12', 1.741],
                ['2022-06-13', 1.753],
                ['2022-06-14', 1.766],
                ['2022-06-15', 1.779],
                ['2022-06-16', 1.792],
                ['2022-06-17', 1.804],
                ['2022-06-18', 1.817],
                ['2022-06-19', 1.83],
                ['2022-06-20', 1.842],
                ['2022-06-21', 1.855],
                ['2022-06-22', 1.868],
                ['2022-06-23', 1.884],
                ['2022-06-24', 1.9],
                ['2022-06-25', 1.916],
                ['2022-06-26', 1.932],
                ['2022-06-27', 1.949],
                ['2022-06-28', 1.965],
                ['2022-06-29', 1.981],
                ['2022-06-30', 1.997],
                ['2022-07-01', 2.013],
                ['2022-07-02', 2.03],
                ['2022-07-03', 2.046],
                ['2022-07-04', 2.062],
                ['2022-07-05', 2.078],
                ['2022-07-06', 2.095],
                ['2022-07-07', 2.111],
                ['2022-07-08', 2.127],
                ['2022-07-09', 2.143],
                ['2022-07-10', 2.159],
                ['2022-07-11', 2.176],
                ['2022-07-12', 2.192],
                ['2022-07-13', 2.208],
                ['2022-07-14', 2.224],
                ['2022-07-15', 2.241],
                ['2022-07-16', 2.257],
                ['2022-07-17', 2.273],
                ['2022-07-18', 2.289],
                ['2022-07-19', 2.306],
                ['2022-07-20', 2.322],
                ['2022-07-21', 2.338],
                ['2022-07-22', 2.354],
                ['2022-07-23', 2.371],
                ['2022-07-24', 2.387],
                ['2022-07-25', 2.403],
                ['2022-07-26', 2.419],
                ['2022-07-27', 2.436],
                ['2022-07-28', 2.452],
                ['2022-07-29', 2.468],
                ['2022-07-30', 2.485],
                ['2022-07-31', 2.501],
                ['2022-08-01', 2.517],
                ['2022-08-02', 2.533],
                ['2022-08-03', 2.55],
                ['2022-08-04', 2.566],
                ['2022-08-05', 2.582],
                ['2022-08-06', 2.599],
                ['2022-08-07', 2.615],
                ['2022-08-08', 2.631],
                ['2022-08-09', 2.648],
                ['2022-08-10', 2.664],
                ['2022-08-11', 2.68],
                ['2022-08-12', 2.697],
                ['2022-08-13', 2.713],
                ['2022-08-14', 2.729],
                ['2022-08-15', 2.745],
                ['2022-08-16', 2.762],
                ['2022-08-17', 2.778],
                ['2022-08-18', 2.794],
                ['2022-08-19', 2.811],
                ['2022-08-20', 2.827],
                ['2022-08-21', 2.843],
                ['2022-08-22', 2.86],
                ['2022-08-23', 2.876],
                ['2022-08-24', 2.893],
                ['2022-08-25', 2.909],
                ['2022-08-26', 2.925],
                ['2022-08-27', 2.942],
                ['2022-08-28', 2.958],
                ['2022-08-29', 2.974],
                ['2022-08-30', 2.991],
                ['2022-08-31', 3.007],
                ['2022-09-01', 3.023],
                ['2022-09-02', 3.04],
                ['2022-09-03', 3.056],
                ['2022-09-04', 3.073],
                ['2022-09-05', 3.089],
                ['2022-09-06', 3.105],
                ['2022-09-07', 3.122],
                ['2022-09-08', 3.138],
                ['2022-09-09', 3.154],
                ['2022-09-10', 3.171],
                ['2022-09-11', 3.187],
                ['2022-09-12', 3.204],
                ['2022-09-13', 3.22],
                ['2022-09-14', 3.236],
                ['2022-09-15', 3.253],
                ['2022-09-16', 3.269],
                ['2022-09-17', 3.286],
                ['2022-09-18', 3.302],
                ['2022-09-19', 3.318],
                ['2022-09-20', 3.335],
                ['2022-09-21', 3.351],
                ['2022-09-22', 3.368],
                ['2022-09-23', 3.384],
                ['2022-09-24', 3.401],
                ['2022-09-25', 3.417],
                ['2022-09-26', 3.433],
                ['2022-09-27', 3.45],
                ['2022-09-28', 3.466],
                ['2022-09-29', 3.483],
                ['2022-09-30', 3.499],
                ['2022-10-01', 3.516],
                ['2022-10-02', 3.532],
                ['2022-10-03', 3.549],
                ['2022-10-04', 3.565],
                ['2022-10-05', 3.581],
                ['2022-10-06', 3.598],
                ['2022-10-07', 3.614],
                ['2022-10-08', 3.631],
                ['2022-10-09', 3.647],
                ['2022-10-10', 3.664],
                ['2022-10-11', 3.68],
                ['2022-10-12', 3.697],
                ['2022-10-13', 3.713],
                ['2022-10-14', 3.73],
                ['2022-10-15', 3.746],
                ['2022-10-16', 3.763],
                ['2022-10-17', 3.779],
                ['2022-10-18', 3.796],
                ['2022-10-19', 3.812],
                ['2022-10-20', 3.829],
                ['2022-10-21', 3.845],
                ['2022-10-22', 3.862],
                ['2022-10-23', 3.878],
                ['2022-10-24', 3.895],
                ['2022-10-25', 3.911],
                ['2022-10-26', 3.928],
                ['2022-10-27', 3.944],
                ['2022-10-28', 3.961],
                ['2022-10-29', 3.977],
                ['2022-10-30', 3.994],
                ['2022-10-31', 4.01],
                ['2022-11-01', 4.027],
                ['2022-11-02', 4.043],
                ['2022-11-03', 4.06],
                ['2022-11-04', 4.076],
                ['2022-11-05', 4.093],
                ['2022-11-06', 4.109],
                ['2022-11-07', 4.126],
                ['2022-11-08', 4.143],
                ['2022-11-09', 4.159],
                ['2022-11-10', 4.176],
                ['2022-11-11', 4.192],
                ['2022-11-12', 4.209],
                ['2022-11-13', 4.225],
                ['2022-11-14', 4.242],
                ['2022-11-15', 4.258],
                ['2022-11-16', 4.275],
                ['2022-11-17', 4.292],
                ['2022-11-18', 4.308],
                ['2022-11-19', 4.325],
                ['2022-11-20', 4.341],
                ['2022-11-21', 4.358],
                ['2022-11-22', 4.374],
                ['2022-11-23', 4.391],
                ['2022-11-24', 4.408],
                ['2022-11-25', 4.424],
                ['2022-11-26', 4.441],
                ['2022-11-27', 4.457],
                ['2022-11-28', 4.474],
                ['2022-11-29', 4.491],
                ['2022-11-30', 4.507],
                ['2022-12-01', 4.524],
                ['2022-12-02', 4.54],
                ['2022-12-03', 4.557],
                ['2022-12-04', 4.574],
                ['2022-12-05', 4.59],
                ['2022-12-06', 4.607],
                ['2022-12-07', 4.624],
                ['2022-12-08', 4.64],
                ['2022-12-09', 4.657],
                ['2022-12-10', 4.673],
                ['2022-12-11', 4.69],
                ['2022-12-12', 4.707],
                ['2022-12-13', 4.723],
                ['2022-12-14', 4.74],
                ['2022-12-15', 4.757],
                ['2022-12-16', 4.773],
                ['2022-12-17', 4.79],
                ['2022-12-18', 4.807],
                ['2022-12-19', 4.823],
                ['2022-12-20', 4.84],
                ['2022-12-21', 4.857],
                ['2022-12-22', 4.873],
                ['2022-12-23', 4.89],
                ['2022-12-24', 4.907],
                ['2022-12-25', 4.923],
                ['2022-12-26', 4.94],
                ['2022-12-27', 4.957],
                ['2022-12-28', 4.973],
                ['2022-12-29', 4.99],
                ['2022-12-30', 5.007],
                ['2022-12-31', 5.023],
                ['2023-01-01', 5.04],
                ['2023-01-02', 5.057],
                ['2023-01-03', 5.073],
                ['2023-01-04', 5.09],
                ['2023-01-05', 5.107],
                ['2023-01-06', 5.123],
                ['2023-01-07', 5.14],
                ['2023-01-08', 5.157],
                ['2023-01-09', 5.174],
                ['2023-01-10', 5.19],
                ['2023-01-11', 5.207],
                ['2023-01-12', 5.224],
                ['2023-01-13', 5.24],
                ['2023-01-14', 5.257],
                ['2023-01-15', 5.274],
                ['2023-01-16', 5.291],
                ['2023-01-17', 5.307],
                ['2023-01-18', 5.324],
                ['2023-01-19', 5.341],
                ['2023-01-20', 5.358],
                ['2023-01-21', 5.374],
                ['2023-01-22', 5.391],
                ['2023-01-23', 5.408],
                ['2023-01-24', 5.425],
                ['2023-01-25', 5.441],
                ['2023-01-26', 5.458],
                ['2023-01-27', 5.475],
                ['2023-01-28', 5.492],
                ['2023-01-29', 5.508],
                ['2023-01-30', 5.525],
                ['2023-01-31', 5.542],
                ['2023-02-01', 5.559],
                ['2023-02-02', 5.575],
                ['2023-02-03', 5.592],
                ['2023-02-04', 5.609],
                ['2023-02-05', 5.626],
                ['2023-02-06', 5.643],
                ['2023-02-07', 5.659],
                ['2023-02-08', 5.676],
                ['2023-02-09', 5.693],
                ['2023-02-10', 5.71],
                ['2023-02-11', 5.726],
                ['2023-02-12', 5.743],
                ['2023-02-13', 5.76],
                ['2023-02-14', 5.777],
                ['2023-02-15', 5.794],
                ['2023-02-16', 5.811],
                ['2023-02-17', 5.827],
                ['2023-02-18', 5.844],
                ['2023-02-19', 5.861],
                ['2023-02-20', 5.878],
                ['2023-02-21', 5.895],
                ['2023-02-22', 5.911],
                ['2023-02-23', 5.928],
                ['2023-02-24', 5.945],
                ['2023-02-25', 5.962],
                ['2023-02-26', 5.979],
                ['2023-02-27', 5.996],
                ['2023-02-28', 6.012],
                ['2023-03-01', 6.029],
                ['2023-03-02', 6.046],
                ['2023-03-03', 6.063],
                ['2023-03-04', 6.08],
                ['2023-03-05', 6.097],
                ['2023-03-06', 6.114],
                ['2023-03-07', 6.13],
                ['2023-03-08', 6.147],
                ['2023-03-09', 6.164],
                ['2023-03-10', 6.181],
                ['2023-03-11', 6.198],
                ['2023-03-12', 6.215],
                ['2023-03-13', 6.232],
                ['2023-03-14', 6.249],
                ['2023-03-15', 6.265],
                ['2023-03-16', 6.282],
                ['2023-03-17', 6.299],
                ['2023-03-18', 6.316],
                ['2023-03-19', 6.333],
                ['2023-03-20', 6.35],
                ['2023-03-21', 6.367],
                ['2023-03-22', 6.384],
                ['2023-03-23', 6.401],
                ['2023-03-24', 6.417],
                ['2023-03-25', 6.434],
                ['2023-03-26', 6.451],
                ['2023-03-27', 6.468],
                ['2023-03-28', 6.485],
                ['2023-03-29', 6.502],
                ['2023-03-30', 6.519],
                ['2023-03-31', 6.536],
                ['2023-04-01', 6.553],
                ['2023-04-02', 6.57],
                ['2023-04-03', 6.587],
                ['2023-04-04', 6.604],
                ['2023-04-05', 6.621],
                ['2023-04-06', 6.638],
                ['2023-04-07', 6.654],
                ['2023-04-08', 6.671],
                ['2023-04-09', 6.688],
                ['2023-04-10', 6.705],
                ['2023-04-11', 6.722],
                ['2023-04-12', 6.739],
                ['2023-04-13', 6.756],
                ['2023-04-14', 6.773],
                ['2023-04-15', 6.79],
                ['2023-04-16', 6.807],
                ['2023-04-17', 6.824],
                ['2023-04-18', 6.841],
                ['2023-04-19', 6.858],
                ['2023-04-20', 6.875],
                ['2023-04-21', 6.892],
                ['2023-04-22', 6.909],
                ['2023-04-23', 6.926],
                ['2023-04-24', 6.943],
                ['2023-04-25', 6.96],
                ['2023-04-26', 6.977],
                ['2023-04-27', 6.994],
                ['2023-04-28', 7.011],
                ['2023-04-29', 7.028],
                ['2023-04-30', 7.045],
                ['2023-05-01', 7.062],
                ['2023-05-02', 7.079],
                ['2023-05-03', 7.096],
                ['2023-05-04', 7.113],
                ['2023-05-05', 7.13],
                ['2023-05-06', 7.147],
                ['2023-05-07', 7.164],
                ['2023-05-08', 7.181],
                ['2023-05-09', 7.198],
                ['2023-05-10', 7.215],
                ['2023-05-11', 7.232],
                ['2023-05-12', 7.249],
                ['2023-05-13', 7.266],
                ['2023-05-14', 7.283],
                ['2023-05-15', 7.3],
                ['2023-05-16', 7.317],
                ['2023-05-17', 7.334],
                ['2023-05-18', 7.352],
                ['2023-05-19', 7.369],
                ['2023-05-20', 7.386],
                ['2023-05-21', 7.403],
                ['2023-05-22', 7.42],
                ['2023-05-23', 7.437],
                ['2023-05-24', 7.454],
                ['2023-05-25', 7.471],
                ['2023-05-26', 7.488],
                ['2023-05-27', 7.505],
                ['2023-05-28', 7.522],
                ['2023-05-29', 7.539],
                ['2023-05-30', 7.556],
                ['2023-05-31', 7.574],
                ['2023-06-01', 7.591],
                ['2023-06-02', 7.608],
                ['2023-06-03', 7.625],
                ['2023-06-04', 7.642],
                ['2023-06-05', 7.659],
                ['2023-06-06', 7.676],
                ['2023-06-07', 7.693],
                ['2023-06-08', 7.71],
                ['2023-06-09', 7.727],
                ['2023-06-10', 7.745],
                ['2023-06-11', 7.762],
                ['2023-06-12', 7.779],
                ['2023-06-13', 7.796],
                ['2023-06-14', 7.813],
                ['2023-06-15', 7.83],
                ['2023-06-16', 7.847],
                ['2023-06-17', 7.864],
                ['2023-06-18', 7.882],
                ['2023-06-19', 7.899],
                ['2023-06-20', 7.916],
                ['2023-06-21', 7.933],
                ['2023-06-22', 7.95],
                ['2023-06-23', 7.967],
                ['2023-06-24', 7.985],
                ['2023-06-25', 8.002],
                ['2023-06-26', 8.019],
                ['2023-06-27', 8.036],
                ['2023-06-28', 8.053],
                ['2023-06-29', 8.07],
                ['2023-06-30', 8.088],
                ['2023-07-01', 8.105],
                ['2023-07-02', 8.122],
                ['2023-07-03', 8.139],
                ['2023-07-04', 8.156],
                ['2023-07-05', 8.173],
                ['2023-07-06', 8.191],
                ['2023-07-07', 8.208],
                ['2023-07-08', 8.225],
                ['2023-07-09', 8.242],
                ['2023-07-10', 8.259],
                ['2023-07-11', 8.277],
                ['2023-07-12', 8.294],
                ['2023-07-13', 8.311],
                ['2023-07-14', 8.328],
                ['2023-07-15', 8.345],
                ['2023-07-16', 8.363],
                ['2023-07-17', 8.38],
                ['2023-07-18', 8.397],
                ['2023-07-19', 8.414],
                ['2023-07-20', 8.432],
                ['2023-07-21', 8.449],
                ['2023-07-22', 8.466],
                ['2023-07-23', 8.483],
                ['2023-07-24', 8.5],
                ['2023-07-25', 8.518],
                ['2023-07-26', 8.535],
                ['2023-07-27', 8.552],
                ['2023-07-28', 8.569],
                ['2023-07-29', 8.587],
                ['2023-07-30', 8.604],
                ['2023-07-31', 8.621],
                ['2023-08-01', 8.638],
                ['2023-08-02', 8.656],
                ['2023-08-03', 8.673],
                ['2023-08-04', 8.69],
                ['2023-08-05', 8.708],
                ['2023-08-06', 8.725],
                ['2023-08-07', 8.742],
                ['2023-08-08', 8.759],
                ['2023-08-09', 8.777],
                ['2023-08-10', 8.794],
                ['2023-08-11', 8.811],
                ['2023-08-12', 8.829],
                ['2023-08-13', 8.846],
                ['2023-08-14', 8.863],
                ['2023-08-15', 8.88],
                ['2023-08-16', 8.898],
                ['2023-08-17', 8.915],
                ['2023-08-18', 8.932],
                ['2023-08-19', 8.95],
                ['2023-08-20', 8.967],
                ['2023-08-21', 8.984],
                ['2023-08-22', 9.002],
                ['2023-08-23', 9.019],
                ['2023-08-24', 9.036],
                ['2023-08-25', 9.054],
                ['2023-08-26', 9.071],
                ['2023-08-27', 9.088],
                ['2023-08-28', 9.106],
                ['2023-08-29', 9.123],
                ['2023-08-30', 9.140],
                ['2023-08-31', 9.158],
            ]
        );
    }
    if (document.getElementById('apexcharts-ChartIvyBalanced')) {
        loadApexChart(
            'ChartIvyBalanced',
            'ivy balanced',
            '#fab800',
            '#fab800',
            '#e94f1a',
            'smooth',
            '2021-03-01',
            [
                ['2006-12-31', -0.01],
                ['2007-01-01', -0.01],
                ['2007-01-02', -0.01],
                ['2007-01-03', -0.01],
                ['2007-01-04', -0.05],
                ['2007-01-05', -0.67],
                ['2007-01-08', -0.46],
                ['2007-01-09', -0.34],
                ['2007-01-10', -0.32],
                ['2007-01-11', -0.18],
                ['2007-01-12', 0.28],
                ['2007-01-16', 0.34],
                ['2007-01-17', 0.50],
                ['2007-01-18', 0.28],
                ['2007-01-19', 0.52],
                ['2007-01-22', 0.32],
                ['2007-01-23', 0.73],
                ['2007-01-24', 0.94],
                ['2007-01-25', 0.59],
                ['2007-01-26', 0.61],
                ['2007-01-29', 0.51],
                ['2007-01-30', 0.76],
                ['2007-01-31', 1.12],
                ['2007-02-01', 1.30],
                ['2007-02-02', 1.16],
                ['2007-02-05', 1.19],
                ['2007-02-06', 1.40],
                ['2007-02-07', 1.60],
                ['2007-02-08', 1.82],
                ['2007-02-09', 1.72],
                ['2007-02-12', 1.44],
                ['2007-02-13', 1.65],
                ['2007-02-14', 2.06],
                ['2007-02-15', 2.16],
                ['2007-02-16', 2.16],
                ['2007-02-20', 2.01],
                ['2007-02-21', 2.42],
                ['2007-02-22', 2.36],
                ['2007-02-23', 2.50],
                ['2007-02-26', 2.59],
                ['2007-02-27', 1.20],
                ['2007-02-28', 1.52],
                ['2007-03-01', 1.23],
                ['2007-03-02', 0.43],
                ['2007-03-05', -0.08],
                ['2007-03-06', 0.64],
                ['2007-03-07', 0.64],
                ['2007-03-08', 0.91],
                ['2007-03-09', 0.83],
                ['2007-03-12', 1.01],
                ['2007-03-13', 0.38],
                ['2007-03-14', 0.57],
                ['2007-03-15', 0.67],
                ['2007-03-16', 0.69],
                ['2007-03-19', 0.95],
                ['2007-03-20', 1.28],
                ['2007-03-21', 1.84],
                ['2007-03-22', 1.75],
                ['2007-03-23', 1.64],
                ['2007-03-26', 1.75],
                ['2007-03-27', 1.52],
                ['2007-03-28', 1.40],
                ['2007-03-29', 1.30],
                ['2007-03-30', 1.29],
                ['2007-04-02', 1.32],
                ['2007-04-03', 1.54],
                ['2007-04-04', 1.83],
                ['2007-04-05', 1.90],
                ['2007-04-09', 1.82],
                ['2007-04-10', 2.07],
                ['2007-04-11', 1.83],
                ['2007-04-12', 1.97],
                ['2007-04-13', 2.25],
                ['2007-04-16', 4.17],
                ['2007-04-17', 4.28],
                ['2007-04-18', 4.48],
                ['2007-04-19', 4.21],
                ['2007-04-20', 4.70],
                ['2007-04-23', 4.58],
                ['2007-04-24', 4.52],
                ['2007-04-25', 4.73],
                ['2007-04-26', 4.42],
                ['2007-04-27', 4.52],
                ['2007-04-30', 4.23],
                ['2007-05-01', 4.17],
                ['2007-05-02', 4.33],
                ['2007-05-03', 4.60],
                ['2007-05-04', 4.84],
                ['2007-05-07', 5.02],
                ['2007-05-08', 4.84],
                ['2007-05-09', 4.80],
                ['2007-05-10', 4.07],
                ['2007-05-11', 4.41],
                ['2007-05-14', 4.29],
                ['2007-05-15', 4.25],
                ['2007-05-16', 4.20],
                ['2007-05-17', 3.88],
                ['2007-05-18', 4.07],
                ['2007-05-21', 4.13],
                ['2007-05-22', 3.98],
                ['2007-05-23', 3.97],
                ['2007-05-24', 3.52],
                ['2007-05-25', 3.67],
                ['2007-05-29', 3.79],
                ['2007-05-30', 3.87],
                ['2007-05-31', 4.13],
                ['2007-06-01', 4.35],
                ['2007-06-04', 4.43],
                ['2007-06-05', 4.11],
                ['2007-06-06', 3.88],
                ['2007-06-07', 2.92],
                ['2007-06-08', 2.97],
                ['2007-06-11', 3.07],
                ['2007-06-12', 2.48],
                ['2007-06-13', 3.03],
                ['2007-06-14', 3.14],
                ['2007-06-15', 3.53],
                ['2007-06-18', 3.47],
                ['2007-06-19', 3.68],
                ['2007-06-20', 3.02],
                ['2007-06-21', 3.15],
                ['2007-06-22', 2.94],
                ['2007-06-25', 2.73],
                ['2007-06-26', 2.50],
                ['2007-06-27', 2.81],
                ['2007-06-28', 2.90],
                ['2007-06-29', 2.93],
                ['2007-07-02', 3.45],
                ['2007-07-03', 3.38],
                ['2007-07-05', 3.26],
                ['2007-07-06', 3.47],
                ['2007-07-09', 3.71],
                ['2007-07-10', 3.48],
                ['2007-07-11', 3.47],
                ['2007-07-12', 4.07],
                ['2007-07-13', 4.22],
                ['2007-07-16', 4.19],
                ['2007-07-17', 4.16],
                ['2007-07-18', 4.38],
                ['2007-07-19', 4.59],
                ['2007-07-20', 4.49],
                ['2007-07-23', 4.56],
                ['2007-07-24', 4.06],
                ['2007-07-25', 4.15],
                ['2007-07-26', 3.30],
                ['2007-07-27', 2.86],
                ['2007-07-30', 3.18],
                ['2007-07-31', 2.96],
                ['2007-08-01', 3.12],
                ['2007-08-02', 3.25],
                ['2007-08-03', 2.93],
                ['2007-08-06', 3.56],
                ['2007-08-07', 3.67],
                ['2007-08-08', 4.09],
                ['2007-08-09', 3.26],
                ['2007-08-10', 3.40],
                ['2007-08-13', 3.26],
                ['2007-08-14', 2.88],
                ['2007-08-15', 2.47],
                ['2007-08-16', 2.21],
                ['2007-08-17', 2.91],
                ['2007-08-20', 2.99],
                ['2007-08-21', 3.08],
                ['2007-08-22', 3.37],
                ['2007-08-23', 3.26],
                ['2007-08-24', 3.76],
                ['2007-08-27', 3.51],
                ['2007-08-28', 2.87],
                ['2007-08-29', 3.49],
                ['2007-08-30', 3.42],
                ['2007-08-31', 3.91],
                ['2007-09-04', 4.35],
                ['2007-09-05', 4.17],
                ['2007-09-06', 4.65],
                ['2007-09-07', 4.58],
                ['2007-09-10', 4.74],
                ['2007-09-11', 5.28],
                ['2007-09-12', 5.20],
                ['2007-09-13', 5.17],
                ['2007-09-14', 5.19],
                ['2007-09-17', 5.26],
                ['2007-09-18', 6.19],
                ['2007-09-19', 6.32],
                ['2007-09-20', 6.22],
                ['2007-09-21', 6.30],
                ['2007-09-24', 6.21],
                ['2007-09-25', 6.19],
                ['2007-09-26', 6.22],
                ['2007-09-27', 6.55],
                ['2007-09-28', 6.61],
                ['2007-10-01', 7.01],
                ['2007-10-02', 6.68],
                ['2007-10-03', 6.38],
                ['2007-10-04', 6.66],
                ['2007-10-05', 7.03],
                ['2007-10-08', 6.84],
                ['2007-10-09', 7.14],
                ['2007-10-10', 7.14],
                ['2007-10-11', 7.09],
                ['2007-10-12', 7.32],
                ['2007-10-15', 7.15],
                ['2007-10-16', 7.04],
                ['2007-10-17', 7.04],
                ['2007-10-18', 7.47],
                ['2007-10-19', 6.83],
                ['2007-10-22', 6.68],
                ['2007-10-23', 7.00],
                ['2007-10-24', 7.07],
                ['2007-10-25', 7.12],
                ['2007-10-26', 7.68],
                ['2007-10-29', 7.87],
                ['2007-10-30', 7.63],
                ['2007-10-31', 8.12],
                ['2007-11-01', 7.35],
                ['2007-11-02', 7.82],
                ['2007-11-05', 7.68],
                ['2007-11-06', 8.27],
                ['2007-11-07', 7.78],
                ['2007-11-08', 7.94],
                ['2007-11-09', 7.71],
                ['2007-11-12', 6.65],
                ['2007-11-13', 7.41],
                ['2007-11-14', 7.46],
                ['2007-11-15', 6.78],
                ['2007-11-16', 6.80],
                ['2007-11-19', 6.43],
                ['2007-11-20', 6.90],
                ['2007-11-21', 6.55],
                ['2007-11-23', 7.33],
                ['2007-11-26', 6.95],
                ['2007-11-27', 6.98],
                ['2007-11-28', 7.57],
                ['2007-11-29', 7.32],
                ['2007-11-30', 7.35],
                ['2007-12-03', 7.48],
                ['2007-12-04', 7.58],
                ['2007-12-05', 7.81],
                ['2007-12-06', 8.22],
                ['2007-12-07', 8.01],
                ['2007-12-10', 8.50],
                ['2007-12-11', 7.72],
                ['2007-12-12', 8.05],
                ['2007-12-13', 7.57],
                ['2007-12-14', 7.15],
                ['2007-12-17', 6.72],
                ['2007-12-18', 7.19],
                ['2007-12-19', 7.27],
                ['2007-12-20', 7.26],
                ['2007-12-21', 7.92],
                ['2007-12-24', 8.08],
                ['2007-12-26', 8.28],
                ['2007-12-27', 7.96],
                ['2007-12-28', 8.41],
                ['2007-12-31', 8.18],
                ['2008-01-02', 8.37],
                ['2008-01-03', 8.59],
                ['2008-01-04', 8.00],
                ['2008-01-07', 8.16],
                ['2008-01-08', 8.16],
                ['2008-01-09', 8.44],
                ['2008-01-10', 8.85],
                ['2008-01-11', 8.69],
                ['2008-01-14', 9.05],
                ['2008-01-15', 8.20],
                ['2008-01-16', 7.80],
                ['2008-01-17', 7.16],
                ['2008-01-18', 7.14],
                ['2008-01-22', 7.09],
                ['2008-01-23', 7.56],
                ['2008-01-24', 7.95],
                ['2008-01-25', 7.78],
                ['2008-01-28', 8.53],
                ['2008-01-29', 8.54],
                ['2008-01-30', 8.48],
                ['2008-01-31', 8.87],
                ['2008-02-01', 9.03],
                ['2008-02-04', 8.61],
                ['2008-02-05', 7.62],
                ['2008-02-06', 7.53],
                ['2008-02-07', 7.81],
                ['2008-02-08', 8.02],
                ['2008-02-11', 8.31],
                ['2008-02-12', 8.13],
                ['2008-02-13', 8.48],
                ['2008-02-14', 7.99],
                ['2008-02-15', 7.93],
                ['2008-02-19', 8.22],
                ['2008-02-20', 8.70],
                ['2008-02-21', 8.48],
                ['2008-02-22', 8.69],
                ['2008-02-25', 8.88],
                ['2008-02-26', 9.32],
                ['2008-02-27', 9.50],
                ['2008-02-28', 9.61],
                ['2008-02-29', 9.10],
                ['2008-03-03', 9.22],
                ['2008-03-04', 8.73],
                ['2008-03-05', 9.29],
                ['2008-03-06', 8.52],
                ['2008-03-07', 8.24],
                ['2008-03-10', 7.88],
                ['2008-03-11', 8.61],
                ['2008-03-12', 8.81],
                ['2008-03-13', 9.21],
                ['2008-03-14', 8.87],
                ['2008-03-17', 8.81],
                ['2008-03-18', 9.26],
                ['2008-03-19', 8.19],
                ['2008-03-20', 8.20],
                ['2008-03-24', 8.37],
                ['2008-03-25', 8.93],
                ['2008-03-26', 8.91],
                ['2008-03-27', 8.54],
                ['2008-03-28', 8.02],
                ['2008-03-31', 7.93],
                ['2008-04-01', 8.04],
                ['2008-04-02', 8.48],
                ['2008-04-03', 8.56],
                ['2008-04-04', 9.06],
                ['2008-04-07', 9.20],
                ['2008-04-08', 8.95],
                ['2008-04-09', 9.29],
                ['2008-04-10', 9.24],
                ['2008-04-11', 8.69],
                ['2008-04-14', 8.44],
                ['2008-04-15', 8.57],
                ['2008-04-16', 9.44],
                ['2008-04-17', 9.20],
                ['2008-04-18', 9.15],
                ['2008-04-21', 9.06],
                ['2008-04-22', 8.76],
                ['2008-04-23', 8.77],
                ['2008-04-24', 8.34],
                ['2008-04-25', 8.50],
                ['2008-04-28', 8.71],
                ['2008-04-29', 8.31],
                ['2008-04-30', 8.40],
                ['2008-05-01', 8.32],
                ['2008-05-02', 8.35],
                ['2008-05-05', 8.58],
                ['2008-05-06', 8.84],
                ['2008-05-07', 8.33],
                ['2008-05-08', 8.82],
                ['2008-05-09', 8.75],
                ['2008-05-12', 8.92],
                ['2008-05-13', 8.46],
                ['2008-05-14', 8.57],
                ['2008-05-15', 9.26],
                ['2008-05-16', 10.04],
                ['2008-05-19', 10.18],
                ['2008-05-20', 10.34],
                ['2008-05-21', 10.10],
                ['2008-05-22', 9.89],
                ['2008-05-23', 9.71],
                ['2008-05-27', 9.44],
                ['2008-05-28', 9.26],
                ['2008-05-29', 8.81],
                ['2008-05-30', 9.15],
                ['2008-06-02', 9.02],
                ['2008-06-03', 8.77],
                ['2008-06-04', 8.74],
                ['2008-06-05', 8.97],
                ['2008-06-06', 8.91],
                ['2008-06-09', 8.49],
                ['2008-06-10', 7.78],
                ['2008-06-11', 7.59],
                ['2008-06-12', 7.27],
                ['2008-06-13', 7.63],
                ['2008-06-16', 7.91],
                ['2008-06-17', 7.83],
                ['2008-06-18', 7.85],
                ['2008-06-19', 7.98],
                ['2008-06-20', 7.72],
                ['2008-06-23', 7.32],
                ['2008-06-24', 7.42],
                ['2008-06-25', 7.65],
                ['2008-06-26', 7.59],
                ['2008-06-27', 7.71],
                ['2008-06-30', 7.75],
                ['2008-07-01', 7.98],
                ['2008-07-02', 7.72],
                ['2008-07-03', 7.39],
                ['2008-07-07', 7.28],
                ['2008-07-08', 7.91],
                ['2008-07-09', 7.60],
                ['2008-07-10', 8.26],
                ['2008-07-11', 8.22],
                ['2008-07-14', 8.07],
                ['2008-07-15', 8.10],
                ['2008-07-16', 8.36],
                ['2008-07-17', 8.54],
                ['2008-07-18', 8.44],
                ['2008-07-21', 8.57],
                ['2008-07-22', 8.59],
                ['2008-07-23', 8.17],
                ['2008-07-24', 7.90],
                ['2008-07-25', 7.91],
                ['2008-07-28', 7.62],
                ['2008-07-29', 7.97],
                ['2008-07-30', 8.26],
                ['2008-07-31', 8.43],
                ['2008-08-01', 8.13],
                ['2008-08-04', 7.75],
                ['2008-08-05', 8.06],
                ['2008-08-06', 8.32],
                ['2008-08-07', 8.11],
                ['2008-08-08', 8.33],
                ['2008-08-11', 7.76],
                ['2008-08-12', 7.47],
                ['2008-08-13', 7.61],
                ['2008-08-14', 7.37],
                ['2008-08-15', 7.26],
                ['2008-08-18', 7.22],
                ['2008-08-19', 7.30],
                ['2008-08-20', 7.38],
                ['2008-08-21', 7.86],
                ['2008-08-22', 7.84],
                ['2008-08-25', 7.43],
                ['2008-08-26', 7.55],
                ['2008-08-27', 7.81],
                ['2008-08-28', 8.29],
                ['2008-08-29', 7.84],
                ['2008-09-02', 7.28],
                ['2008-09-03', 7.21],
                ['2008-09-04', 6.47],
                ['2008-09-05', 6.81],
                ['2008-09-08', 7.39],
                ['2008-09-09', 6.15],
                ['2008-09-10', 5.67],
                ['2008-09-11', 5.79],
                ['2008-09-12', 6.43],
                ['2008-09-15', 6.08],
                ['2008-09-16', 6.17],
                ['2008-09-17', 7.04],
                ['2008-09-18', 7.45],
                ['2008-09-19', 8.55],
                ['2008-09-22', 8.09],
                ['2008-09-23', 7.48],
                ['2008-09-24', 7.13],
                ['2008-09-25', 7.39],
                ['2008-09-26', 7.57],
                ['2008-09-29', 6.22],
                ['2008-09-30', 6.28],
                ['2008-10-01', 6.49],
                ['2008-10-02', 4.97],
                ['2008-10-03', 4.95],
                ['2008-10-06', 4.07],
                ['2008-10-07', 3.31],
                ['2008-10-08', 2.87],
                ['2008-10-09', 0.68],
                ['2008-10-10', -1.65],
                ['2008-10-13', 0.60],
                ['2008-10-14', 0.49],
                ['2008-10-15', -1.41],
                ['2008-10-16', -1.33],
                ['2008-10-17', -1.91],
                ['2008-10-20', -0.83],
                ['2008-10-21', -2.06],
                ['2008-10-22', -4.57],
                ['2008-10-23', -5.32],
                ['2008-10-24', -5.89],
                ['2008-10-27', -6.96],
                ['2008-10-28', -4.44],
                ['2008-10-29', -4.57],
                ['2008-10-30', -3.91],
                ['2008-10-31', -3.74],
                ['2008-11-03', -3.69],
                ['2008-11-04', -1.58],
                ['2008-11-05', -2.97],
                ['2008-11-06', -4.17],
                ['2008-11-07', -3.41],
                ['2008-11-10', -3.41],
                ['2008-11-11', -4.18],
                ['2008-11-12', -5.94],
                ['2008-11-13', -4.01],
                ['2008-11-14', -4.69],
                ['2008-11-17', -5.35],
                ['2008-11-18', -5.21],
                ['2008-11-19', -6.64],
                ['2008-11-20', -7.68],
                ['2008-11-21', -5.29],
                ['2008-11-24', -3.67],
                ['2008-11-25', -3.20],
                ['2008-11-26', -2.29],
                ['2008-11-28', -1.82],
                ['2008-12-01', -4.74],
                ['2008-12-02', -3.56],
                ['2008-12-03', -2.89],
                ['2008-12-04', -3.66],
                ['2008-12-05', -3.00],
                ['2008-12-08', -1.98],
                ['2008-12-09', -2.20],
                ['2008-12-10', -1.24],
                ['2008-12-11', -1.40],
                ['2008-12-12', -1.07],
                ['2008-12-15', -1.12],
                ['2008-12-16', 0.86],
                ['2008-12-17', 1.04],
                ['2008-12-18', 0.48],
                ['2008-12-19', 0.27],
                ['2008-12-22', -0.11],
                ['2008-12-23', -0.62],
                ['2008-12-24', -0.28],
                ['2008-12-26', 0.31],
                ['2008-12-29', 0.26],
                ['2008-12-30', 0.87],
                ['2008-12-31', 1.39],
                ['2009-01-02', 1.86],
                ['2009-01-05', 1.33],
                ['2009-01-06', 1.67],
                ['2009-01-07', 0.44],
                ['2009-01-08', 0.80],
                ['2009-01-09', 0.43],
                ['2009-01-12', -0.49],
                ['2009-01-13', -0.23],
                ['2009-01-14', -1.01],
                ['2009-01-15', -0.75],
                ['2009-01-16', -0.13],
                ['2009-01-20', -1.22],
                ['2009-01-21', -0.53],
                ['2009-01-22', -0.96],
                ['2009-01-23', -0.31],
                ['2009-01-26', -0.16],
                ['2009-01-27', 0.26],
                ['2009-01-28', 0.74],
                ['2009-01-29', 0.26],
                ['2009-01-30', 0.08],
                ['2009-02-02', -0.22],
                ['2009-02-03', -0.14],
                ['2009-02-04', -0.20],
                ['2009-02-05', 0.44],
                ['2009-02-06', 1.01],
                ['2009-02-09', 0.74],
                ['2009-02-10', 0.10],
                ['2009-02-11', 0.75],
                ['2009-02-12', 0.92],
                ['2009-02-13', 0.47],
                ['2009-02-17', -0.15],
                ['2009-02-18', -0.18],
                ['2009-02-19', -0.78],
                ['2009-02-20', -0.78],
                ['2009-02-23', -1.48],
                ['2009-02-24', -1.03],
                ['2009-02-25', -1.69],
                ['2009-02-26', -2.21],
                ['2009-02-27', -2.96],
                ['2009-03-02', -4.38],
                ['2009-03-03', -4.82],
                ['2009-03-04', -4.56],
                ['2009-03-05', -4.88],
                ['2009-03-06', -4.87],
                ['2009-03-09', -5.41],
                ['2009-03-10', -4.53],
                ['2009-03-11', -4.23],
                ['2009-03-12', -2.81],
                ['2009-03-13', -2.39],
                ['2009-03-16', -2.70],
                ['2009-03-17', -2.07],
                ['2009-03-18', -0.47],
                ['2009-03-19', -0.68],
                ['2009-03-20', -1.36],
                ['2009-03-23', 0.04],
                ['2009-03-24', -0.62],
                ['2009-03-25', -0.19],
                ['2009-03-26', 0.59],
                ['2009-03-27', 0.01],
                ['2009-03-30', -0.93],
                ['2009-03-31', -0.58],
                ['2009-04-01', -0.20],
                ['2009-04-02', 0.02],
                ['2009-04-03', -0.17],
                ['2009-04-06', -0.74],
                ['2009-04-07', -0.94],
                ['2009-04-08', -0.43],
                ['2009-04-09', 0.49],
                ['2009-04-13', 0.94],
                ['2009-04-14', 0.44],
                ['2009-04-15', 0.79],
                ['2009-04-16', 0.93],
                ['2009-04-17', 0.95],
                ['2009-04-20', 0.39],
                ['2009-04-21', 0.73],
                ['2009-04-22', 0.51],
                ['2009-04-23', 0.84],
                ['2009-04-24', 1.26],
                ['2009-04-27', 1.02],
                ['2009-04-28', 0.75],
                ['2009-04-29', 1.35],
                ['2009-04-30', 1.23],
                ['2009-05-01', 1.16],
                ['2009-05-04', 2.45],
                ['2009-05-05', 2.33],
                ['2009-05-06', 2.86],
                ['2009-05-07', 2.60],
                ['2009-05-08', 3.23],
                ['2009-05-11', 2.86],
                ['2009-05-12', 3.00],
                ['2009-05-13', 2.44],
                ['2009-05-14', 2.70],
                ['2009-05-15', 2.41],
                ['2009-05-18', 2.83],
                ['2009-05-19', 2.97],
                ['2009-05-20', 3.22],
                ['2009-05-21', 2.96],
                ['2009-05-22', 2.91],
                ['2009-05-26', 3.47],
                ['2009-05-27', 2.81],
                ['2009-05-28', 3.26],
                ['2009-05-29', 4.21],
                ['2009-06-01', 4.48],
                ['2009-06-02', 4.70],
                ['2009-06-03', 4.16],
                ['2009-06-04', 4.48],
                ['2009-06-05', 3.82],
                ['2009-06-08', 3.71],
                ['2009-06-09', 4.05],
                ['2009-06-10', 3.90],
                ['2009-06-11', 4.22],
                ['2009-06-12', 4.09],
                ['2009-06-15', 3.33],
                ['2009-06-16', 3.30],
                ['2009-06-17', 3.52],
                ['2009-06-18', 3.40],
                ['2009-06-19', 3.60],
                ['2009-06-22', 2.76],
                ['2009-06-23', 2.85],
                ['2009-06-24', 3.07],
                ['2009-06-25', 4.05],
                ['2009-06-26', 4.04],
                ['2009-06-29', 4.23],
                ['2009-06-30', 4.19],
                ['2009-07-01', 4.54],
                ['2009-07-02', 3.73],
                ['2009-07-06', 3.75],
                ['2009-07-07', 3.31],
                ['2009-07-08', 3.20],
                ['2009-07-09', 3.22],
                ['2009-07-10', 3.29],
                ['2009-07-13', 3.98],
                ['2009-07-14', 4.16],
                ['2009-07-15', 4.93],
                ['2009-07-16', 5.26],
                ['2009-07-17', 5.07],
                ['2009-07-20', 5.64],
                ['2009-07-21', 5.84],
                ['2009-07-22', 5.86],
                ['2009-07-23', 6.31],
                ['2009-07-24', 6.55],
                ['2009-07-27', 6.69],
                ['2009-07-28', 6.44],
                ['2009-07-29', 6.31],
                ['2009-07-30', 6.68],
                ['2009-07-31', 7.23],
                ['2009-08-03', 7.48],
                ['2009-08-04', 7.73],
                ['2009-08-05', 7.63],
                ['2009-08-06', 7.44],
                ['2009-08-07', 7.53],
                ['2009-08-10', 7.46],
                ['2009-08-11', 7.23],
                ['2009-08-12', 7.50],
                ['2009-08-13', 7.98],
                ['2009-08-14', 7.74],
                ['2009-08-17', 7.02],
                ['2009-08-18', 7.26],
                ['2009-08-19', 7.68],
                ['2009-08-20', 7.91],
                ['2009-08-21', 8.52],
                ['2009-08-24', 8.35],
                ['2009-08-25', 8.56],
                ['2009-08-26', 8.62],
                ['2009-08-27', 8.79],
                ['2009-08-28', 9.05],
                ['2009-08-31', 8.84],
                ['2009-09-01', 8.34],
                ['2009-09-02', 8.76],
                ['2009-09-03', 9.16],
                ['2009-09-04', 9.50],
                ['2009-09-08', 9.65],
                ['2009-09-09', 9.87],
                ['2009-09-10', 10.40],
                ['2009-09-11', 10.59],
                ['2009-09-14', 10.53],
                ['2009-09-15', 10.78],
                ['2009-09-16', 11.30],
                ['2009-09-17', 11.18],
                ['2009-09-18', 11.03],
                ['2009-09-21', 10.96],
                ['2009-09-22', 11.42],
                ['2009-09-23', 11.12],
                ['2009-09-24', 10.66],
                ['2009-09-25', 10.56],
                ['2009-09-28', 11.07],
                ['2009-09-29', 11.07],
                ['2009-09-30', 11.35],
                ['2009-10-01', 10.47],
                ['2009-10-02', 10.45],
                ['2009-10-05', 11.09],
                ['2009-10-06', 11.81],
                ['2009-10-07', 12.12],
                ['2009-10-08', 12.44],
                ['2009-10-09', 12.49],
                ['2009-10-12', 12.79],
                ['2009-10-13', 12.91],
                ['2009-10-14', 13.27],
                ['2009-10-15', 13.04],
                ['2009-10-16', 12.95],
                ['2009-10-19', 13.49],
                ['2009-10-20', 13.21],
                ['2009-10-21', 13.00],
                ['2009-10-22', 13.30],
                ['2009-10-23', 12.68],
                ['2009-10-26', 11.99],
                ['2009-10-27', 11.99],
                ['2009-10-28', 11.23],
                ['2009-10-29', 11.99],
                ['2009-10-30', 10.98],
                ['2009-11-02', 11.21],
                ['2009-11-03', 11.58],
                ['2009-11-04', 11.71],
                ['2009-11-05', 12.32],
                ['2009-11-06', 12.61],
                ['2009-11-09', 13.42],
                ['2009-11-10', 13.47],
                ['2009-11-11', 13.94],
                ['2009-11-12', 13.42],
                ['2009-11-13', 13.82],
                ['2009-11-16', 14.68],
                ['2009-11-17', 14.77],
                ['2009-11-18', 14.65],
                ['2009-11-19', 14.30],
                ['2009-11-20', 14.34],
                ['2009-11-23', 14.90],
                ['2009-11-24', 15.14],
                ['2009-11-25', 15.53],
                ['2009-11-27', 15.15],
                ['2009-11-30', 15.36],
                ['2009-12-01', 15.84],
                ['2009-12-02', 16.24],
                ['2009-12-03', 15.94],
                ['2009-12-04', 15.33],
                ['2009-12-07', 15.39],
                ['2009-12-08', 14.95],
                ['2009-12-09', 14.94],
                ['2009-12-10', 15.10],
                ['2009-12-11', 14.91],
                ['2009-12-14', 15.34],
                ['2009-12-15', 15.21],
                ['2009-12-16', 15.41],
                ['2009-12-17', 14.72],
                ['2009-12-18', 14.94],
                ['2009-12-21', 14.93],
                ['2009-12-22', 14.79],
                ['2009-12-23', 14.95],
                ['2009-12-24', 15.27],
                ['2009-12-28', 15.30],
                ['2009-12-29', 15.11],
                ['2009-12-30', 15.12],
                ['2009-12-31', 14.54],
                ['2010-01-04', 15.25],
                ['2010-01-05', 15.17],
                ['2010-01-06', 15.34],
                ['2010-01-07', 15.33],
                ['2010-01-08', 15.47],
                ['2010-01-11', 15.86],
                ['2010-01-12', 15.36],
                ['2010-01-13', 15.67],
                ['2010-01-14', 15.84],
                ['2010-01-15', 15.42],
                ['2010-01-19', 15.99],
                ['2010-01-20', 15.32],
                ['2010-01-21', 14.86],
                ['2010-01-22', 14.28],
                ['2010-01-25', 14.37],
                ['2010-01-26', 14.46],
                ['2010-01-27', 14.32],
                ['2010-01-28', 14.16],
                ['2010-01-29', 13.91],
                ['2010-02-01', 14.51],
                ['2010-02-02', 14.94],
                ['2010-02-03', 14.70],
                ['2010-02-04', 13.27],
                ['2010-02-05', 13.53],
                ['2010-02-08', 13.26],
                ['2010-02-09', 13.76],
                ['2010-02-10', 13.57],
                ['2010-02-11', 14.19],
                ['2010-02-12', 14.20],
                ['2010-02-16', 15.03],
                ['2010-02-17', 15.12],
                ['2010-02-18', 15.37],
                ['2010-02-19', 15.41],
                ['2010-02-22', 15.33],
                ['2010-02-23', 14.98],
                ['2010-02-24', 15.25],
                ['2010-02-25', 15.40],
                ['2010-02-26', 15.63],
                ['2010-03-01', 15.90],
                ['2010-03-02', 16.36],
                ['2010-03-03', 16.38],
                ['2010-03-04', 16.31],
                ['2010-03-05', 16.70],
                ['2010-03-08', 16.51],
                ['2010-03-09', 16.55],
                ['2010-03-10', 16.53],
                ['2010-03-11', 16.54],
                ['2010-03-12', 16.40],
                ['2010-03-15', 16.49],
                ['2010-03-16', 17.05],
                ['2010-03-17', 17.19],
                ['2010-03-18', 17.22],
                ['2010-03-19', 16.79],
                ['2010-03-22', 16.96],
                ['2010-03-23', 17.16],
                ['2010-03-24', 16.50],
                ['2010-03-25', 16.31],
                ['2010-03-26', 16.58],
                ['2010-03-29', 16.78],
                ['2010-03-30', 16.80],
                ['2010-03-31', 16.90],
                ['2010-04-01', 17.21],
                ['2010-04-05', 17.42],
                ['2010-04-06', 17.43],
                ['2010-04-07', 17.62],
                ['2010-04-08', 17.62],
                ['2010-04-09', 18.00],
                ['2010-04-12', 18.06],
                ['2010-04-13', 18.16],
                ['2010-04-14', 18.51],
                ['2010-04-15', 18.74],
                ['2010-04-16', 18.24],
                ['2010-04-19', 18.20],
                ['2010-04-20', 18.48],
                ['2010-04-21', 18.59],
                ['2010-04-22', 18.43],
                ['2010-04-23', 18.81],
                ['2010-04-26', 18.61],
                ['2010-04-27', 18.42],
                ['2010-04-28', 18.39],
                ['2010-04-29', 18.77],
                ['2010-04-30', 18.67],
                ['2010-05-03', 18.90],
                ['2010-05-04', 18.11],
                ['2010-05-05', 18.02],
                ['2010-05-06', 17.62],
                ['2010-05-07', 16.85],
                ['2010-05-10', 18.09],
                ['2010-05-11', 18.44],
                ['2010-05-12', 18.95],
                ['2010-05-13', 18.69],
                ['2010-05-14', 18.23],
                ['2010-05-17', 18.11],
                ['2010-05-18', 17.92],
                ['2010-05-19', 17.29],
                ['2010-05-20', 16.04],
                ['2010-05-21', 16.27],
                ['2010-05-24', 16.21],
                ['2010-05-25', 16.14],
                ['2010-05-26', 16.10],
                ['2010-05-27', 16.84],
                ['2010-05-28', 16.78],
                ['2010-06-01', 16.29],
                ['2010-06-02', 17.02],
                ['2010-06-03', 16.97],
                ['2010-06-04', 16.21],
                ['2010-06-07', 16.28],
                ['2010-06-08', 16.49],
                ['2010-06-09', 16.32],
                ['2010-06-10', 16.85],
                ['2010-06-11', 17.18],
                ['2010-06-14', 17.17],
                ['2010-06-15', 18.03],
                ['2010-06-16', 18.04],
                ['2010-06-17', 18.46],
                ['2010-06-18', 18.89],
                ['2010-06-21', 18.44],
                ['2010-06-22', 18.18],
                ['2010-06-23', 18.05],
                ['2010-06-24', 17.69],
                ['2010-06-25', 18.03],
                ['2010-06-28', 17.98],
                ['2010-06-29', 17.13],
                ['2010-06-30', 17.31],
                ['2010-07-01', 16.49],
                ['2010-07-02', 16.71],
                ['2010-07-06', 16.51],
                ['2010-07-07', 17.59],
                ['2010-07-08', 17.68],
                ['2010-07-09', 18.01],
                ['2010-07-12', 17.90],
                ['2010-07-13', 18.28],
                ['2010-07-14', 18.45],
                ['2010-07-15', 18.43],
                ['2010-07-16', 17.68],
                ['2010-07-19', 17.73],
                ['2010-07-20', 18.13],
                ['2010-07-21', 17.73],
                ['2010-07-22', 18.40],
                ['2010-07-23', 18.47],
                ['2010-07-26', 18.68],
                ['2010-07-27', 18.34],
                ['2010-07-28', 18.23],
                ['2010-07-29', 18.01],
                ['2010-07-30', 18.36],
                ['2010-08-02', 18.74],
                ['2010-08-03', 18.81],
                ['2010-08-04', 19.07],
                ['2010-08-05', 19.06],
                ['2010-08-06', 19.31],
                ['2010-08-09', 19.50],
                ['2010-08-10', 19.41],
                ['2010-08-11', 18.70],
                ['2010-08-12', 18.79],
                ['2010-08-13', 18.71],
                ['2010-08-16', 19.00],
                ['2010-08-17', 19.32],
                ['2010-08-18', 19.43],
                ['2010-08-19', 19.18],
                ['2010-08-20', 19.06],
                ['2010-08-23', 18.95],
                ['2010-08-24', 18.74],
                ['2010-08-25', 18.97],
                ['2010-08-26', 18.73],
                ['2010-08-27', 19.01],
                ['2010-08-30', 18.65],
                ['2010-08-31', 18.78],
                ['2010-09-01', 19.29],
                ['2010-09-02', 19.60],
                ['2010-09-03', 19.60],
                ['2010-09-07', 19.60],
                ['2010-09-08', 19.71],
                ['2010-09-09', 19.57],
                ['2010-09-10', 19.62],
                ['2010-09-13', 19.88],
                ['2010-09-14', 20.37],
                ['2010-09-15', 20.53],
                ['2010-09-16', 20.55],
                ['2010-09-17', 20.63],
                ['2010-09-20', 21.13],
                ['2010-09-21', 21.42],
                ['2010-09-22', 21.38],
                ['2010-09-23', 21.23],
                ['2010-09-24', 21.79],
                ['2010-09-27', 21.77],
                ['2010-09-28', 22.26],
                ['2010-09-29', 22.18],
                ['2010-09-30', 22.10],
                ['2010-10-01', 22.31],
                ['2010-10-04', 22.13],
                ['2010-10-05', 22.95],
                ['2010-10-06', 23.02],
                ['2010-10-07', 22.82],
                ['2010-10-08', 23.11],
                ['2010-10-11', 23.22],
                ['2010-10-12', 23.20],
                ['2010-10-13', 23.78],
                ['2010-10-14', 23.70],
                ['2010-10-15', 23.59],
                ['2010-10-18', 23.82],
                ['2010-10-19', 22.92],
                ['2010-10-20', 23.28],
                ['2010-10-21', 23.04],
                ['2010-10-22', 23.19],
                ['2010-10-25', 23.47],
                ['2010-10-26', 23.33],
                ['2010-10-27', 23.14],
                ['2010-10-28', 23.33],
                ['2010-10-29', 23.50],
                ['2010-11-01', 23.44],
                ['2010-11-02', 23.75],
                ['2010-11-03', 23.85],
                ['2010-11-04', 24.89],
                ['2010-11-05', 25.00],
                ['2010-11-08', 25.08],
                ['2010-11-09', 24.46],
                ['2010-11-10', 24.77],
                ['2010-11-11', 24.65],
                ['2010-11-12', 23.76],
                ['2010-11-15', 23.33],
                ['2010-11-16', 22.85],
                ['2010-11-17', 22.75],
                ['2010-11-18', 23.37],
                ['2010-11-19', 23.38],
                ['2010-11-22', 23.69],
                ['2010-11-23', 23.55],
                ['2010-11-24', 23.65],
                ['2010-11-26', 23.35],
                ['2010-11-29', 23.30],
                ['2010-11-30', 23.38],
                ['2010-12-01', 23.67],
                ['2010-12-02', 23.87],
                ['2010-12-03', 24.32],
                ['2010-12-06', 24.39],
                ['2010-12-07', 23.89],
                ['2010-12-08', 23.53],
                ['2010-12-09', 23.77],
                ['2010-12-10', 23.75],
                ['2010-12-13', 23.91],
                ['2010-12-14', 23.93],
                ['2010-12-15', 23.60],
                ['2010-12-16', 23.81],
                ['2010-12-17', 24.11],
                ['2010-12-20', 24.16],
                ['2010-12-21', 24.37],
                ['2010-12-22', 24.19],
                ['2010-12-23', 23.98],
                ['2010-12-27', 24.10],
                ['2010-12-28', 23.99],
                ['2010-12-29', 24.22],
                ['2010-12-30', 24.13],
                ['2010-12-31', 24.60],
                ['2011-01-03', 24.71],
                ['2011-01-04', 24.40],
                ['2011-01-05', 24.20],
                ['2011-01-06', 24.11],
                ['2011-01-07', 24.20],
                ['2011-01-10', 24.30],
                ['2011-01-11', 24.45],
                ['2011-01-12', 24.62],
                ['2011-01-13', 24.55],
                ['2011-01-14', 24.45],
                ['2011-01-18', 24.64],
                ['2011-01-19', 24.46],
                ['2011-01-20', 23.96],
                ['2011-01-21', 24.04],
                ['2011-01-24', 24.13],
                ['2011-01-25', 24.23],
                ['2011-01-26', 24.35],
                ['2011-01-27', 24.11],
                ['2011-01-28', 23.86],
                ['2011-01-31', 24.00],
                ['2011-02-01', 24.39],
                ['2011-02-02', 24.28],
                ['2011-02-03', 24.45],
                ['2011-02-04', 24.45],
                ['2011-02-07', 24.67],
                ['2011-02-08', 24.77],
                ['2011-02-09', 24.80],
                ['2011-02-10', 24.70],
                ['2011-02-11', 24.94],
                ['2011-02-14', 25.04],
                ['2011-02-15', 25.20],
                ['2011-02-16', 25.41],
                ['2011-02-17', 25.69],
                ['2011-02-18', 25.83],
                ['2011-02-22', 25.56],
                ['2011-02-23', 25.39],
                ['2011-02-24', 25.28],
                ['2011-02-25', 25.87],
                ['2011-02-28', 25.94],
                ['2011-03-01', 25.93],
                ['2011-03-02', 25.97],
                ['2011-03-03', 26.18],
                ['2011-03-04', 26.28],
                ['2011-03-07', 26.02],
                ['2011-03-08', 26.13],
                ['2011-03-09', 26.19],
                ['2011-03-10', 25.54],
                ['2011-03-11', 25.84],
                ['2011-03-14', 25.82],
                ['2011-03-15', 25.27],
                ['2011-03-16', 24.91],
                ['2011-03-17', 25.14],
                ['2011-03-18', 25.55],
                ['2011-03-21', 25.93],
                ['2011-03-22', 25.81],
                ['2011-03-23', 26.08],
                ['2011-03-24', 26.18],
                ['2011-03-25', 26.21],
                ['2011-03-28', 26.10],
                ['2011-03-29', 26.19],
                ['2011-03-30', 26.58],
                ['2011-03-31', 26.67],
                ['2011-04-01', 26.69],
                ['2011-04-04', 26.90],
                ['2011-04-05', 27.10],
                ['2011-04-06', 27.26],
                ['2011-04-07', 27.25],
                ['2011-04-08', 27.07],
                ['2011-04-11', 27.05],
                ['2011-04-12', 26.76],
                ['2011-04-13', 26.86],
                ['2011-04-14', 27.16],
                ['2011-04-15', 27.66],
                ['2011-04-18', 27.48],
                ['2011-04-19', 27.63],
                ['2011-04-20', 28.10],
                ['2011-04-21', 28.17],
                ['2011-04-25', 28.25],
                ['2011-04-26', 28.62],
                ['2011-04-27', 29.12],
                ['2011-04-28', 29.34],
                ['2011-04-29', 29.66],
                ['2011-05-02', 29.47],
                ['2011-05-03', 29.44],
                ['2011-05-04', 29.01],
                ['2011-05-05', 28.53],
                ['2011-05-06', 28.92],
                ['2011-05-09', 29.29],
                ['2011-05-10', 29.60],
                ['2011-05-11', 29.23],
                ['2011-05-12', 29.54],
                ['2011-05-13', 29.28],
                ['2011-05-16', 29.09],
                ['2011-05-17', 29.07],
                ['2011-05-18', 29.39],
                ['2011-05-19', 29.40],
                ['2011-05-20', 29.59],
                ['2011-05-23', 29.35],
                ['2011-05-24', 29.24],
                ['2011-05-25', 29.38],
                ['2011-05-26', 29.52],
                ['2011-05-27', 29.76],
                ['2011-05-31', 30.19],
                ['2011-06-01', 29.76],
                ['2011-06-02', 29.52],
                ['2011-06-03', 29.34],
                ['2011-06-06', 29.11],
                ['2011-06-07', 29.21],
                ['2011-06-08', 29.10],
                ['2011-06-09', 29.30],
                ['2011-06-10', 28.68],
                ['2011-06-13', 28.53],
                ['2011-06-14', 28.86],
                ['2011-06-15', 28.60],
                ['2011-06-16', 28.61],
                ['2011-06-17', 28.83],
                ['2011-06-20', 29.03],
                ['2011-06-21', 29.43],
                ['2011-06-22', 29.25],
                ['2011-06-23', 28.94],
                ['2011-06-24', 28.39],
                ['2011-06-27', 28.43],
                ['2011-06-28', 28.77],
                ['2011-06-29', 28.86],
                ['2011-06-30', 28.94],
                ['2011-07-01', 29.20],
                ['2011-07-05', 29.51],
                ['2011-07-06', 29.79],
                ['2011-07-07', 30.09],
                ['2011-07-08', 30.17],
                ['2011-07-11', 29.89],
                ['2011-07-12', 29.92],
                ['2011-07-13', 30.22],
                ['2011-07-14', 29.93],
                ['2011-07-15', 30.17],
                ['2011-07-18', 29.93],
                ['2011-07-19', 30.29],
                ['2011-07-20', 30.32],
                ['2011-07-21', 30.35],
                ['2011-07-22', 30.56],
                ['2011-07-25', 30.34],
                ['2011-07-26', 30.33],
                ['2011-07-27', 29.59],
                ['2011-07-28', 29.43],
                ['2011-07-29', 29.55],
                ['2011-08-01', 29.35],
                ['2011-08-02', 29.26],
                ['2011-08-03', 29.41],
                ['2011-08-04', 27.94],
                ['2011-08-05', 28.01],
                ['2011-08-08', 26.54],
                ['2011-08-09', 28.27],
                ['2011-08-10', 28.07],
                ['2011-08-11', 28.80],
                ['2011-08-12', 29.10],
                ['2011-08-15', 29.89],
                ['2011-08-16', 29.84],
                ['2011-08-17', 30.17],
                ['2011-08-18', 29.30],
                ['2011-08-19', 29.31],
                ['2011-08-22', 29.87],
                ['2011-08-23', 30.20],
                ['2011-08-24', 29.65],
                ['2011-08-25', 29.34],
                ['2011-08-26', 30.45],
                ['2011-08-29', 30.87],
                ['2011-08-30', 31.53],
                ['2011-08-31', 31.71],
                ['2011-09-01', 31.41],
                ['2011-09-02', 31.36],
                ['2011-09-06', 31.20],
                ['2011-09-07', 31.45],
                ['2011-09-08', 31.73],
                ['2011-09-09', 30.91],
                ['2011-09-12', 30.70],
                ['2011-09-13', 31.08],
                ['2011-09-14', 31.36],
                ['2011-09-15', 31.41],
                ['2011-09-16', 31.86],
                ['2011-09-19', 31.34],
                ['2011-09-20', 31.77],
                ['2011-09-21', 30.79],
                ['2011-09-22', 29.40],
                ['2011-09-23', 28.48],
                ['2011-09-26', 28.54],
                ['2011-09-27', 29.23],
                ['2011-09-28', 28.09],
                ['2011-09-29', 28.43],
                ['2011-09-30', 27.98],
                ['2011-10-03', 27.28],
                ['2011-10-04', 27.60],
                ['2011-10-05', 28.04],
                ['2011-10-06', 28.66],
                ['2011-10-07', 28.35],
                ['2011-10-10', 29.55],
                ['2011-10-11', 29.37],
                ['2011-10-12', 29.76],
                ['2011-10-13', 29.77],
                ['2011-10-14', 30.26],
                ['2011-10-17', 29.93],
                ['2011-10-18', 30.07],
                ['2011-10-19', 29.75],
                ['2011-10-20', 29.56],
                ['2011-10-21', 29.78],
                ['2011-10-24', 30.69],
                ['2011-10-25', 30.96],
                ['2011-10-26', 31.25],
                ['2011-10-27', 31.94],
                ['2011-10-28', 32.16],
                ['2011-10-31', 31.98],
                ['2011-11-01', 31.88],
                ['2011-11-02', 31.90],
                ['2011-11-03', 32.41],
                ['2011-11-04', 32.18],
                ['2011-11-07', 32.59],
                ['2011-11-08', 32.90],
                ['2011-11-09', 31.99],
                ['2011-11-10', 31.86],
                ['2011-11-11', 32.85],
                ['2011-11-14', 32.46],
                ['2011-11-15', 32.63],
                ['2011-11-16', 32.01],
                ['2011-11-17', 30.98],
                ['2011-11-18', 31.02],
                ['2011-11-21', 29.81],
                ['2011-11-22', 29.97],
                ['2011-11-23', 29.29],
                ['2011-11-25', 28.76],
                ['2011-11-28', 29.88],
                ['2011-11-29', 29.98],
                ['2011-11-30', 31.28],
                ['2011-12-01', 31.56],
                ['2011-12-02', 31.63],
                ['2011-12-05', 31.46],
                ['2011-12-06', 31.65],
                ['2011-12-07', 31.91],
                ['2011-12-08', 31.50],
                ['2011-12-09', 31.50],
                ['2011-12-12', 30.49],
                ['2011-12-13', 29.91],
                ['2011-12-14', 29.23],
                ['2011-12-15', 29.40],
                ['2011-12-16', 29.78],
                ['2011-12-19', 29.64],
                ['2011-12-20', 30.06],
                ['2011-12-21', 30.62],
                ['2011-12-22', 30.71],
                ['2011-12-23', 30.86],
                ['2011-12-27', 30.88],
                ['2011-12-28', 30.26],
                ['2011-12-29', 31.18],
                ['2011-12-30', 31.82],
                ['2012-01-03', 32.43],
                ['2012-01-04', 32.26],
                ['2012-01-05', 32.44],
                ['2012-01-06', 32.61],
                ['2012-01-09', 32.60],
                ['2012-01-10', 33.12],
                ['2012-01-11', 33.42],
                ['2012-01-12', 33.59],
                ['2012-01-13', 33.35],
                ['2012-01-17', 33.71],
                ['2012-01-18', 34.22],
                ['2012-01-19', 34.37],
                ['2012-01-20', 34.42],
                ['2012-01-23', 34.44],
                ['2012-01-24', 34.40],
                ['2012-01-25', 35.32],
                ['2012-01-26', 35.56],
                ['2012-01-27', 35.88],
                ['2012-01-30', 35.67],
                ['2012-01-31', 36.08],
                ['2012-02-01', 36.52],
                ['2012-02-02', 36.71],
                ['2012-02-03', 36.46],
                ['2012-02-06', 36.23],
                ['2012-02-07', 36.63],
                ['2012-02-08', 36.42],
                ['2012-02-09', 36.44],
                ['2012-02-10', 36.12],
                ['2012-02-13', 36.35],
                ['2012-02-14', 36.46],
                ['2012-02-15', 36.56],
                ['2012-02-16', 36.74],
                ['2012-02-17', 36.64],
                ['2012-02-21', 36.79],
                ['2012-02-22', 37.01],
                ['2012-02-23', 37.19],
                ['2012-02-24', 37.31],
                ['2012-02-27', 37.35],
                ['2012-02-28', 37.74],
                ['2012-02-29', 36.56],
                ['2012-03-01', 36.86],
                ['2012-03-02', 36.74],
                ['2012-03-05', 36.60],
                ['2012-03-06', 35.91],
                ['2012-03-07', 36.38],
                ['2012-03-08', 36.83],
                ['2012-03-09', 37.04],
                ['2012-03-12', 36.94],
                ['2012-03-13', 36.79],
                ['2012-03-14', 36.20],
                ['2012-03-15', 36.55],
                ['2012-03-16', 36.54],
                ['2012-03-19', 36.59],
                ['2012-03-20', 36.27],
                ['2012-03-21', 36.42],
                ['2012-03-22', 36.18],
                ['2012-03-23', 36.48],
                ['2012-03-26', 37.19],
                ['2012-03-27', 37.04],
                ['2012-03-28', 36.61],
                ['2012-03-29', 36.74],
                ['2012-03-30', 36.99],
                ['2012-04-02', 37.27],
                ['2012-04-03', 36.63],
                ['2012-04-04', 36.29],
                ['2012-04-05', 36.47],
                ['2012-04-09', 36.51],
                ['2012-04-10', 36.20],
                ['2012-04-11', 36.40],
                ['2012-04-12', 37.07],
                ['2012-04-13', 36.66],
                ['2012-04-16', 36.52],
                ['2012-04-17', 36.94],
                ['2012-04-18', 36.87],
                ['2012-04-19', 36.72],
                ['2012-04-20', 36.90],
                ['2012-04-23', 36.47],
                ['2012-04-24', 36.52],
                ['2012-04-25', 36.98],
                ['2012-04-26', 37.51],
                ['2012-04-27', 37.74],
                ['2012-04-30', 37.78],
                ['2012-05-01', 37.85],
                ['2012-05-02', 37.84],
                ['2012-05-03', 37.40],
                ['2012-05-04', 37.14],
                ['2012-05-07', 37.12],
                ['2012-05-08', 36.66],
                ['2012-05-09', 36.31],
                ['2012-05-10', 36.49],
                ['2012-05-11', 36.39],
                ['2012-05-14', 35.88],
                ['2012-05-15', 35.55],
                ['2012-05-16', 35.37],
                ['2012-05-17', 35.05],
                ['2012-05-18', 34.80],
                ['2012-05-21', 35.33],
                ['2012-05-22', 34.97],
                ['2012-05-23', 35.03],
                ['2012-05-24', 35.04],
                ['2012-05-25', 35.23],
                ['2012-05-29', 35.35],
                ['2012-05-30', 35.23],
                ['2012-05-31', 35.43],
                ['2012-06-01', 35.43],
                ['2012-06-04', 35.36],
                ['2012-06-05', 35.52],
                ['2012-06-06', 36.12],
                ['2012-06-07', 35.92],
                ['2012-06-08', 36.04],
                ['2012-06-11', 35.98],
                ['2012-06-12', 36.31],
                ['2012-06-13', 36.28],
                ['2012-06-14', 36.55],
                ['2012-06-15', 37.08],
                ['2012-06-18', 37.33],
                ['2012-06-19', 37.52],
                ['2012-06-20', 37.23],
                ['2012-06-21', 36.01],
                ['2012-06-22', 36.24],
                ['2012-06-25', 36.11],
                ['2012-06-26', 36.17],
                ['2012-06-27', 36.33],
                ['2012-06-28', 36.52],
                ['2012-06-29', 37.82],
                ['2012-07-02', 38.04],
                ['2012-07-03', 38.32],
                ['2012-07-05', 38.13],
                ['2012-07-06', 37.67],
                ['2012-07-09', 37.84],
                ['2012-07-10', 37.41],
                ['2012-07-11', 37.45],
                ['2012-07-12', 37.40],
                ['2012-07-13', 37.99],
                ['2012-07-16', 37.93],
                ['2012-07-17', 38.05],
                ['2012-07-18', 38.33],
                ['2012-07-19', 38.31],
                ['2012-07-20', 38.04],
                ['2012-07-23', 37.56],
                ['2012-07-24', 37.37],
                ['2012-07-25', 37.77],
                ['2012-07-26', 38.25],
                ['2012-07-27', 38.96],
                ['2012-07-30', 38.96],
                ['2012-07-31', 38.85],
                ['2012-08-01', 38.50],
                ['2012-08-02', 38.39],
                ['2012-08-03', 38.86],
                ['2012-08-06', 38.98],
                ['2012-08-07', 38.99],
                ['2012-08-08', 39.14],
                ['2012-08-09', 39.13],
                ['2012-08-10', 39.23],
                ['2012-08-13', 38.97],
                ['2012-08-14', 38.79],
                ['2012-08-15', 38.81],
                ['2012-08-16', 38.98],
                ['2012-08-17', 38.99],
                ['2012-08-20', 39.04],
                ['2012-08-21', 39.19],
                ['2012-08-22', 39.47],
                ['2012-08-23', 39.50],
                ['2012-08-24', 39.73],
                ['2012-08-27', 39.68],
                ['2012-08-28', 39.75],
                ['2012-08-29', 39.69],
                ['2012-08-30', 39.51],
                ['2012-08-31', 40.33],
                ['2012-09-04', 40.26],
                ['2012-09-05', 40.07],
                ['2012-09-06', 40.71],
                ['2012-09-07', 41.05],
                ['2012-09-10', 40.83],
                ['2012-09-11', 40.94],
                ['2012-09-12', 40.85],
                ['2012-09-13', 41.64],
                ['2012-09-14', 41.55],
                ['2012-09-17', 41.33],
                ['2012-09-18', 41.49],
                ['2012-09-19', 41.63],
                ['2012-09-20', 41.60],
                ['2012-09-21', 41.72],
                ['2012-09-24', 41.44],
                ['2012-09-25', 41.13],
                ['2012-09-26', 41.02],
                ['2012-09-27', 41.53],
                ['2012-09-28', 41.52],
                ['2012-10-01', 41.73],
                ['2012-10-02', 41.72],
                ['2012-10-03', 41.98],
                ['2012-10-04', 42.25],
                ['2012-10-05', 42.15],
                ['2012-10-08', 41.98],
                ['2012-10-09', 41.38],
                ['2012-10-10', 41.19],
                ['2012-10-11', 41.22],
                ['2012-10-12', 40.96],
                ['2012-10-15', 41.07],
                ['2012-10-16', 41.44],
                ['2012-10-17', 41.51],
                ['2012-10-18', 41.21],
                ['2012-10-19', 40.36],
                ['2012-10-22', 40.29],
                ['2012-10-23', 39.87],
                ['2012-10-24', 39.70],
                ['2012-10-25', 39.84],
                ['2012-10-26', 39.78],
                ['2012-10-31', 40.28],
                ['2012-11-01', 40.50],
                ['2012-11-02', 39.81],
                ['2012-11-05', 40.10],
                ['2012-11-06', 40.51],
                ['2012-11-07', 40.04],
                ['2012-11-08', 40.02],
                ['2012-11-09', 39.99],
                ['2012-11-12', 39.99],
                ['2012-11-13', 39.89],
                ['2012-11-14', 39.56],
                ['2012-11-15', 39.23],
                ['2012-11-16', 39.53],
                ['2012-11-19', 40.17],
                ['2012-11-20', 40.09],
                ['2012-11-21', 40.23],
                ['2012-11-23', 40.73],
                ['2012-11-26', 40.75],
                ['2012-11-27', 40.67],
                ['2012-11-28', 40.67],
                ['2012-11-29', 40.86],
                ['2012-11-30', 40.82],
                ['2012-12-03', 40.52],
                ['2012-12-04', 40.28],
                ['2012-12-05', 40.34],
                ['2012-12-06', 40.58],
                ['2012-12-07', 40.55],
                ['2012-12-10', 40.99],
                ['2012-12-11', 41.07],
                ['2012-12-12', 40.94],
                ['2012-12-13', 40.67],
                ['2012-12-14', 40.52],
                ['2012-12-17', 40.72],
                ['2012-12-18', 40.73],
                ['2012-12-19', 40.32],
                ['2012-12-20', 40.15],
                ['2012-12-21', 40.05],
                ['2012-12-24', 39.88],
                ['2012-12-26', 39.89],
                ['2012-12-27', 39.95],
                ['2012-12-28', 39.57],
                ['2012-12-31', 40.49],
                ['2013-01-02', 41.35],
                ['2013-01-03', 41.18],
                ['2013-01-04', 41.28],
                ['2013-01-07', 41.07],
                ['2013-01-08', 41.13],
                ['2013-01-09', 41.37],
                ['2013-01-10', 41.81],
                ['2013-01-11', 41.76],
                ['2013-01-14', 41.72],
                ['2013-01-15', 41.80],
                ['2013-01-16', 41.75],
                ['2013-01-17', 42.16],
                ['2013-01-18', 42.23],
                ['2013-01-22', 42.54],
                ['2013-01-23', 42.55],
                ['2013-01-24', 42.40],
                ['2013-01-25', 42.32],
                ['2013-01-28', 42.24],
                ['2013-01-29', 42.42],
                ['2013-01-30', 42.36],
                ['2013-01-31', 42.32],
                ['2013-02-01', 42.68],
                ['2013-02-04', 42.46],
                ['2013-02-05', 42.72],
                ['2013-02-06', 42.93],
                ['2013-02-07', 42.81],
                ['2013-02-08', 43.08],
                ['2013-02-11', 42.79],
                ['2013-02-12', 42.89],
                ['2013-02-13', 42.69],
                ['2013-02-14', 42.69],
                ['2013-02-15', 42.48],
                ['2013-02-19', 42.67],
                ['2013-02-20', 41.89],
                ['2013-02-21', 41.81],
                ['2013-02-22', 42.23],
                ['2013-02-25', 42.08],
                ['2013-02-26', 42.56],
                ['2013-02-27', 42.78],
                ['2013-02-28', 42.67],
                ['2013-03-01', 42.69],
                ['2013-03-04', 42.87],
                ['2013-03-05', 43.19],
                ['2013-03-06', 43.19],
                ['2013-03-07', 43.03],
                ['2013-03-08', 43.08],
                ['2013-03-11', 43.18],
                ['2013-03-12', 43.40],
                ['2013-03-13', 43.43],
                ['2013-03-14', 43.55],
                ['2013-03-15', 43.38],
                ['2013-03-18', 43.43],
                ['2013-03-19', 43.64],
                ['2013-03-20', 43.91],
                ['2013-03-21', 43.75],
                ['2013-03-22', 44.02],
                ['2013-03-25', 43.70],
                ['2013-03-26', 44.20],
                ['2013-03-27', 44.37],
                ['2013-03-28', 44.46],
                ['2013-04-01', 44.48],
                ['2013-04-02', 44.34],
                ['2013-04-03', 43.76],
                ['2013-04-04', 44.04],
                ['2013-04-05', 44.25],
                ['2013-04-08', 44.35],
                ['2013-04-09', 44.68],
                ['2013-04-10', 44.75],
                ['2013-04-11', 44.77],
                ['2013-04-12', 44.00],
                ['2013-04-15', 41.63],
                ['2013-04-16', 42.36],
                ['2013-04-17', 41.93],
                ['2013-04-18', 41.97],
                ['2013-04-19', 42.58],
                ['2013-04-22', 42.91],
                ['2013-04-23', 43.25],
                ['2013-04-24', 43.26],
                ['2013-04-25', 43.87],
                ['2013-04-26', 43.70],
                ['2013-04-29', 44.10],
                ['2013-04-30', 44.25],
                ['2013-05-01', 43.85],
                ['2013-05-02', 44.27],
                ['2013-05-03', 44.36],
                ['2013-05-06', 44.24],
                ['2013-05-07', 44.18],
                ['2013-05-08', 44.62],
                ['2013-05-09', 44.30],
                ['2013-05-10', 44.15],
                ['2013-05-13', 43.87],
                ['2013-05-14', 44.05],
                ['2013-05-15', 43.90],
                ['2013-05-16', 43.64],
                ['2013-05-17', 43.35],
                ['2013-05-20', 43.71],
                ['2013-05-21', 43.62],
                ['2013-05-22', 42.96],
                ['2013-05-23', 43.32],
                ['2013-05-24', 43.19],
                ['2013-05-28', 43.09],
                ['2013-05-29', 42.90],
                ['2013-05-30', 43.36],
                ['2013-05-31', 42.47],
                ['2013-06-03', 42.98],
                ['2013-06-04', 42.59],
                ['2013-06-05', 42.23],
                ['2013-06-06', 42.80],
                ['2013-06-07', 42.49],
                ['2013-06-10', 42.52],
                ['2013-06-11', 42.12],
                ['2013-06-12', 42.03],
                ['2013-06-13', 42.66],
                ['2013-06-14', 42.74],
                ['2013-06-17', 42.87],
                ['2013-06-18', 42.79],
                ['2013-06-19', 41.74],
                ['2013-06-20', 39.56],
                ['2013-06-21', 39.88],
                ['2013-06-24', 39.24],
                ['2013-06-25', 39.52],
                ['2013-06-26', 39.15],
                ['2013-06-27', 38.98],
                ['2013-06-28', 39.20],
                ['2013-07-01', 39.63],
                ['2013-07-02', 39.44],
                ['2013-07-03', 39.64],
                ['2013-07-05', 39.16],
                ['2013-07-08', 39.63],
                ['2013-07-09', 39.99],
                ['2013-07-10', 40.11],
                ['2013-07-11', 41.41],
                ['2013-07-12', 41.41],
                ['2013-07-15', 41.58],
                ['2013-07-16', 41.63],
                ['2013-07-17', 41.53],
                ['2013-07-18', 41.69],
                ['2013-07-19', 42.01],
                ['2013-07-22', 42.59],
                ['2013-07-23', 42.53],
                ['2013-07-24', 41.76],
                ['2013-07-25', 42.21],
                ['2013-07-26', 42.30],
                ['2013-07-29', 42.17],
                ['2013-07-30', 42.17],
                ['2013-07-31', 41.97],
                ['2013-08-01', 41.97],
                ['2013-08-02', 42.11],
                ['2013-08-05', 41.94],
                ['2013-08-06', 41.49],
                ['2013-08-07', 41.43],
                ['2013-08-08', 42.17],
                ['2013-08-09', 41.97],
                ['2013-08-12', 42.18],
                ['2013-08-13', 41.79],
                ['2013-08-14', 41.79],
                ['2013-08-15', 41.40],
                ['2013-08-16', 41.19],
                ['2013-08-19', 40.95],
                ['2013-08-20', 41.33],
                ['2013-08-21', 40.95],
                ['2013-08-22', 41.22],
                ['2013-08-23', 41.75],
                ['2013-08-26', 41.72],
                ['2013-08-27', 41.61],
                ['2013-08-28', 41.54],
                ['2013-08-29', 41.59],
                ['2013-08-30', 41.34],
                ['2013-09-03', 41.49],
                ['2013-09-04', 41.44],
                ['2013-09-05', 41.00],
                ['2013-09-06', 41.46],
                ['2013-09-09', 41.74],
                ['2013-09-10', 41.68],
                ['2013-09-11', 41.83],
                ['2013-09-12', 41.22],
                ['2013-09-13', 41.42],
                ['2013-09-16', 41.51],
                ['2013-09-17', 41.76],
                ['2013-09-18', 43.37],
                ['2013-09-19', 43.15],
                ['2013-09-20', 42.34],
                ['2013-09-23', 42.30],
                ['2013-09-24', 42.07],
                ['2013-09-25', 42.18],
                ['2013-09-26', 42.11],
                ['2013-09-27', 42.29],
                ['2013-09-30', 42.33],
                ['2013-10-01', 41.97],
                ['2013-10-02', 42.27],
                ['2013-10-03', 42.11],
                ['2013-10-04', 42.13],
                ['2013-10-07', 42.05],
                ['2013-10-08', 41.71],
                ['2013-10-09', 41.54],
                ['2013-10-10', 41.91],
                ['2013-10-11', 41.88],
                ['2013-10-14', 41.98],
                ['2013-10-15', 41.85],
                ['2013-10-16', 42.38],
                ['2013-10-17', 43.46],
                ['2013-10-18', 43.51],
                ['2013-10-21', 43.41],
                ['2013-10-22', 44.24],
                ['2013-10-23', 43.92],
                ['2013-10-24', 44.22],
                ['2013-10-25', 44.41],
                ['2013-10-28', 44.51],
                ['2013-10-29', 44.70],
                ['2013-10-30', 44.37],
                ['2013-10-31', 44.10],
                ['2013-11-01', 43.91],
                ['2013-11-04', 44.05],
                ['2013-11-05', 43.72],
                ['2013-11-06', 44.04],
                ['2013-11-07', 43.61],
                ['2013-11-08', 43.36],
                ['2013-11-11', 43.36],
                ['2013-11-12', 43.12],
                ['2013-11-13', 43.62],
                ['2013-11-14', 44.10],
                ['2013-11-15', 44.21],
                ['2013-11-18', 43.86],
                ['2013-11-19', 43.62],
                ['2013-11-20', 42.97],
                ['2013-11-21', 43.17],
                ['2013-11-22', 43.36],
                ['2013-11-25', 43.42],
                ['2013-11-26', 43.42],
                ['2013-11-27', 43.37],
                ['2013-11-29', 43.57],
                ['2013-12-02', 42.90],
                ['2013-12-03', 42.86],
                ['2013-12-04', 42.92],
                ['2013-12-05', 42.49],
                ['2013-12-06', 43.04],
                ['2013-12-09', 43.22],
                ['2013-12-10', 43.39],
                ['2013-12-11', 42.79],
                ['2013-12-12', 42.13],
                ['2013-12-13', 42.32],
                ['2013-12-16', 42.62],
                ['2013-12-17', 42.46],
                ['2013-12-18', 42.75],
                ['2013-12-19', 42.13],
                ['2013-12-20', 42.52],
                ['2013-12-23', 42.40],
                ['2013-12-24', 42.46],
                ['2013-12-26', 42.68],
                ['2013-12-27', 42.54],
                ['2013-12-30', 42.59],
                ['2013-12-31', 42.80],
                ['2014-01-02', 42.97],
                ['2014-01-03', 43.22],
                ['2014-01-06', 43.26],
                ['2014-01-07', 43.47],
                ['2014-01-08', 43.28],
                ['2014-01-09', 43.37],
                ['2014-01-10', 44.09],
                ['2014-01-13', 43.82],
                ['2014-01-14', 43.94],
                ['2014-01-15', 44.05],
                ['2014-01-16', 44.06],
                ['2014-01-17', 44.26],
                ['2014-01-21', 44.12],
                ['2014-01-22', 43.95],
                ['2014-01-23', 44.13],
                ['2014-01-24', 43.68],
                ['2014-01-27', 43.28],
                ['2014-01-28', 43.55],
                ['2014-01-29', 43.53],
                ['2014-01-30', 43.50],
                ['2014-01-31', 43.43],
                ['2014-02-03', 42.87],
                ['2014-02-04', 43.06],
                ['2014-02-05', 43.04],
                ['2014-02-06', 43.40],
                ['2014-02-07', 44.03],
                ['2014-02-10', 44.32],
                ['2014-02-11', 44.87],
                ['2014-02-12', 44.91],
                ['2014-02-13', 45.44],
                ['2014-02-14', 45.89],
                ['2014-02-18', 46.09],
                ['2014-02-19', 45.65],
                ['2014-02-20', 46.15],
                ['2014-02-21', 46.09],
                ['2014-02-24', 46.36],
                ['2014-02-25', 46.38],
                ['2014-02-26', 46.32],
                ['2014-02-27', 46.51],
                ['2014-02-28', 46.60],
                ['2014-03-03', 46.67],
                ['2014-03-04', 46.95],
                ['2014-03-05', 46.83],
                ['2014-03-06', 46.93],
                ['2014-03-07', 46.75],
                ['2014-03-10', 46.72],
                ['2014-03-11', 46.67],
                ['2014-03-12', 47.01],
                ['2014-03-13', 46.78],
                ['2014-03-14', 46.94],
                ['2014-03-17', 46.99],
                ['2014-03-18', 47.14],
                ['2014-03-19', 46.40],
                ['2014-03-20', 46.50],
                ['2014-03-21', 46.39],
                ['2014-03-24', 45.81],
                ['2014-03-25', 45.88],
                ['2014-03-26', 45.63],
                ['2014-03-27', 45.53],
                ['2014-03-28', 45.54],
                ['2014-03-31', 46.16],
                ['2014-04-01', 46.25],
                ['2014-04-02', 46.40],
                ['2014-04-03', 46.36],
                ['2014-04-04', 46.23],
                ['2014-04-07', 45.82],
                ['2014-04-08', 46.29],
                ['2014-04-09', 46.66],
                ['2014-04-10', 46.05],
                ['2014-04-11', 45.66],
                ['2014-04-14', 46.14],
                ['2014-04-15', 45.99],
                ['2014-04-16', 46.26],
                ['2014-04-17', 46.09],
                ['2014-04-21', 46.05],
                ['2014-04-22', 46.25],
                ['2014-04-23', 46.08],
                ['2014-04-24', 46.28],
                ['2014-04-25', 46.09],
                ['2014-04-28', 46.12],
                ['2014-04-29', 46.26],
                ['2014-04-30', 46.48],
                ['2014-05-01', 46.50],
                ['2014-05-02', 46.59],
                ['2014-05-05', 46.95],
                ['2014-05-06', 46.69],
                ['2014-05-07', 46.63],
                ['2014-05-08', 46.60],
                ['2014-05-09', 46.63],
                ['2014-05-12', 47.06],
                ['2014-05-13', 47.06],
                ['2014-05-14', 47.15],
                ['2014-05-15', 46.74],
                ['2014-05-16', 46.96],
                ['2014-05-19', 46.94],
                ['2014-05-20', 46.82],
                ['2014-05-21', 47.01],
                ['2014-05-22', 47.14],
                ['2014-05-23', 47.27],
                ['2014-05-27', 47.01],
                ['2014-05-28', 46.89],
                ['2014-05-29', 47.00],
                ['2014-05-30', 47.17],
                ['2014-06-02', 46.99],
                ['2014-06-03', 46.89],
                ['2014-06-04', 47.03],
                ['2014-06-05', 47.28],
                ['2014-06-06', 47.48],
                ['2014-06-09', 47.50],
                ['2014-06-10', 47.50],
                ['2014-06-11', 47.38],
                ['2014-06-12', 47.50],
                ['2014-06-13', 47.61],
                ['2014-06-16', 47.65],
                ['2014-06-17', 47.66],
                ['2014-06-18', 48.08],
                ['2014-06-19', 48.81],
                ['2014-06-20', 48.85],
                ['2014-06-23', 48.80],
                ['2014-06-24', 48.68],
                ['2014-06-25', 48.83],
                ['2014-06-26', 48.82],
                ['2014-06-27', 48.95],
                ['2014-06-30', 49.24],
                ['2014-07-01', 49.56],
                ['2014-07-02', 49.39],
                ['2014-07-03', 49.45],
                ['2014-07-07', 49.26],
                ['2014-07-08', 49.25],
                ['2014-07-09', 49.57],
                ['2014-07-10', 49.56],
                ['2014-07-11', 49.72],
                ['2014-07-14', 49.39],
                ['2014-07-15', 49.06],
                ['2014-07-16', 49.29],
                ['2014-07-17', 49.15],
                ['2014-07-18', 49.48],
                ['2014-07-21', 49.40],
                ['2014-07-22', 49.46],
                ['2014-07-23', 49.44],
                ['2014-07-24', 49.08],
                ['2014-07-25', 49.10],
                ['2014-07-28', 49.06],
                ['2014-07-29', 48.89],
                ['2014-07-30', 48.73],
                ['2014-07-31', 47.80],
                ['2014-08-01', 48.11],
                ['2014-08-04', 48.22],
                ['2014-08-05', 47.81],
                ['2014-08-06', 47.92],
                ['2014-08-07', 47.79],
                ['2014-08-08', 48.27],
                ['2014-08-11', 48.40],
                ['2014-08-12', 48.31],
                ['2014-08-13', 48.74],
                ['2014-08-14', 48.98],
                ['2014-08-15', 48.97],
                ['2014-08-18', 49.14],
                ['2014-08-19', 49.30],
                ['2014-08-20', 49.25],
                ['2014-08-21', 49.18],
                ['2014-08-22', 49.10],
                ['2014-08-25', 49.09],
                ['2014-08-26', 49.20],
                ['2014-08-27', 49.23],
                ['2014-08-28', 49.41],
                ['2014-08-29', 49.63],
                ['2014-09-02', 49.11],
                ['2014-09-03', 49.28],
                ['2014-09-04', 49.03],
                ['2014-09-05', 49.35],
                ['2014-09-08', 49.11],
                ['2014-09-09', 48.87],
                ['2014-09-10', 48.72],
                ['2014-09-11', 48.66],
                ['2014-09-12', 48.05],
                ['2014-09-15', 48.06],
                ['2014-09-16', 48.46],
                ['2014-09-17', 48.13],
                ['2014-09-18', 48.34],
                ['2014-09-19', 48.24],
                ['2014-09-22', 47.94],
                ['2014-09-23', 47.86],
                ['2014-09-24', 47.97],
                ['2014-09-25', 47.53],
                ['2014-09-26', 47.76],
                ['2014-09-29', 47.75],
                ['2014-09-30', 47.71],
                ['2014-10-01', 47.61],
                ['2014-10-02', 47.65],
                ['2014-10-03', 47.79],
                ['2014-10-06', 47.95],
                ['2014-10-07', 47.70],
                ['2014-10-08', 48.69],
                ['2014-10-09', 47.89],
                ['2014-10-10', 47.54],
                ['2014-10-13', 47.22],
                ['2014-10-14', 47.49],
                ['2014-10-15', 47.12],
                ['2014-10-16', 47.11],
                ['2014-10-17', 47.46],
                ['2014-10-20', 48.05],
                ['2014-10-21', 48.80],
                ['2014-10-22', 48.41],
                ['2014-10-23', 48.64],
                ['2014-10-24', 48.94],
                ['2014-10-27', 48.95],
                ['2014-10-28', 49.28],
                ['2014-10-29', 48.85],
                ['2014-10-30', 49.02],
                ['2014-10-31', 49.03],
                ['2014-11-03', 49.01],
                ['2014-11-04', 48.95],
                ['2014-11-05', 48.81],
                ['2014-11-06', 48.72],
                ['2014-11-07', 49.37],
                ['2014-11-10', 49.07],
                ['2014-11-11', 49.29],
                ['2014-11-12', 49.20],
                ['2014-11-13', 49.34],
                ['2014-11-14', 49.85],
                ['2014-11-17', 49.84],
                ['2014-11-18', 50.50],
                ['2014-11-19', 50.11],
                ['2014-11-20', 50.39],
                ['2014-11-21', 50.61],
                ['2014-11-24', 50.74],
                ['2014-11-25', 50.86],
                ['2014-11-26', 51.13],
                ['2014-11-28', 50.96],
                ['2014-12-01', 51.39],
                ['2014-12-02', 51.23],
                ['2014-12-03', 51.61],
                ['2014-12-04', 51.52],
                ['2014-12-05', 51.25],
                ['2014-12-08', 51.31],
                ['2014-12-09', 51.78],
                ['2014-12-10', 51.12],
                ['2014-12-11', 51.27],
                ['2014-12-12', 50.76],
                ['2014-12-15', 49.92],
                ['2014-12-16', 49.69],
                ['2014-12-17', 50.23],
                ['2014-12-18', 51.15],
                ['2014-12-19', 51.47],
                ['2014-12-22', 51.15],
                ['2014-12-23', 50.95],
                ['2014-12-24', 50.76],
                ['2014-12-26', 51.28],
                ['2014-12-29', 51.14],
                ['2014-12-30', 51.31],
                ['2014-12-31', 50.99],
                ['2015-01-02', 51.19],
                ['2015-01-05', 51.12],
                ['2015-01-06', 51.20],
                ['2015-01-07', 51.68],
                ['2015-01-08', 52.24],
                ['2015-01-09', 52.37],
                ['2015-01-12', 52.42],
                ['2015-01-13', 52.24],
                ['2015-01-14', 52.14],
                ['2015-01-15', 52.73],
                ['2015-01-16', 53.25],
                ['2015-01-20', 53.67],
                ['2015-01-21', 53.68],
                ['2015-01-22', 54.43],
                ['2015-01-23', 54.28],
                ['2015-01-26', 54.10],
                ['2015-01-27', 54.01],
                ['2015-01-28', 53.55],
                ['2015-01-29', 53.21],
                ['2015-01-30', 53.24],
                ['2015-02-02', 53.35],
                ['2015-02-03', 53.44],
                ['2015-02-04', 53.50],
                ['2015-02-05', 53.93],
                ['2015-02-06', 52.90],
                ['2015-02-09', 52.70],
                ['2015-02-10', 53.05],
                ['2015-02-11', 52.78],
                ['2015-02-12', 53.16],
                ['2015-02-13', 53.34],
                ['2015-02-17', 52.97],
                ['2015-02-18', 53.25],
                ['2015-02-19', 53.04],
                ['2015-02-20', 53.16],
                ['2015-02-23', 53.22],
                ['2015-02-24', 53.43],
                ['2015-02-25', 53.54],
                ['2015-02-26', 53.49],
                ['2015-02-27', 53.57],
                ['2015-03-02', 53.61],
                ['2015-03-03', 53.29],
                ['2015-03-04', 53.12],
                ['2015-03-05', 53.20],
                ['2015-03-06', 51.78],
                ['2015-03-09', 52.08],
                ['2015-03-10', 51.55],
                ['2015-03-11', 51.51],
                ['2015-03-12', 52.00],
                ['2015-03-13', 51.80],
                ['2015-03-16', 52.45],
                ['2015-03-17', 52.17],
                ['2015-03-18', 53.18],
                ['2015-03-19', 53.06],
                ['2015-03-20', 53.72],
                ['2015-03-23', 53.92],
                ['2015-03-24', 53.81],
                ['2015-03-25', 52.93],
                ['2015-03-26', 52.77],
                ['2015-03-27', 53.10],
                ['2015-03-30', 53.28],
                ['2015-03-31', 53.29],
                ['2015-04-01', 53.61],
                ['2015-04-02', 53.57],
                ['2015-04-06', 54.06],
                ['2015-04-07', 53.83],
                ['2015-04-08', 53.89],
                ['2015-04-09', 53.85],
                ['2015-04-10', 54.21],
                ['2015-04-13', 53.89],
                ['2015-04-14', 53.81],
                ['2015-04-15', 54.12],
                ['2015-04-16', 54.00],
                ['2015-04-17', 53.73],
                ['2015-04-20', 53.92],
                ['2015-04-21', 54.03],
                ['2015-04-22', 53.79],
                ['2015-04-23', 54.06],
                ['2015-04-24', 53.74],
                ['2015-04-27', 53.81],
                ['2015-04-28', 54.03],
                ['2015-04-29', 53.49],
                ['2015-04-30', 52.88],
                ['2015-05-01', 53.08],
                ['2015-05-04', 53.27],
                ['2015-05-05', 52.81],
                ['2015-05-06', 52.59],
                ['2015-05-07', 52.74],
                ['2015-05-08', 53.38],
                ['2015-05-11', 52.99],
                ['2015-05-12', 53.04],
                ['2015-05-13', 53.36],
                ['2015-05-14', 54.01],
                ['2015-05-15', 54.28],
                ['2015-05-18', 54.29],
                ['2015-05-19', 53.93],
                ['2015-05-20', 54.03],
                ['2015-05-21', 54.09],
                ['2015-05-22', 53.95],
                ['2015-05-26', 53.45],
                ['2015-05-27', 53.84],
                ['2015-05-28', 53.87],
                ['2015-05-29', 53.77],
                ['2015-06-01', 53.72],
                ['2015-06-02', 53.49],
                ['2015-06-03', 53.14],
                ['2015-06-04', 52.77],
                ['2015-06-05', 52.39],
                ['2015-06-08', 52.17],
                ['2015-06-09', 52.11],
                ['2015-06-10', 52.57],
                ['2015-06-11', 52.79],
                ['2015-06-12', 52.53],
                ['2015-06-15', 52.47],
                ['2015-06-16', 52.66],
                ['2015-06-17', 52.85],
                ['2015-06-18', 53.56],
                ['2015-06-19', 53.49],
                ['2015-06-22', 53.17],
                ['2015-06-23', 52.95],
                ['2015-06-24', 52.48],
                ['2015-06-25', 52.35],
                ['2015-06-26', 52.18],
                ['2015-06-29', 51.71],
                ['2015-06-30', 51.89],
                ['2015-07-01', 52.09],
                ['2015-07-02', 52.18],
                ['2015-07-06', 52.38],
                ['2015-07-07', 52.39],
                ['2015-07-08', 51.99],
                ['2015-07-09', 51.76],
                ['2015-07-10', 52.14],
                ['2015-07-13', 52.28],
                ['2015-07-14', 52.45],
                ['2015-07-15', 52.30],
                ['2015-07-16', 52.45],
                ['2015-07-17', 52.15],
                ['2015-07-20', 51.57],
                ['2015-07-21', 51.46],
                ['2015-07-22', 51.30],
                ['2015-07-23', 51.21],
                ['2015-07-24', 51.13],
                ['2015-07-27', 50.80],
                ['2015-07-28', 51.25],
                ['2015-07-29', 51.47],
                ['2015-07-30', 51.43],
                ['2015-07-31', 51.93],
                ['2015-08-03', 51.77],
                ['2015-08-04', 51.57],
                ['2015-08-05', 51.75],
                ['2015-08-06', 51.37],
                ['2015-08-07', 51.48],
                ['2015-08-10', 52.19],
                ['2015-08-11', 52.22],
                ['2015-08-12', 52.50],
                ['2015-08-13', 52.28],
                ['2015-08-14', 52.39],
                ['2015-08-17', 52.71],
                ['2015-08-18', 52.54],
                ['2015-08-19', 52.70],
                ['2015-08-20', 52.37],
                ['2015-08-21', 51.57],
                ['2015-08-24', 50.06],
                ['2015-08-25', 49.06],
                ['2015-08-26', 49.96],
                ['2015-08-27', 50.81],
                ['2015-08-28', 50.99],
                ['2015-08-31', 50.71],
                ['2015-09-01', 49.76],
                ['2015-09-02', 50.11],
                ['2015-09-03', 50.21],
                ['2015-09-04', 49.66],
                ['2015-09-08', 50.59],
                ['2015-09-09', 49.75],
                ['2015-09-10', 49.87],
                ['2015-09-11', 50.12],
                ['2015-09-14', 50.00],
                ['2015-09-15', 50.24],
                ['2015-09-16', 50.75],
                ['2015-09-17', 51.15],
                ['2015-09-18', 50.98],
                ['2015-09-21', 50.90],
                ['2015-09-22', 50.33],
                ['2015-09-23', 50.37],
                ['2015-09-24', 50.74],
                ['2015-09-25', 50.19],
                ['2015-09-28', 48.99],
                ['2015-09-29', 49.16],
                ['2015-09-30', 49.65],
                ['2015-10-01', 49.75],
                ['2015-10-02', 50.91],
                ['2015-10-05', 51.23],
                ['2015-10-06', 51.10],
                ['2015-10-07', 51.43],
                ['2015-10-08', 51.55],
                ['2015-10-09', 51.98],
                ['2015-10-12', 52.18],
                ['2015-10-13', 52.09],
                ['2015-10-14', 52.44],
                ['2015-10-15', 52.83],
                ['2015-10-16', 52.92],
                ['2015-10-19', 52.93],
                ['2015-10-20', 52.87],
                ['2015-10-21', 52.61],
                ['2015-10-22', 53.07],
                ['2015-10-23', 53.07],
                ['2015-10-26', 52.94],
                ['2015-10-27', 53.01],
                ['2015-10-28', 53.09],
                ['2015-10-29', 52.70],
                ['2015-10-30', 52.60],
                ['2015-11-02', 52.79],
                ['2015-11-03', 52.51],
                ['2015-11-04', 52.21],
                ['2015-11-05', 51.98],
                ['2015-11-06', 51.56],
                ['2015-11-09', 51.24],
                ['2015-11-10', 51.37],
                ['2015-11-11', 51.22],
                ['2015-11-12', 50.75],
                ['2015-11-13', 50.45],
                ['2015-11-16', 51.05],
                ['2015-11-17', 50.76],
                ['2015-11-18', 51.24],
                ['2015-11-19', 51.37],
                ['2015-11-20', 51.44],
                ['2015-11-23', 51.25],
                ['2015-11-24', 51.46],
                ['2015-11-25', 51.40],
                ['2015-11-27', 51.34],
                ['2015-11-30', 51.39],
                ['2015-12-01', 52.03],
                ['2015-12-02', 51.36],
                ['2015-12-03', 50.87],
                ['2015-12-04', 52.12],
                ['2015-12-07', 51.81],
                ['2015-12-08', 51.74],
                ['2015-12-09', 51.35],
                ['2015-12-10', 51.31],
                ['2015-12-11', 51.06],
                ['2015-12-14', 50.84],
                ['2015-12-15', 51.10],
                ['2015-12-16', 51.83],
                ['2015-12-17', 51.06],
                ['2015-12-18', 50.83],
                ['2015-12-21', 51.37],
                ['2015-12-22', 51.55],
                ['2015-12-23', 51.86],
                ['2015-12-24', 51.74],
                ['2015-12-28', 51.58],
                ['2015-12-29', 51.77],
                ['2015-12-30', 51.46],
                ['2015-12-31', 51.44],
                ['2016-01-04', 51.32],
                ['2016-01-05', 51.64],
                ['2016-01-06', 51.63],
                ['2016-01-07', 51.19],
                ['2016-01-08', 50.72],
                ['2016-01-11', 50.47],
                ['2016-01-12', 50.71],
                ['2016-01-13', 50.12],
                ['2016-01-14', 50.19],
                ['2016-01-15', 49.74],
                ['2016-01-19', 49.88],
                ['2016-01-20', 49.73],
                ['2016-01-21', 49.85],
                ['2016-01-22', 50.52],
                ['2016-01-25', 50.49],
                ['2016-01-26', 51.21],
                ['2016-01-27', 51.04],
                ['2016-01-28', 50.81],
                ['2016-01-29', 52.08],
                ['2016-02-01', 52.40],
                ['2016-02-02', 52.07],
                ['2016-02-03', 52.37],
                ['2016-02-04', 52.62],
                ['2016-02-05', 52.39],
                ['2016-02-08', 52.22],
                ['2016-02-09', 52.16],
                ['2016-02-10', 52.50],
                ['2016-02-11', 52.96],
                ['2016-02-12', 53.24],
                ['2016-02-16', 53.37],
                ['2016-02-17', 53.92],
                ['2016-02-18', 54.52],
                ['2016-02-19', 54.48],
                ['2016-02-22', 54.52],
                ['2016-02-23', 54.58],
                ['2016-02-24', 54.87],
                ['2016-02-25', 55.55],
                ['2016-02-26', 55.15],
                ['2016-02-29', 55.32],
                ['2016-03-01', 55.73],
                ['2016-03-02', 56.02],
                ['2016-03-03', 56.55],
                ['2016-03-04', 56.58],
                ['2016-03-07', 56.65],
                ['2016-03-08', 56.35],
                ['2016-03-09', 56.23],
                ['2016-03-10', 56.50],
                ['2016-03-11', 56.73],
                ['2016-03-14', 56.48],
                ['2016-03-15', 56.36],
                ['2016-03-16', 57.09],
                ['2016-03-17', 57.24],
                ['2016-03-18', 57.39],
                ['2016-03-21', 57.25],
                ['2016-03-22', 57.24],
                ['2016-03-23', 56.59],
                ['2016-03-24', 56.45],
                ['2016-03-28', 56.58],
                ['2016-03-29', 57.69],
                ['2016-03-30', 57.69],
                ['2016-03-31', 57.99],
                ['2016-04-01', 58.16],
                ['2016-04-04', 58.03],
                ['2016-04-05', 57.82],
                ['2016-04-06', 58.01],
                ['2016-04-07', 58.18],
                ['2016-04-08', 58.16],
                ['2016-04-11', 58.27],
                ['2016-04-12', 58.40],
                ['2016-04-13', 58.38],
                ['2016-04-14', 57.89],
                ['2016-04-15', 58.13],
                ['2016-04-18', 58.31],
                ['2016-04-19', 58.66],
                ['2016-04-20', 58.34],
                ['2016-04-21', 58.00],
                ['2016-04-22', 57.93],
                ['2016-04-25', 57.97],
                ['2016-04-26', 58.05],
                ['2016-04-27', 58.41],
                ['2016-04-28', 58.53],
                ['2016-04-29', 58.70],
                ['2016-05-02', 58.93],
                ['2016-05-03', 58.74],
                ['2016-05-04', 58.57],
                ['2016-05-05', 58.63],
                ['2016-05-06', 58.89],
                ['2016-05-09', 58.69],
                ['2016-05-10', 59.16],
                ['2016-05-11', 59.11],
                ['2016-05-12', 58.82],
                ['2016-05-13', 58.77],
                ['2016-05-16', 59.17],
                ['2016-05-17', 58.69],
                ['2016-05-18', 58.11],
                ['2016-05-19', 58.00],
                ['2016-05-20', 58.28],
                ['2016-05-23', 58.20],
                ['2016-05-24', 58.35],
                ['2016-05-25', 58.41],
                ['2016-05-26', 58.49],
                ['2016-05-27', 58.38],
                ['2016-05-31', 58.48],
                ['2016-06-01', 58.55],
                ['2016-06-02', 58.78],
                ['2016-06-03', 59.59],
                ['2016-06-06', 59.49],
                ['2016-06-07', 59.63],
                ['2016-06-08', 60.19],
                ['2016-06-09', 60.49],
                ['2016-06-10', 60.35],
                ['2016-06-13', 60.26],
                ['2016-06-14', 60.26],
                ['2016-06-15', 60.32],
                ['2016-06-16', 60.33],
                ['2016-06-17', 60.29],
                ['2016-06-20', 60.37],
                ['2016-06-21', 59.92],
                ['2016-06-22', 59.93],
                ['2016-06-23', 60.24],
                ['2016-06-24', 60.33],
                ['2016-06-27', 60.24],
                ['2016-06-28', 60.64],
                ['2016-06-29', 61.32],
                ['2016-06-30', 62.18],
                ['2016-07-01', 62.52],
                ['2016-07-05', 62.78],
                ['2016-07-06', 63.01],
                ['2016-07-07', 62.84],
                ['2016-07-08', 63.58],
                ['2016-07-11', 63.43],
                ['2016-07-12', 62.96],
                ['2016-07-13', 63.33],
                ['2016-07-14', 63.25],
                ['2016-07-15', 63.06],
                ['2016-07-18', 63.11],
                ['2016-07-19', 63.17],
                ['2016-07-20', 63.01],
                ['2016-07-21', 63.24],
                ['2016-07-22', 63.27],
                ['2016-07-25', 63.06],
                ['2016-07-26', 63.37],
                ['2016-07-27', 63.50],
                ['2016-07-28', 63.55],
                ['2016-07-29', 63.98],
                ['2016-08-01', 64.00],
                ['2016-08-02', 63.82],
                ['2016-08-03', 63.47],
                ['2016-08-04', 63.70],
                ['2016-08-05', 63.36],
                ['2016-08-08', 63.27],
                ['2016-08-09', 63.59],
                ['2016-08-10', 63.78],
                ['2016-08-11', 63.63],
                ['2016-08-12', 63.60],
                ['2016-08-15', 63.57],
                ['2016-08-16', 63.30],
                ['2016-08-17', 63.39],
                ['2016-08-18', 63.72],
                ['2016-08-19', 63.42],
                ['2016-08-22', 63.71],
                ['2016-08-23', 63.72],
                ['2016-08-24', 63.14],
                ['2016-08-25', 63.05],
                ['2016-08-26', 62.83],
                ['2016-08-29', 63.30],
                ['2016-08-30', 62.88],
                ['2016-08-31', 62.81],
                ['2016-09-01', 62.97],
                ['2016-09-02', 63.44],
                ['2016-09-06', 63.98],
                ['2016-09-07', 63.90],
                ['2016-09-08', 63.56],
                ['2016-09-09', 62.11],
                ['2016-09-12', 62.74],
                ['2016-09-13', 61.84],
                ['2016-09-14', 61.97],
                ['2016-09-15', 62.32],
                ['2016-09-16', 62.24],
                ['2016-09-19', 62.30],
                ['2016-09-20', 62.38],
                ['2016-09-21', 63.27],
                ['2016-09-22', 63.70],
                ['2016-09-23', 63.49],
                ['2016-09-26', 63.06],
                ['2016-09-27', 63.13],
                ['2016-09-28', 63.07],
                ['2016-09-29', 62.72],
                ['2016-09-30', 63.32],
                ['2016-10-03', 62.89],
                ['2016-10-04', 61.68],
                ['2016-10-05', 61.59],
                ['2016-10-06', 61.42],
                ['2016-10-07', 61.34],
                ['2016-10-10', 61.54],
                ['2016-10-11', 60.77],
                ['2016-10-12', 60.88],
                ['2016-10-13', 60.93],
                ['2016-10-14', 60.70],
                ['2016-10-17', 60.72],
                ['2016-10-18', 61.14],
                ['2016-10-19', 61.22],
                ['2016-10-20', 61.13],
                ['2016-10-21', 61.06],
                ['2016-10-24', 61.19],
                ['2016-10-25', 61.20],
                ['2016-10-26', 60.74],
                ['2016-10-27', 60.36],
                ['2016-10-28', 60.47],
                ['2016-10-31', 60.90],
                ['2016-11-01', 60.57],
                ['2016-11-02', 60.64],
                ['2016-11-03', 60.63],
                ['2016-11-04', 60.66],
                ['2016-11-07', 61.02],
                ['2016-11-08', 61.12],
                ['2016-11-09', 60.53],
                ['2016-11-10', 59.72],
                ['2016-11-11', 59.30],
                ['2016-11-14', 58.76],
                ['2016-11-15', 59.38],
                ['2016-11-16', 59.49],
                ['2016-11-17', 59.57],
                ['2016-11-18', 59.25],
                ['2016-11-21', 59.52],
                ['2016-11-22', 59.58],
                ['2016-11-23', 59.13],
                ['2016-11-25', 59.16],
                ['2016-11-28', 59.44],
                ['2016-11-29', 59.47],
                ['2016-11-30', 58.63],
                ['2016-12-01', 57.90],
                ['2016-12-02', 58.25],
                ['2016-12-05', 58.34],
                ['2016-12-06', 58.51],
                ['2016-12-07', 59.10],
                ['2016-12-08', 59.10],
                ['2016-12-09', 58.97],
                ['2016-12-12', 59.12],
                ['2016-12-13', 59.30],
                ['2016-12-14', 58.50],
                ['2016-12-15', 58.34],
                ['2016-12-16', 58.55],
                ['2016-12-19', 58.93],
                ['2016-12-20', 58.78],
                ['2016-12-21', 58.65],
                ['2016-12-22', 58.42],
                ['2016-12-23', 58.57],
                ['2016-12-27', 58.78],
                ['2016-12-28', 58.60],
                ['2016-12-29', 59.33],
                ['2016-12-30', 59.20],
                ['2017-01-03', 59.52],
                ['2017-01-04', 59.82],
                ['2017-01-05', 60.25],
                ['2017-01-06', 60.08],
                ['2017-01-09', 60.35],
                ['2017-01-10', 60.47],
                ['2017-01-11', 60.75],
                ['2017-01-12', 60.64],
                ['2017-01-13', 60.74],
                ['2017-01-17', 61.25],
                ['2017-01-18', 60.99],
                ['2017-01-19', 60.63],
                ['2017-01-20', 60.86],
                ['2017-01-23', 60.98],
                ['2017-01-24', 61.04],
                ['2017-01-25', 61.01],
                ['2017-01-26', 60.72],
                ['2017-01-27', 60.93],
                ['2017-01-30', 60.80],
                ['2017-01-31', 61.32],
                ['2017-02-01', 61.07],
                ['2017-02-02', 61.39],
                ['2017-02-03', 61.75],
                ['2017-02-06', 62.03],
                ['2017-02-07', 62.17],
                ['2017-02-08', 62.47],
                ['2017-02-09', 62.28],
                ['2017-02-10', 62.40],
                ['2017-02-13', 62.35],
                ['2017-02-14', 62.38],
                ['2017-02-15', 62.63],
                ['2017-02-16', 62.96],
                ['2017-02-17', 62.99],
                ['2017-02-21', 63.36],
                ['2017-02-22', 63.44],
                ['2017-02-23', 63.83],
                ['2017-02-24', 64.24],
                ['2017-02-27', 64.06],
                ['2017-02-28', 64.03],
                ['2017-03-01', 64.21],
                ['2017-03-02', 63.71],
                ['2017-03-03', 63.66],
                ['2017-03-06', 63.38],
                ['2017-03-07', 63.16],
                ['2017-03-08', 62.83],
                ['2017-03-09', 62.70],
                ['2017-03-10', 62.98],
                ['2017-03-13', 62.99],
                ['2017-03-14', 62.76],
                ['2017-03-15', 63.74],
                ['2017-03-16', 63.57],
                ['2017-03-17', 63.84],
                ['2017-03-20', 63.95],
                ['2017-03-21', 63.89],
                ['2017-03-22', 64.09],
                ['2017-03-23', 63.99],
                ['2017-03-24', 63.96],
                ['2017-03-27', 64.09],
                ['2017-03-28', 64.03],
                ['2017-03-29', 64.15],
                ['2017-03-30', 64.14],
                ['2017-03-31', 64.33],
                ['2017-04-03', 64.49],
                ['2017-04-04', 64.48],
                ['2017-04-05', 64.43],
                ['2017-04-06', 64.35],
                ['2017-04-07', 64.38],
                ['2017-04-10', 64.43],
                ['2017-04-11', 64.85],
                ['2017-04-12', 64.95],
                ['2017-04-13', 64.92],
                ['2017-04-17', 65.11],
                ['2017-04-18', 65.36],
                ['2017-04-19', 65.17],
                ['2017-04-20', 65.33],
                ['2017-04-21', 65.34],
                ['2017-04-24', 65.47],
                ['2017-04-25', 65.31],
                ['2017-04-26', 65.47],
                ['2017-04-27', 65.51],
                ['2017-04-28', 65.58],
                ['2017-05-01', 65.34],
                ['2017-05-02', 65.31],
                ['2017-05-03', 64.84],
                ['2017-05-04', 64.67],
                ['2017-05-05', 64.85],
                ['2017-05-08', 64.60],
                ['2017-05-09', 64.47],
                ['2017-05-10', 64.61],
                ['2017-05-11', 64.65],
                ['2017-05-12', 64.78],
                ['2017-05-15', 65.03],
                ['2017-05-16', 65.06],
                ['2017-05-17', 65.27],
                ['2017-05-18', 65.27],
                ['2017-05-19', 65.53],
                ['2017-05-22', 65.86],
                ['2017-05-23', 65.66],
                ['2017-05-24', 65.95],
                ['2017-05-25', 66.23],
                ['2017-05-26', 66.40],
                ['2017-05-30', 66.48],
                ['2017-05-31', 66.74],
                ['2017-06-01', 66.99],
                ['2017-06-02', 67.38],
                ['2017-06-05', 67.33],
                ['2017-06-06', 67.50],
                ['2017-06-07', 67.48],
                ['2017-06-08', 67.25],
                ['2017-06-09', 66.76],
                ['2017-06-12', 66.66],
                ['2017-06-13', 66.91],
                ['2017-06-14', 66.96],
                ['2017-06-15', 66.77],
                ['2017-06-16', 66.79],
                ['2017-06-19', 66.87],
                ['2017-06-20', 66.69],
                ['2017-06-21', 66.75],
                ['2017-06-22', 66.79],
                ['2017-06-23', 66.96],
                ['2017-06-26', 66.77],
                ['2017-06-27', 66.17],
                ['2017-06-28', 66.38],
                ['2017-06-29', 65.77],
                ['2017-06-30', 66.15],
                ['2017-07-03', 65.58],
                ['2017-07-05', 65.85],
                ['2017-07-06', 65.46],
                ['2017-07-07', 65.53],
                ['2017-07-10', 65.57],
                ['2017-07-11', 65.62],
                ['2017-07-12', 66.01],
                ['2017-07-13', 65.85],
                ['2017-07-14', 66.38],
                ['2017-07-17', 66.47],
                ['2017-07-18', 66.67],
                ['2017-07-19', 66.84],
                ['2017-07-20', 66.98],
                ['2017-07-21', 67.16],
                ['2017-07-24', 67.14],
                ['2017-07-25', 67.01],
                ['2017-07-26', 67.26],
                ['2017-07-27', 67.15],
                ['2017-07-28', 67.30],
                ['2017-07-31', 67.38],
                ['2017-08-01', 67.46],
                ['2017-08-02', 67.23],
                ['2017-08-03', 67.27],
                ['2017-08-04', 67.01],
                ['2017-08-07', 67.13],
                ['2017-08-08', 67.00],
                ['2017-08-09', 67.21],
                ['2017-08-10', 67.11],
                ['2017-08-11', 67.37],
                ['2017-08-14', 67.59],
                ['2017-08-15', 67.35],
                ['2017-08-16', 67.65],
                ['2017-08-17', 67.36],
                ['2017-08-18', 67.29],
                ['2017-08-21', 67.50],
                ['2017-08-22', 67.65],
                ['2017-08-23', 67.65],
                ['2017-08-24', 67.50],
                ['2017-08-25', 67.69],
                ['2017-08-28', 68.07],
                ['2017-08-29', 68.11],
                ['2017-08-30', 68.22],
                ['2017-08-31', 68.81],
                ['2017-09-01', 68.81],
                ['2017-09-05', 69.04],
                ['2017-09-06', 68.95],
                ['2017-09-07', 69.37],
                ['2017-09-08', 69.46],
                ['2017-09-11', 69.38],
                ['2017-09-12', 69.45],
                ['2017-09-13', 69.08],
                ['2017-09-14', 69.20],
                ['2017-09-15', 69.22],
                ['2017-09-18', 69.07],
                ['2017-09-19', 68.95],
                ['2017-09-20', 68.65],
                ['2017-09-21', 68.20],
                ['2017-09-22', 68.38],
                ['2017-09-25', 68.59],
                ['2017-09-26', 68.13],
                ['2017-09-27', 67.90],
                ['2017-09-28', 67.96],
                ['2017-09-29', 68.28],
                ['2017-10-02', 68.30],
                ['2017-10-03', 68.37],
                ['2017-10-04', 68.57],
                ['2017-10-05', 68.52],
                ['2017-10-06', 68.54],
                ['2017-10-09', 68.56],
                ['2017-10-10', 68.80],
                ['2017-10-11', 68.97],
                ['2017-10-12', 69.09],
                ['2017-10-13', 69.38],
                ['2017-10-16', 69.16],
                ['2017-10-17', 69.08],
                ['2017-10-18', 69.02],
                ['2017-10-19', 69.33],
                ['2017-10-20', 69.26],
                ['2017-10-23', 69.27],
                ['2017-10-24', 69.08],
                ['2017-10-25', 68.92],
                ['2017-10-26', 68.69],
                ['2017-10-27', 69.21],
                ['2017-10-30', 69.11],
                ['2017-10-31', 69.16],
                ['2017-11-01', 69.11],
                ['2017-11-02', 69.27],
                ['2017-11-03', 69.35],
                ['2017-11-06', 69.52],
                ['2017-11-07', 69.52],
                ['2017-11-08', 69.64],
                ['2017-11-09', 69.52],
                ['2017-11-10', 69.25],
                ['2017-11-13', 69.38],
                ['2017-11-14', 69.52],
                ['2017-11-15', 69.28],
                ['2017-11-16', 69.56],
                ['2017-11-17', 69.73],
                ['2017-11-20', 69.49],
                ['2017-11-21', 69.76],
                ['2017-11-22', 69.94],
                ['2017-11-24', 69.99],
                ['2017-11-27', 70.01],
                ['2017-11-28', 70.26],
                ['2017-11-29', 69.89],
                ['2017-11-30', 70.04],
                ['2017-12-01', 70.07],
                ['2017-12-04', 69.73],
                ['2017-12-05', 69.41],
                ['2017-12-06', 69.44],
                ['2017-12-07', 69.24],
                ['2017-12-08', 69.32],
                ['2017-12-11', 69.31],
                ['2017-12-12', 69.24],
                ['2017-12-13', 69.51],
                ['2017-12-14', 69.25],
                ['2017-12-15', 69.73],
                ['2017-12-18', 70.03],
                ['2017-12-19', 69.77],
                ['2017-12-20', 69.72],
                ['2017-12-21', 69.54],
                ['2017-12-22', 69.63],
                ['2017-12-26', 69.70],
                ['2017-12-27', 70.01],
                ['2017-12-28', 70.31],
                ['2017-12-29', 70.46],
                ['2018-01-02', 70.86],
                ['2018-01-03', 71.11],
                ['2018-01-04', 71.32],
                ['2018-01-05', 71.53],
                ['2018-01-08', 71.62],
                ['2018-01-09', 71.45],
                ['2018-01-10', 71.26],
                ['2018-01-11', 71.52],
                ['2018-01-12', 71.88],
                ['2018-01-16', 71.86],
                ['2018-01-17', 72.12],
                ['2018-01-18', 72.01],
                ['2018-01-19', 72.30],
                ['2018-01-22', 72.52],
                ['2018-01-23', 72.77],
                ['2018-01-24', 72.98],
                ['2018-01-25', 73.04],
                ['2018-01-26', 73.39],
                ['2018-01-29', 72.91],
                ['2018-01-30', 72.36],
                ['2018-01-31', 72.59],
                ['2018-02-01', 72.42],
                ['2018-02-02', 71.23],
                ['2018-02-05', 70.00],
                ['2018-02-06', 70.22],
                ['2018-02-07', 69.65],
                ['2018-02-08', 68.45],
                ['2018-02-09', 69.02],
                ['2018-02-12', 69.46],
                ['2018-02-13', 69.69],
                ['2018-02-14', 70.33],
                ['2018-02-15', 70.88],
                ['2018-02-16', 70.97],
                ['2018-02-20', 70.47],
                ['2018-02-21', 69.97],
                ['2018-02-22', 70.13],
                ['2018-02-23', 70.70],
                ['2018-02-26', 71.16],
                ['2018-02-27', 70.26],
                ['2018-02-28', 70.11],
                ['2018-03-01', 69.70],
                ['2018-03-02', 70.06],
                ['2018-03-05', 70.37],
                ['2018-03-06', 70.79],
                ['2018-03-07', 70.79],
                ['2018-03-08', 70.96],
                ['2018-03-09', 71.41],
                ['2018-03-12', 71.41],
                ['2018-03-13', 71.32],
                ['2018-03-14', 71.20],
                ['2018-03-15', 71.02],
                ['2018-03-16', 70.93],
                ['2018-03-19', 70.57],
                ['2018-03-20', 70.47],
                ['2018-03-21', 70.70],
                ['2018-03-22', 69.84],
                ['2018-03-23', 69.65],
                ['2018-03-26', 70.58],
                ['2018-03-27', 70.04],
                ['2018-03-28', 69.76],
                ['2018-03-29', 70.29],
                ['2018-04-02', 69.79],
                ['2018-04-03', 69.93],
                ['2018-04-04', 70.32],
                ['2018-04-05', 70.17],
                ['2018-04-06', 69.59],
                ['2018-04-09', 69.80],
                ['2018-04-10', 70.37],
                ['2018-04-11', 70.41],
                ['2018-04-12', 70.31],
                ['2018-04-13', 70.42],
                ['2018-04-16', 70.74],
                ['2018-04-17', 71.26],
                ['2018-04-18', 71.13],
                ['2018-04-19', 70.48],
                ['2018-04-20', 69.96],
                ['2018-04-23', 69.69],
                ['2018-04-24', 69.44],
                ['2018-04-25', 69.29],
                ['2018-04-26', 69.66],
                ['2018-04-27', 69.80],
                ['2018-04-30', 69.59],
                ['2018-05-01', 69.49],
                ['2018-05-02', 69.08],
                ['2018-05-03', 69.16],
                ['2018-05-04', 69.62],
                ['2018-05-07', 69.77],
                ['2018-05-08', 69.63],
                ['2018-05-09', 69.70],
                ['2018-05-10', 70.30],
                ['2018-05-11', 70.29],
                ['2018-05-14', 70.26],
                ['2018-05-15', 69.40],
                ['2018-05-16', 69.42],
                ['2018-05-17', 69.42],
                ['2018-05-18', 69.41],
                ['2018-05-21', 69.70],
                ['2018-05-22', 69.55],
                ['2018-05-23', 69.84],
                ['2018-05-24', 70.13],
                ['2018-05-25', 70.26],
                ['2018-05-29', 70.33],
                ['2018-05-30', 70.66],
                ['2018-05-31', 70.37],
                ['2018-06-01', 70.44],
                ['2018-06-04', 70.53],
                ['2018-06-05', 70.66],
                ['2018-06-06', 70.81],
                ['2018-06-07', 70.89],
                ['2018-06-08', 71.01],
                ['2018-06-11', 70.93],
                ['2018-06-12', 70.96],
                ['2018-06-13', 70.88],
                ['2018-06-14', 71.15],
                ['2018-06-15', 70.91],
                ['2018-06-18', 70.68],
                ['2018-06-19', 70.58],
                ['2018-06-20', 70.41],
                ['2018-06-21', 70.25],
                ['2018-06-22', 70.39],
                ['2018-06-25', 70.02],
                ['2018-06-26', 69.69],
                ['2018-06-27', 69.23],
                ['2018-06-28', 69.45],
                ['2018-06-29', 69.65],
                ['2018-07-02', 69.74],
                ['2018-07-03', 69.97],
                ['2018-07-05', 70.45],
                ['2018-07-06', 70.72],
                ['2018-07-09', 70.86],
                ['2018-07-10', 70.94],
                ['2018-07-11', 70.56],
                ['2018-07-12', 70.91],
                ['2018-07-13', 70.85],
                ['2018-07-16', 70.63],
                ['2018-07-17', 70.61],
                ['2018-07-18', 70.63],
                ['2018-07-19', 70.59],
                ['2018-07-20', 70.60],
                ['2018-07-23', 70.41],
                ['2018-07-24', 70.37],
                ['2018-07-25', 70.79],
                ['2018-07-26', 70.78],
                ['2018-07-27', 70.59],
                ['2018-07-30', 70.25],
                ['2018-07-31', 70.73],
                ['2018-08-01', 70.32],
                ['2018-08-02', 70.46],
                ['2018-08-03', 70.72],
                ['2018-08-06', 70.69],
                ['2018-08-07', 70.76],
                ['2018-08-08', 70.89],
                ['2018-08-09', 70.82],
                ['2018-08-10', 70.60],
                ['2018-08-13', 70.11],
                ['2018-08-14', 70.31],
                ['2018-08-15', 69.93],
                ['2018-08-16', 70.17],
                ['2018-08-17', 70.47],
                ['2018-08-20', 70.76],
                ['2018-08-21', 70.82],
                ['2018-08-22', 70.93],
                ['2018-08-23', 70.78],
                ['2018-08-24', 71.46],
                ['2018-08-27', 71.60],
                ['2018-08-28', 71.43],
                ['2018-08-29', 71.68],
                ['2018-08-30', 71.42],
                ['2018-08-31', 71.64],
                ['2018-09-04', 71.40],
                ['2018-09-05', 71.50],
                ['2018-09-06', 71.62],
                ['2018-09-07', 71.31],
                ['2018-09-10', 71.40],
                ['2018-09-11', 71.35],
                ['2018-09-12', 71.61],
                ['2018-09-13', 71.84],
                ['2018-09-14', 71.77],
                ['2018-09-17', 71.69],
                ['2018-09-18', 71.73],
                ['2018-09-19', 71.54],
                ['2018-09-20', 71.91],
                ['2018-09-21', 71.84],
                ['2018-09-24', 71.73],
                ['2018-09-25', 71.61],
                ['2018-09-26', 71.22],
                ['2018-09-27', 71.22],
                ['2018-09-28', 71.64],
                ['2018-10-01', 71.51],
                ['2018-10-02', 71.89],
                ['2018-10-03', 71.50],
                ['2018-10-04', 71.08],
                ['2018-10-05', 71.01],
                ['2018-10-08', 70.66],
                ['2018-10-09', 70.74],
                ['2018-10-10', 69.69],
                ['2018-10-11', 69.67],
                ['2018-10-12', 70.04],
                ['2018-10-15', 70.16],
                ['2018-10-16', 70.99],
                ['2018-10-17', 70.91],
                ['2018-10-18', 70.58],
                ['2018-10-19', 70.42],
                ['2018-10-22', 70.30],
                ['2018-10-23', 70.33],
                ['2018-10-24', 69.56],
                ['2018-10-25', 69.78],
                ['2018-10-26', 69.58],
                ['2018-10-29', 69.40],
                ['2018-10-30', 69.96],
                ['2018-10-31', 70.14],
                ['2018-11-01', 70.77],
                ['2018-11-02', 70.57],
                ['2018-11-05', 70.72],
                ['2018-11-06', 70.93],
                ['2018-11-07', 71.59],
                ['2018-11-08', 71.47],
                ['2018-11-09', 71.21],
                ['2018-11-12', 70.37],
                ['2018-11-13', 70.40],
                ['2018-11-14', 70.48],
                ['2018-11-15', 71.03],
                ['2018-11-16', 71.33],
                ['2018-11-19', 70.73],
                ['2018-11-20', 70.31],
                ['2018-11-21', 70.31],
                ['2018-11-23', 70.33],
                ['2018-11-26', 70.69],
                ['2018-11-27', 70.58],
                ['2018-11-28', 71.31],
                ['2018-11-29', 71.43],
                ['2018-11-30', 71.75],
                ['2018-12-03', 72.28],
                ['2018-12-04', 71.59],
                ['2018-12-06', 71.83],
                ['2018-12-07', 71.27],
                ['2018-12-10', 71.42],
                ['2018-12-11', 71.43],
                ['2018-12-12', 71.57],
                ['2018-12-13', 71.57],
                ['2018-12-14', 70.94],
                ['2018-12-17', 70.17],
                ['2018-12-18', 70.16],
                ['2018-12-19', 69.72],
                ['2018-12-20', 69.50],
                ['2018-12-21', 69.07],
                ['2018-12-24', 68.35],
                ['2018-12-26', 69.57],
                ['2018-12-27', 70.31],
                ['2018-12-28', 70.38],
                ['2018-12-31', 71.02],
                ['2019-01-02', 70.66],
                ['2019-01-03', 70.34],
                ['2019-01-04', 71.08],
                ['2019-01-07', 71.25],
                ['2019-01-08', 71.36],
                ['2019-01-09', 71.74],
                ['2019-01-10', 71.91],
                ['2019-01-11', 72.09],
                ['2019-01-14', 71.77],
                ['2019-01-15', 72.18],
                ['2019-01-16', 72.14],
                ['2019-01-17', 72.34],
                ['2019-01-18', 72.60],
                ['2019-01-22', 72.32],
                ['2019-01-23', 72.51],
                ['2019-01-24', 72.81],
                ['2019-01-25', 73.27],
                ['2019-01-28', 73.10],
                ['2019-01-29', 73.34],
                ['2019-01-30', 74.07],
                ['2019-01-31', 74.62],
                ['2019-02-01', 74.48],
                ['2019-02-04', 74.63],
                ['2019-02-05', 74.80],
                ['2019-02-06', 74.77],
                ['2019-02-07', 74.66],
                ['2019-02-08', 74.90],
                ['2019-02-11', 74.83],
                ['2019-02-12', 75.18],
                ['2019-02-13', 75.03],
                ['2019-02-14', 75.25],
                ['2019-02-15', 75.78],
                ['2019-02-19', 76.22],
                ['2019-02-20', 76.26],
                ['2019-02-21', 75.90],
                ['2019-02-22', 76.24],
                ['2019-02-25', 76.11],
                ['2019-02-26', 76.12],
                ['2019-02-27', 75.92],
                ['2019-02-28', 75.86],
                ['2019-03-01', 75.71],
                ['2019-03-04', 75.57],
                ['2019-03-05', 75.58],
                ['2019-03-06', 75.28],
                ['2019-03-07', 75.12],
                ['2019-03-08', 75.34],
                ['2019-03-11', 75.76],
                ['2019-03-12', 76.03],
                ['2019-03-13', 76.39],
                ['2019-03-14', 75.99],
                ['2019-03-15', 76.48],
                ['2019-03-18', 76.39],
                ['2019-03-19', 76.47],
                ['2019-03-20', 76.43],
                ['2019-03-21', 76.93],
                ['2019-03-22', 76.65],
                ['2019-03-25', 76.93],
                ['2019-03-26', 77.26],
                ['2019-03-27', 77.00],
                ['2019-03-28', 76.73],
                ['2019-03-29', 77.30],
                ['2019-04-01', 77.24],
                ['2019-04-02', 77.29],
                ['2019-04-03', 77.29],
                ['2019-04-04', 77.39],
                ['2019-04-05', 77.59],
                ['2019-04-08', 77.66],
                ['2019-04-09', 77.65],
                ['2019-04-10', 77.88],
                ['2019-04-11', 77.42],
                ['2019-04-12', 77.45],
                ['2019-04-15', 77.38],
                ['2019-04-16', 76.97],
                ['2019-04-17', 76.52],
                ['2019-04-18', 76.64],
                ['2019-04-22', 76.53],
                ['2019-04-23', 76.94],
                ['2019-04-24', 77.20],
                ['2019-04-25', 77.14],
                ['2019-04-26', 77.45],
                ['2019-04-29', 77.14],
                ['2019-04-30', 77.86],
                ['2019-05-01', 77.29],
                ['2019-05-02', 77.30],
                ['2019-05-03', 77.68],
                ['2019-05-06', 77.77],
                ['2019-05-07', 77.43],
                ['2019-05-08', 77.22],
                ['2019-05-09', 77.17],
                ['2019-05-10', 77.48],
                ['2019-05-13', 77.14],
                ['2019-05-14', 77.37],
                ['2019-05-15', 77.58],
                ['2019-05-16', 77.54],
                ['2019-05-17', 77.20],
                ['2019-05-20', 76.93],
                ['2019-05-21', 77.22],
                ['2019-05-22', 77.25],
                ['2019-05-23', 77.43],
                ['2019-05-24', 77.44],
                ['2019-05-28', 77.03],
                ['2019-05-29', 76.93],
                ['2019-05-30', 77.42],
                ['2019-05-31', 77.85],
                ['2019-06-03', 78.41],
                ['2019-06-04', 78.97],
                ['2019-06-05', 79.48],
                ['2019-06-06', 79.81],
                ['2019-06-07', 80.33],
                ['2019-06-10', 80.05],
                ['2019-06-11', 79.92],
                ['2019-06-12', 80.16],
                ['2019-06-13', 80.39],
                ['2019-06-14', 80.32],
                ['2019-06-17', 80.04],
                ['2019-06-18', 80.61],
                ['2019-06-19', 81.10],
                ['2019-06-20', 81.96],
                ['2019-06-21', 82.09],
                ['2019-06-24', 82.45],
                ['2019-06-25', 82.23],
                ['2019-06-26', 81.64],
                ['2019-06-27', 81.93],
                ['2019-06-28', 82.46],
                ['2019-07-01', 82.26],
                ['2019-07-02', 82.94],
                ['2019-07-03', 83.43],
                ['2019-07-05', 82.87],
                ['2019-07-08', 82.60],
                ['2019-07-09', 82.77],
                ['2019-07-10', 83.32],
                ['2019-07-11', 83.04],
                ['2019-07-12', 83.30],
                ['2019-07-15', 83.50],
                ['2019-07-16', 83.03],
                ['2019-07-17', 83.51],
                ['2019-07-18', 84.25],
                ['2019-07-19', 83.45],
                ['2019-07-22', 83.59],
                ['2019-07-23', 83.59],
                ['2019-07-24', 83.93],
                ['2019-07-25', 83.74],
                ['2019-07-26', 84.06],
                ['2019-07-29', 84.33],
                ['2019-07-30', 84.25],
                ['2019-07-31', 83.65],
                ['2019-08-01', 84.37],
                ['2019-08-02', 84.15],
                ['2019-08-05', 83.58],
                ['2019-08-06', 84.47],
                ['2019-08-07', 85.09],
                ['2019-08-08', 85.96],
                ['2019-08-09', 85.61],
                ['2019-08-12', 85.56],
                ['2019-08-13', 85.85],
                ['2019-08-14', 85.19],
                ['2019-08-15', 85.84],
                ['2019-08-16', 86.10],
                ['2019-08-19', 86.09],
                ['2019-08-20', 86.18],
                ['2019-08-21', 86.32],
                ['2019-08-22', 86.22],
                ['2019-08-23', 85.83],
                ['2019-08-26', 86.32],
                ['2019-08-27', 86.58],
                ['2019-08-28', 86.76],
                ['2019-08-29', 86.99],
                ['2019-08-30', 87.13],
                ['2019-09-03', 87.31],
                ['2019-09-04', 87.92],
                ['2019-09-05', 87.48],
                ['2019-09-06', 87.43],
                ['2019-09-09', 86.80],
                ['2019-09-10', 86.19],
                ['2019-09-11', 86.54],
                ['2019-09-12', 86.77],
                ['2019-09-13', 86.11],
                ['2019-09-16', 86.33],
                ['2019-09-17', 86.74],
                ['2019-09-18', 86.66],
                ['2019-09-19', 86.72],
                ['2019-09-20', 87.00],
                ['2019-09-23', 87.28],
                ['2019-09-24', 87.13],
                ['2019-09-25', 86.60],
                ['2019-09-26', 86.66],
                ['2019-09-27', 86.29],
                ['2019-09-30', 86.54],
                ['2019-10-01', 86.27],
                ['2019-10-02', 86.00],
                ['2019-10-03', 86.69],
                ['2019-10-04', 87.32],
                ['2019-10-07', 86.82],
                ['2019-10-08', 86.38],
                ['2019-10-09', 86.72],
                ['2019-10-10', 86.57],
                ['2019-10-11', 86.48],
                ['2019-10-14', 86.50],
                ['2019-10-15', 86.47],
                ['2019-10-16', 86.54],
                ['2019-10-17', 86.76],
                ['2019-10-18', 86.79],
                ['2019-10-21', 86.73],
                ['2019-10-22', 86.43],
                ['2019-10-23', 86.52],
                ['2019-10-24', 86.83],
                ['2019-10-25', 86.84],
                ['2019-10-28', 86.68],
                ['2019-10-29', 86.72],
                ['2019-10-30', 87.22],
                ['2019-10-31', 87.73],
                ['2019-11-01', 87.85],
                ['2019-11-04', 87.44],
                ['2019-11-05', 86.65],
                ['2019-11-06', 87.03],
                ['2019-11-07', 86.54],
                ['2019-11-08', 86.40],
                ['2019-11-11', 86.30],
                ['2019-11-12', 86.46],
                ['2019-11-13', 86.85],
                ['2019-11-14', 87.15],
                ['2019-11-15', 87.37],
                ['2019-11-18', 87.49],
                ['2019-11-19', 87.63],
                ['2019-11-20', 87.64],
                ['2019-11-21', 87.20],
                ['2019-11-22', 87.15],
                ['2019-11-25', 87.35],
                ['2019-11-26', 87.75],
                ['2019-11-27', 87.74],
                ['2019-11-29', 87.92],
                ['2019-12-02', 87.41],
                ['2019-12-03', 87.76],
                ['2019-12-04', 87.91],
                ['2019-12-05', 87.87],
                ['2019-12-06', 87.88],
                ['2019-12-09', 87.70],
                ['2019-12-10', 87.72],
                ['2019-12-11', 88.13],
                ['2019-12-12', 88.06],
                ['2019-12-13', 88.42],
                ['2019-12-16', 88.35],
                ['2019-12-17', 88.37],
                ['2019-12-18', 88.18],
                ['2019-12-19', 88.45],
                ['2019-12-20', 88.93],
                ['2019-12-23', 88.91],
                ['2019-12-24', 89.20],
                ['2019-12-26', 89.54],
                ['2019-12-27', 89.64],
                ['2019-12-30', 89.51],
                ['2019-12-31', 89.64],
                ['2020-01-02', 90.10],
                ['2020-01-03', 90.49],
                ['2020-01-06', 90.72],
                ['2020-01-07', 90.77],
                ['2020-01-08', 90.59],
                ['2020-01-09', 90.75],
                ['2020-01-10', 90.90],
                ['2020-01-13', 90.94],
                ['2020-01-14', 91.03],
                ['2020-01-15', 91.44],
                ['2020-01-16', 91.77],
                ['2020-01-17', 91.97],
                ['2020-01-21', 92.20],
                ['2020-01-22', 92.38],
                ['2020-01-23', 92.57],
                ['2020-01-24', 92.62],
                ['2020-01-27', 92.44],
                ['2020-01-28', 92.54],
                ['2020-01-29', 92.55],
                ['2020-01-30', 92.81],
                ['2020-01-31', 92.40],
                ['2020-02-03', 92.43],
                ['2020-02-04', 92.49],
                ['2020-02-05', 92.80],
                ['2020-02-06', 92.96],
                ['2020-02-07', 92.75],
                ['2020-02-10', 93.17],
                ['2020-02-11', 93.29],
                ['2020-02-12', 93.32],
                ['2020-02-13', 93.69],
                ['2020-02-14', 94.00],
                ['2020-02-18', 94.21],
                ['2020-02-19', 94.56],
                ['2020-02-20', 94.59],
                ['2020-02-21', 94.69],
                ['2020-02-24', 94.01],
                ['2020-02-25', 92.44],
                ['2020-02-26', 92.32],
                ['2020-02-27', 90.65],
                ['2020-02-28', 89.40],
                ['2020-03-02', 91.69],
                ['2020-03-03', 91.91],
                ['2020-03-04', 93.88],
                ['2020-03-05', 93.19],
                ['2020-03-06', 92.86],
                ['2020-03-09', 89.82],
                ['2020-03-10', 90.96],
                ['2020-03-11', 88.49],
                ['2020-03-12', 82.72],
                ['2020-03-13', 84.38],
                ['2020-03-16', 79.34],
                ['2020-03-17', 82.18],
                ['2020-03-18', 77.99],
                ['2020-03-19', 77.44],
                ['2020-03-20', 76.47],
                ['2020-03-23', 76.26],
                ['2020-03-24', 81.33],
                ['2020-03-25', 81.66],
                ['2020-03-26', 84.88],
                ['2020-03-27', 83.87],
                ['2020-03-30', 85.41],
                ['2020-03-31', 83.73],
                ['2020-04-01', 82.22],
                ['2020-04-02', 83.77],
                ['2020-04-03', 82.96],
                ['2020-04-06', 86.49],
                ['2020-04-07', 86.18],
                ['2020-04-08', 87.77],
                ['2020-04-09', 89.37],
                ['2020-04-13', 88.95],
                ['2020-04-14', 90.46],
                ['2020-04-15', 89.97],
                ['2020-04-16', 90.49],
                ['2020-04-17', 91.17],
                ['2020-04-20', 90.54],
                ['2020-04-21', 89.16],
                ['2020-04-22', 90.46],
                ['2020-04-23', 90.38],
                ['2020-04-24', 90.78],
                ['2020-04-27', 91.12],
                ['2020-04-28', 91.12],
                ['2020-04-29', 91.94],
                ['2020-04-30', 90.97],
                ['2020-05-01', 90.09],
                ['2020-05-04', 90.30],
                ['2020-05-05', 90.94],
                ['2020-05-06', 90.29],
                ['2020-05-07', 91.48],
                ['2020-05-08', 92.02],
                ['2020-05-11', 91.68],
                ['2020-05-12', 90.85],
                ['2020-05-13', 90.40],
                ['2020-05-14', 91.20],
                ['2020-05-15', 91.30],
                ['2020-05-18', 92.18],
                ['2020-05-19', 91.95],
                ['2020-05-20', 92.53],
                ['2020-05-21', 91.72],
                ['2020-05-22', 92.20],
                ['2020-05-26', 92.29],
                ['2020-05-27', 93.12],
                ['2020-05-28', 93.35],
                ['2020-05-29', 94.09],
                ['2020-06-01', 94.47],
                ['2020-06-02', 94.36],
                ['2020-06-03', 94.34],
                ['2020-06-04', 94.29],
                ['2020-06-05', 94.97],
                ['2020-06-08', 95.68],
                ['2020-06-09', 95.43],
                ['2020-06-10', 95.55],
                ['2020-06-11', 92.90],
                ['2020-06-12', 93.35],
                ['2020-06-15', 93.53],
                ['2020-06-16', 94.15],
                ['2020-06-17', 94.28],
                ['2020-06-18', 94.25],
                ['2020-06-19', 94.53],
                ['2020-06-22', 94.81],
                ['2020-06-23', 95.14],
                ['2020-06-24', 93.98],
                ['2020-06-25', 94.39],
                ['2020-06-26', 93.89],
                ['2020-06-29', 94.52],
                ['2020-06-30', 95.63],
                ['2020-07-01', 95.46],
                ['2020-07-02', 95.75],
                ['2020-07-06', 96.35],
                ['2020-07-07', 96.24],
                ['2020-07-08', 96.55],
                ['2020-07-09', 96.49],
                ['2020-07-10', 96.66],
                ['2020-07-13', 96.26],
                ['2020-07-14', 97.21],
                ['2020-07-15', 97.71],
                ['2020-07-16', 97.57],
                ['2020-07-17', 98.32],
                ['2020-07-20', 98.64],
                ['2020-07-21', 98.99],
                ['2020-07-22', 99.81],
                ['2020-07-23', 99.83],
                ['2020-07-24', 99.59],
                ['2020-07-27', 100.40],
                ['2020-07-28', 100.36],
                ['2020-07-29', 101.21],
                ['2020-07-30', 100.93],
                ['2020-07-31', 101.28],
                ['2020-08-03', 101.57],
                ['2020-08-04', 102.25],
                ['2020-08-05', 102.45],
                ['2020-08-06', 102.75],
                ['2020-08-07', 102.61],
                ['2020-08-10', 102.33],
                ['2020-08-11', 100.42],
                ['2020-08-12', 100.86],
                ['2020-08-13', 101.33],
                ['2020-08-14', 101.18],
                ['2020-08-17', 102.16],
                ['2020-08-18', 102.34],
                ['2020-08-19', 101.30],
                ['2020-08-20', 101.45],
                ['2020-08-21', 101.30],
                ['2020-08-24', 101.33],
                ['2020-08-25', 101.45],
                ['2020-08-26', 101.92],
                ['2020-08-27', 101.69],
                ['2020-08-28', 102.52],
                ['2020-08-31', 102.67],
                ['2020-09-01', 102.98],
                ['2020-09-02', 103.66],
                ['2020-09-03', 101.94],
                ['2020-09-04', 101.48],
                ['2020-09-08', 100.49],
                ['2020-09-09', 101.62],
                ['2020-09-10', 101.04],
                ['2020-09-11', 101.17],
                ['2020-09-14', 102.06],
                ['2020-09-15', 102.36],
                ['2020-09-16', 102.35],
                ['2020-09-17', 101.92],
                ['2020-09-18', 101.58],
                ['2020-09-21', 100.66],
                ['2020-09-22', 100.95],
                ['2020-09-23', 99.29],
                ['2020-09-24', 99.33],
                ['2020-09-25', 100.11],
                ['2020-09-28', 101.08],
                ['2020-09-29', 101.33],
                ['2020-09-30', 101.87],
                ['2020-10-01', 102.36],
                ['2020-10-02', 101.92],
                ['2020-10-05', 102.66],
                ['2020-10-06', 102.28],
                ['2020-10-07', 102.50],
                ['2020-10-08', 102.99],
                ['2020-10-09', 103.92],
                ['2020-10-12', 104.48],
                ['2020-10-13', 103.86],
                ['2020-10-14', 103.92],
                ['2020-10-15', 103.49],
                ['2020-10-16', 103.72],
                ['2020-10-19', 103.02],
                ['2020-10-20', 102.89],
                ['2020-10-21', 103.15],
                ['2020-10-22', 102.94],
                ['2020-10-23', 103.05],
                ['2020-10-26', 102.29],
                ['2020-10-27', 102.32],
                ['2020-10-28', 100.54],
                ['2020-10-29', 100.46],
                ['2020-10-30', 100.36],
                ['2020-11-02', 101.13],
                ['2020-11-03', 102.09],
                ['2020-11-04', 103.06],
                ['2020-11-05', 104.13],
                ['2020-11-06', 104.41],
                ['2020-11-09', 103.38],
                ['2020-11-10', 103.46],
                ['2020-11-11', 103.66],
                ['2020-11-12', 103.63],
                ['2020-11-13', 104.44],
                ['2020-11-16', 104.68],
                ['2020-11-17', 104.54],
                ['2020-11-18', 103.80],
                ['2020-11-19', 103.78],
                ['2020-11-20', 103.89],
                ['2020-11-23', 103.44],
                ['2020-11-24', 103.16],
                ['2020-11-25', 103.24],
                ['2020-11-26', 103.23],
                ['2020-11-27', 103.09],
                ['2020-11-30', 102.99],
                ['2020-12-01', 103.79],
                ['2020-12-02', 103.80],
                ['2020-12-03', 103.98],
                ['2020-12-04', 104.48],
                ['2020-12-07', 104.73],
                ['2020-12-08', 104.96],
                ['2020-12-09', 104.17],
                ['2020-12-10', 104.11],
                ['2020-12-11', 104.13],
                ['2020-12-14', 103.85],
                ['2020-12-15', 104.63],
                ['2020-12-16', 104.67],
                ['2020-12-17', 105.61],
                ['2020-12-18', 105.61],
                ['2020-12-21', 105.19],
                ['2020-12-22', 105.05],
                ['2020-12-23', 104.88],
                ['2020-12-24', 105.14],
                ['2020-12-25', 105.13],
                ['2020-12-28', 105.20],
                ['2020-12-29', 105.11],
                ['2020-12-30', 105.09],
                ['2020-12-31', 105.66],
                ['2021-01-01', 105.65],
                ['2021-01-04', 105.80],
                ['2021-01-05', 105.96],
                ['2021-01-06', 106.41],
                ['2021-01-07', 106.98],
                ['2021-01-08', 106.46],
                ['2021-01-11', 106.02],
                ['2021-01-12', 105.63],
                ['2021-01-13', 106.02],
                ['2021-01-14', 105.41],
                ['2021-01-15', 105.37],
                ['2021-01-18', 105.37],
                ['2021-01-19', 105.73],
                ['2021-01-20', 106.46],
                ['2021-01-21', 106.46],
                ['2021-01-22', 106.11],
                ['2021-01-25', 106.57],
                ['2021-01-26', 106.43],
                ['2021-01-27', 104.86],
                ['2021-01-28', 105.31],
                ['2021-01-29', 105.08],
                ['2021-02-01', 105.64],
                ['2021-02-02', 105.60],
                ['2021-02-03', 105.09],
                ['2021-02-04', 104.71],
                ['2021-02-05', 105.10],
                ['2021-02-08', 105.99],
                ['2021-02-09', 106.06],
                ['2021-02-10', 106.18],
                ['2021-02-11', 106.38],
                ['2021-02-12', 106.26],
                ['2021-02-15', 106.26],
                ['2021-02-16', 105.40],
                ['2021-02-17', 105.11],
                ['2021-02-18', 104.90],
                ['2021-02-19', 104.60],
                ['2021-02-22', 104.34],
                ['2021-02-23', 104.13],
                ['2021-02-24', 104.24],
                ['2021-02-25', 102.90],
                ['2021-02-26', 102.51],
                ['2021-03-01', 103.21],
                ['2021-03-02', 102.78],
                ['2021-03-03', 101.65],
                ['2021-03-04', 100.60],
                ['2021-03-05', 101.39],
                ['2021-03-08', 100.85],
                ['2021-03-09', 102.12],
                ['2021-03-10', 102.31],
                ['2021-03-11', 102.87],
                ['2021-03-12', 102.43],
                ['2021-03-15', 103.21],
                ['2021-03-16', 103.44],
                ['2021-03-17', 103.19],
                ['2021-03-18', 102.40],
                ['2021-03-19', 102.47],
                ['2021-03-22', 103.06],
                ['2021-03-23', 102.80],
                ['2021-03-24', 102.75],
                ['2021-03-25', 102.89],
                ['2021-03-26', 103.74],
                ['2021-03-29', 103.38],
                ['2021-03-30', 102.63],
                ['2021-03-31', 103.05],
                ['2021-04-01', 104.21],
                ['2021-04-02', 104.20],
                ['2021-04-05', 104.75],
                ['2021-04-06', 105.10],
                ['2021-04-07', 104.90],
                ['2021-04-08', 105.43],
                ['2021-04-09', 105.35],
                ['2021-04-12', 105.11],
                ['2021-04-13', 105.65],
                ['2021-04-14', 105.29],
                ['2021-04-15', 106.53],
                ['2021-04-16', 106.92],
                ['2021-04-19', 106.65],
                ['2021-04-20', 106.93],
                ['2021-04-21', 107.67],
                ['2021-04-22', 107.24],
                ['2021-04-23', 107.51],
                ['2021-04-26', 107.29],
                ['2021-04-27', 107.11],
                ['2021-04-28', 106.76],
                ['2021-04-29', 106.73],
                ['2021-04-30', 106.58],
                ['2021-05-03', 106.68],
                ['2021-05-04', 106.82],
                ['2021-05-05', 106.53],
                ['2021-05-06', 107.36],
                ['2021-05-07', 107.96],
                ['2021-05-10', 107.67],
                ['2021-05-11', 107.15],
                ['2021-05-12', 105.97],
                ['2021-05-13', 106.50],
                ['2021-05-14', 107.26],
                ['2021-05-17', 107.20],
                ['2021-05-18', 107.19],
                ['2021-05-19', 107.38],
                ['2021-05-20', 108.01],
                ['2021-05-21', 107.93],
                ['2021-05-24', 108.23],
                ['2021-05-25', 108.35],
                ['2021-05-26', 108.39],
                ['2021-05-27', 108.28],
                ['2021-05-28', 108.68],
                ['2021-05-31', 108.77],
                ['2021-06-01', 108.38],
                ['2021-06-02', 108.59],
                ['2021-06-03', 107.92],
                ['2021-06-04', 108.88],
                ['2021-06-07', 108.74],
                ['2021-06-08', 108.79],
                ['2021-06-09', 109.02],
                ['2021-06-10', 109.44],
                ['2021-06-11', 109.29],
                ['2021-06-14', 109.11],
                ['2021-06-15', 108.90],
                ['2021-06-16', 108.34],
                ['2021-06-17', 107.58],
                ['2021-06-18', 107.05],
                ['2021-06-21', 107.47],
                ['2021-06-22', 107.67],
                ['2021-06-23', 107.67],
                ['2021-06-24', 107.86],
                ['2021-06-25', 108.10],
                ['2021-06-28', 108.38],
                ['2021-06-29', 108.12],
                ['2021-06-30', 108.40],
                ['2021-07-01', 108.75],
                ['2021-07-02', 109.43],
                ['2021-07-05', 109.41],
                ['2021-07-06', 109.96],
                ['2021-07-07', 110.10],
                ['2021-07-08', 109.77],
                ['2021-07-09', 109.97],
                ['2021-07-12', 109.81],
                ['2021-07-13', 109.80],
                ['2021-07-14', 110.15],
                ['2021-07-15', 110.21],
                ['2021-07-16', 110.15],
                ['2021-07-19', 110.00],
                ['2021-07-20', 110.48],
                ['2021-07-21', 110.27],
                ['2021-07-22', 110.40],
                ['2021-07-23', 110.85],
                ['2021-07-26', 110.72],
                ['2021-07-27', 110.74],
                ['2021-07-28', 110.76],
                ['2021-07-29', 111.37],
                ['2021-07-30', 111.64],
                ['2021-08-02', 111.57],
                ['2021-08-03', 111.85],
                ['2021-08-04', 111.88],
                ['2021-08-05', 111.60],
                ['2021-08-06', 110.72],
                ['2021-08-09', 110.29],
                ['2021-08-10', 109.86],
                ['2021-08-11', 110.17],
                ['2021-08-12', 110.23],
                ['2021-08-13', 110.95],
                ['2021-08-16', 111.38],
                ['2021-08-17', 111.43],
                ['2021-08-18', 110.77],
                ['2021-08-19', 111.02],
                ['2021-08-20', 111.28],
                ['2021-08-23', 111.82],
                ['2021-08-24', 111.80],
                ['2021-08-25', 111.38],
                ['2021-08-26', 111.20],
                ['2021-08-27', 111.76],
                ['2021-08-30', 112.07],
                ['2021-08-31', 112.14],
                ['2021-09-01', 112.69],
                ['2021-09-02', 113.02],
                ['2021-09-03', 113.17],
                ['2021-09-06', 113.16],
                ['2021-09-07', 112.41],
                ['2021-09-08', 112.34],
                ['2021-09-09', 112.07],
                ['2021-09-10', 111.93],
                ['2021-09-13', 111.77],
                ['2021-09-14', 111.64],
                ['2021-09-15', 111.89],
                ['2021-09-16', 111.03],
                ['2021-09-17', 110.84],
                ['2021-09-20', 110.29],
                ['2021-09-21', 110.48],
                ['2021-09-22', 110.76],
                ['2021-09-23', 110.75],
                ['2021-09-24', 110.42],
                ['2021-09-27', 110.05],
                ['2021-09-28', 108.77],
                ['2021-09-29', 108.94],
                ['2021-09-30', 108.62],
                ['2021-10-01', 108.79],
                ['2021-10-04', 108.15],
                ['2021-10-05', 108.28],
                ['2021-10-06', 108.57],
                ['2021-10-07', 108.92],
                ['2021-10-08', 108.72],
                ['2021-10-11', 108.03],
                ['2021-10-12', 108.15],
                ['2021-10-13', 108.67],
                ['2021-10-14', 109.74],
                ['2021-10-15', 109.43],
                ['2021-10-18', 109.39],
                ['2021-10-19', 109.94],
                ['2021-10-20', 110.22],
                ['2021-10-21', 110.43],
                ['2021-10-22', 110.98],
                ['2021-10-25', 110.96],
                ['2021-10-26', 110.76],
                ['2021-10-27', 110.62],
                ['2021-10-28', 111.05],
                ['2021-10-29', 110.86],
                ['2021-11-01', 111.26],
                ['2021-11-02', 111.43],
                ['2021-11-03', 111.20],
                ['2021-11-04', 111.87],
                ['2021-11-05', 111.76],
                ['2021-11-08', 112.02],
                ['2021-11-09', 112.14],
                ['2021-11-10', 112.16],
                ['2021-11-11', 112.22],
                ['2021-11-12', 112.59],
                ['2021-11-15', 112.47],
                ['2021-11-16', 112.68],
                ['2021-11-17', 112.72],
                ['2021-11-18', 112.68],
                ['2021-11-19', 112.66],
                ['2021-11-22', 111.54],
                ['2021-11-23', 110.90],
                ['2021-11-24', 111.03],
                ['2021-11-25', 111.00],
                ['2021-11-26', 111.00],
                ['2021-11-29', 111.22],
                ['2021-11-30', 110.96],
                ['2021-12-01', 110.55],
                ['2021-12-02', 110.72],
                ['2021-12-03', 110.80],
                ['2021-12-06', 111.13],
                ['2021-12-07', 111.90],
                ['2021-12-08', 111.99],
                ['2021-12-09', 111.62],
                ['2021-12-10', 112.07],
                ['2021-12-13', 112.11],
                ['2021-12-14', 111.43],
                ['2021-12-15', 111.97],
                ['2021-12-16', 112.32],
                ['2021-12-17', 112.05],
                ['2021-12-20', 111.53],
                ['2021-12-21', 111.91],
                ['2021-12-22', 112.42],
                ['2021-12-23', 112.73],
                ['2021-12-24', 112.72],
                ['2021-12-27', 113.16],
                ['2021-12-28', 113.11],
                ['2021-12-29', 113.25],
                ['2021-12-30', 113.42],
                ['2021-12-31', 113.67],
                ['2022-01-03', 113.46],
                ['2022-01-04', 113.07],
                ['2022-01-05', 112.38],
                ['2022-01-06', 111.47],
                ['2022-01-07', 110.97],
                ['2022-01-10', 110.88],
                ['2022-01-11', 111.35],
                ['2022-01-12', 111.65],
                ['2022-01-13', 111.10],
                ['2022-01-14', 110.71],
                ['2022-01-17', 110.64],
                ['2022-01-18', 109.48],
                ['2022-01-19', 109.50],
                ['2022-01-20', 109.27],
                ['2022-01-21', 108.78],
                ['2022-01-24', 108.81],
                ['2022-01-25', 108.08],
                ['2022-01-26', 107.28],
                ['2022-01-27', 107.27],
                ['2022-01-28', 107.26],
                ['2022-01-31', 107.25],
                ['2022-02-01', 107.34],
                ['2022-02-02', 108.10],
                ['2022-02-03', 107.26],
                ['2022-02-04', 106.96],
                ['2022-02-07', 106.91],
                ['2022-02-08', 107.23],
                ['2022-02-09', 108.14],
                ['2022-02-10', 106.81],
                ['2022-02-11', 106.80],
                ['2022-02-14', 106.45],
                ['2022-02-15', 106.79],
                ['2022-02-16', 106.92],
                ['2022-02-17', 106.67],
                ['2022-02-18', 106.56],
                ['2022-02-22', 106.36],
                ['2022-02-23', 105.61],
                ['2022-02-24', 106.16],
                ['2022-02-25', 106.70],
                ['2022-02-28', 107.20],
                ['2022-03-01', 107.22],
                ['2022-03-02', 107.20],
                ['2022-03-03', 107.27],
                ['2022-03-04', 107.58],
                ['2022-03-07', 107.12],
                ['2022-03-08', 106.93],
                ['2022-03-09', 106.94],
                ['2022-03-10', 106.80],
                ['2022-03-11', 106.50],
                ['2022-03-14', 106.00],
                ['2022-03-15', 106.26],
                ['2022-03-16', 106.70],
                ['2022-03-17', 107.04],
                ['2022-03-18', 107.17],
                ['2022-03-21', 107.02],
                ['2022-03-22', 106.94],
                ['2022-03-23', 106.82],
                ['2022-03-24', 107.08],
                ['2022-03-25', 107.02],
                ['2022-03-28', 107.05],
                ['2022-03-29', 107.47],
                ['2022-03-30', 107.78],
                ['2022-03-31', 107.63],
                ['2022-04-01', 107.59],
                ['2022-04-04', 107.65],
                ['2022-04-05', 107.25],
                ['2022-04-06', 107.21],
                ['2022-04-07', 107.42],
                ['2022-04-08', 107.35],
                ['2022-04-11', 107.00],
                ['2022-04-12', 107.02],
                ['2022-04-13', 107.37],
                ['2022-04-14', 106.94],
                ['2022-04-18', 106.83],
                ['2022-04-19', 106.84],
                ['2022-04-20', 107.17],
                ['2022-04-21', 106.76],
                ['2022-04-22', 106.04],
                ['2022-04-25', 106.01],
                ['2022-04-26', 105.54],
                ['2022-04-27', 105.28],
                ['2022-04-28', 105.79],
                ['2022-04-29', 105.12],
                ['2022-05-02', 104.77],
                ['2022-05-03', 104.92],
                ['2022-05-04', 105.63],
                ['2022-05-05', 104.73],
                ['2022-05-06', 104.42],
                ['2022-05-09', 103.66],
                ['2022-05-10', 103.65],
                ['2022-05-11', 103.63],
                ['2022-05-12', 103.53],
                ['2022-05-13', 103.81],
                ['2022-05-16', 103.92],
                ['2022-05-17', 104.19],
                ['2022-05-18', 103.41],
                ['2022-05-19', 103.57],
                ['2022-05-20', 103.79],
                ['2022-05-23', 104.06],
                ['2022-05-24', 104.25],
                ['2022-05-25', 104.30],
                ['2022-05-26', 104.62],
                ['2022-05-27', 105.24],
                ['2022-05-31', 104.82],
                ['2022-06-01', 104.51],
                ['2022-06-02', 105.09],
                ['2022-06-03', 104.64],
                ['2022-06-06', 104.41],
                ['2022-06-07', 104.73],
                ['2022-06-08', 104.33],
                ['2022-06-09', 103.69],
                ['2022-06-10', 103.27],
                ['2022-06-13', 101.83],
                ['2022-06-14', 101.40],
                ['2022-06-15', 101.94],
                ['2022-06-16', 101.53],
                ['2022-06-17', 101.58],
                ['2022-06-21', 101.95],
                ['2022-06-22', 102.27],
                ['2022-06-23', 102.64],
                ['2022-06-24', 103.15],
                ['2022-06-27', 103.02],
                ['2022-06-28', 102.62],
                ['2022-06-29', 102.74],
                ['2022-06-30', 102.77],
                ['2022-07-01', 103.07],
                ['2022-07-05', 102.83],
                ['2022-07-06', 102.63],
                ['2022-07-07', 102.74],
                ['2022-07-08', 102.66],
                ['2022-07-11', 102.51],
                ['2022-07-12', 102.32],
                ['2022-07-13', 102.36],
                ['2022-07-14', 102.11],
                ['2022-07-15', 102.51],
                ['2022-07-18', 102.20],
                ['2022-07-19', 102.71],
                ['2022-07-20', 102.74],
                ['2022-07-21', 103.33],
                ['2022-07-22', 103.39],
                ['2022-07-25', 103.29],
                ['2022-07-26', 103.24],
                ['2022-07-27', 103.81],
                ['2022-07-28', 104.42],
                ['2022-07-29', 104.64],
                ['2022-08-01', 104.74],
                ['2022-08-02', 104.39],
                ['2022-08-03', 104.78],
                ['2022-08-04', 105.03],
                ['2022-08-05', 104.77],
                ['2022-08-08', 104.96],
                ['2022-08-09', 105.02],
                ['2022-08-10', 105.41],
                ['2022-08-11', 105.20],
                ['2022-08-12', 105.76],
                ['2022-08-15', 105.81],
                ['2022-08-16', 105.81],
                ['2022-08-17', 105.38],
                ['2022-08-18', 105.47],
                ['2022-08-19', 105.01],
                ['2022-08-22', 104.44],
                ['2022-08-23', 104.29],
                ['2022-08-24', 104.31],
                ['2022-08-25', 104.76],
                ['2022-08-26', 103.84],
                ['2022-08-29', 103.57],
                ['2022-08-30', 103.28],
                ['2022-08-31', 103.08],
                ['2022-09-01', 102.99],
                ['2022-09-02', 102.88],
                ['2022-09-06', 102.56],
                ['2022-09-07', 103.24],
                ['2022-09-08', 103.34],
                ['2022-09-09', 103.73],
                ['2022-09-12', 103.93],
                ['2022-09-13', 102.87],
                ['2022-09-14', 102.86],
                ['2022-09-15', 102.32],
                ['2022-09-16', 102.29],
                ['2022-09-19', 102.32],
                ['2022-09-20', 101.85],
                ['2022-09-21', 101.67],
                ['2022-09-22', 101.27],
                ['2022-09-23', 100.85],
                ['2022-09-26', 100.07],
                ['2022-09-27', 99.88],
                ['2022-09-28', 100.79],
                ['2022-09-29', 100.33],
                ['2022-09-30', 100.23],
                ['2022-10-03', 101.20],
                ['2022-10-04', 102.02],
                ['2022-10-05', 101.74],
                ['2022-10-06', 101.36],
                ['2022-10-07', 100.58],
                ['2022-10-10', 100.18],
                ['2022-10-11', 100.08],
                ['2022-10-12', 100.00],
                ['2022-10-13', 100.30],
                ['2022-10-14', 99.68],
                ['2022-10-17', 100.15],
                ['2022-10-18', 100.41],
                ['2022-10-19', 99.91],
                ['2022-10-20', 99.64],
                ['2022-10-21', 100.26],
                ['2022-10-24', 100.47],
                ['2022-10-25', 101.01],
                ['2022-10-26', 101.17],
                ['2022-10-27', 101.16],
                ['2022-10-28', 101.59],
                ['2022-10-31', 101.41],
                ['2022-11-01', 101.56],
                ['2022-11-02', 100.86],
                ['2022-11-03', 100.49],
                ['2022-11-04', 101.16],
                ['2022-11-07', 101.35],
                ['2022-11-08', 101.69],
                ['2022-11-09', 101.28],
                ['2022-11-10', 103.05],
                ['2022-11-11', 103.15],
                ['2022-11-14', 103.02],
                ['2022-11-15', 103.41],
                ['2022-11-16', 103.35],
                ['2022-11-17', 103.11],
                ['2022-11-18', 103.21],
                ['2022-11-21', 103.19],
                ['2022-11-22', 103.23],
                ['2022-11-23', 103.53],
                ['2022-11-25', 103.54],
                ['2022-11-28', 103.16],
                ['2022-11-29', 103.13],
                ['2022-11-30', 104.19],
                ['2022-12-01', 104.61],
                ['2022-12-02', 104.60],
                ['2022-12-05', 103.93],
                ['2022-12-06', 103.81],
                ['2022-12-07', 104.15],
                ['2022-12-08', 104.32],
                ['2022-12-09', 104.08],
                ['2022-12-12', 104.31],
                ['2022-12-13', 104.62],
                ['2022-12-14', 104.54],
                ['2022-12-15', 103.81],
                ['2022-12-16', 103.60],
                ['2022-12-19', 103.40],
                ['2022-12-20', 103.49],
                ['2022-12-21', 103.82],
                ['2022-12-22', 103.42],
                ['2022-12-23', 103.47],
                ['2022-12-27', 103.44],
                ['2022-12-28', 103.12],
                ['2022-12-29', 103.58],
                ['2022-12-30', 103.53],
                ['2023-01-03', 103.71],
                ['2023-01-04', 104.12],
                ['2023-01-05', 103.70],
                ['2023-01-06', 104.61],
                ['2023-01-09', 104.56],
                ['2023-01-10', 104.69],
                ['2023-01-11', 104.91],
                ['2023-01-12', 105.16],
                ['2023-01-13', 105.28],
                ['2023-01-17', 105.05],
                ['2023-01-18', 104.86],
                ['2023-01-19', 104.82],
                ['2023-01-20', 105.00],
                ['2023-01-23', 105.24],
                ['2023-01-24', 105.23],
                ['2023-01-25', 105.23],
                ['2023-01-26', 105.13],
                ['2023-01-27', 104.99],
                ['2023-01-30', 104.69],
                ['2023-01-31', 105.14],
                ['2023-02-01', 105.75],
                ['2023-02-02', 105.60],
                ['2023-02-03', 104.89],
                ['2023-02-06', 104.62],
                ['2023-02-07', 104.89],
                ['2023-02-08', 104.71],
                ['2023-02-09', 104.40],
                ['2023-02-10', 104.46],
                ['2023-02-13', 104.66],
                ['2023-02-14', 104.49],
                ['2023-02-15', 104.39],
                ['2023-02-16', 104.14],
                ['2023-02-17', 104.30],
                ['2023-02-21', 103.76],
                ['2023-02-22', 103.70],
                ['2023-02-23', 103.84],
                ['2023-02-24', 103.43],
                ['2023-02-27', 103.49],
                ['2023-02-28', 103.51],
                ['2023-03-01', 103.29],
                ['2023-03-02', 103.40],
                ['2023-03-03', 103.90],
                ['2023-03-06', 103.69],
                ['2023-03-07', 103.21],
                ['2023-03-08', 103.26],
                ['2023-03-09', 103.19],
                ['2023-03-10', 103.48],
                ['2023-03-13', 103.96],
                ['2023-03-14', 104.04],
                ['2023-03-15', 104.29],
                ['2023-03-16', 104.47],
                ['2023-03-17', 104.75],
                ['2023-03-20', 104.92],
                ['2023-03-21', 104.61],
                ['2023-03-22', 104.71],
                ['2023-03-23', 104.94],
                ['2023-03-24', 105.02],
                ['2023-03-27', 104.80],
                ['2023-03-28', 104.86],
                ['2023-03-29', 105.13],
                ['2023-03-30', 105.44],
                ['2023-03-31', 105.72],
                ['2023-04-03', 105.74],
                ['2023-04-04', 105.75],
                ['2023-04-05', 105.76],
                ['2023-04-06', 105.78],
                ['2023-04-10', 105.79],
                ['2023-04-11', 105.81],
                ['2023-04-12', 105.82],
                ['2023-04-13', 105.83],
                ['2023-04-14', 105.85],
                ['2023-04-17', 105.86],
                ['2023-04-18', 105.88],
                ['2023-04-19', 105.89],
                ['2023-04-20', 105.90],
                ['2023-04-21', 105.92],
                ['2023-04-24', 105.93],
                ['2023-04-25', 105.94],
                ['2023-04-26', 105.96],
                ['2023-04-27', 105.97],
                ['2023-04-28', 105.98],
                ['2023-05-01', 105.86],
                ['2023-05-02', 106.10],
                ['2023-05-03', 106.20],
                ['2023-05-04', 106.20],
                ['2023-05-05', 106.26],
                ['2023-05-08', 106.14],
                ['2023-05-09', 106.12],
                ['2023-05-10', 106.32],
                ['2023-05-11', 106.20],
                ['2023-05-12', 106.16],
                ['2023-05-15', 106.28],
                ['2023-05-16', 105.73],
                ['2023-05-17', 105.75],
                ['2023-05-18', 105.63],
                ['2023-05-19', 105.70],
                ['2023-05-22', 105.63],
                ['2023-05-23', 105.40],
                ['2023-05-24', 105.08],
                ['2023-05-25', 104.96],
                ['2023-05-26', 105.28],
                ['2023-05-30', 105.40],
                ['2023-05-31', 105.59],
                ['2023-06-01', 105.80],
                ['2023-06-02', 105.73],
                ['2023-06-05', 105.70],
                ['2023-06-06', 105.67],
                ['2023-06-07', 105.34],
                ['2023-06-08', 105.68],
                ['2023-06-09', 105.60],
                ['2023-06-12', 105.83],
                ['2023-06-13', 105.85],
                ['2023-06-14', 105.95],
                ['2023-06-15', 106.42],
                ['2023-06-16', 106.30],
                ['2023-06-20', 106.03],
                ['2023-06-21', 105.95],
                ['2023-06-22', 105.76],
                ['2023-06-23', 105.63],
                ['2023-06-26', 105.75],
                ['2023-06-27', 105.84],
                ['2023-06-28', 105.74],
                ['2023-06-29', 105.70],
                ['2023-06-30', 106.15],
                ['2023-07-03', 106.06],
                ['2023-07-05', 105.84],
                ['2023-07-06', 105.55],
                ['2023-07-07', 105.49],
                ['2023-07-10', 105.75],
                ['2023-07-11', 105.96],
                ['2023-07-12', 106.36],
                ['2023-07-13', 106.57],
                ['2023-07-14', 106.41],
                ['2023-07-17', 106.51],
                ['2023-07-18', 106.67],
                ['2023-07-19', 106.74],
                ['2023-07-20', 106.60],
                ['2023-07-21', 106.69],
                ['2023-07-24', 106.62],
                ['2023-07-25', 106.71],
                ['2023-07-26', 106.75],
                ['2023-07-27', 106.27],
                ['2023-07-28', 106.49],
                ['2023-07-31', 106.60],
                ['2023-08-01', 106.25],
                ['2023-08-02', 106.03],
                ['2023-08-03', 105.81],
                ['2023-08-04', 105.89],
                ['2023-08-07', 106.05],
                ['2023-08-08', 105.95],
                ['2023-08-09', 105.90],
                ['2023-08-10', 105.77],
                ['2023-08-11', 105.69],
                ['2023-08-14', 105.70],
                ['2023-08-15', 105.42],
                ['2023-08-16', 105.16],
                ['2023-08-17', 104.92],
                ['2023-08-18', 105.03],
                ['2023-08-21', 105.06],
                ['2023-08-22', 105.05],
                ['2023-08-23', 105.51],
                ['2023-08-24', 105.19],
                ['2023-08-25', 105.33],
                ['2023-08-28', 105.55],
                ['2023-08-29', 106.02],
                ['2023-08-30', 106.06],
                ['2023-08-31', 105.96],
            ]
        );
    }
    if (document.getElementById('apexcharts-ChartIvyDynamic')) {
        loadApexChart(
            'ChartIvyDynamic',
            'ivy dynamic',
            '#bd5574',
            '#bd5574',
            '#953583',
            'smooth',
            '2021-03-01',
            [
                ['2006-12-31', -0.01],
                ['2007-01-01', -0.01],
                ['2007-01-02', -0.01],
                ['2007-01-03', -0.01],
                ['2007-01-04', 0.04],
                ['2007-01-05', -0.70],
                ['2007-01-08', -0.44],
                ['2007-01-09', -0.26],
                ['2007-01-10', -0.11],
                ['2007-01-11', 0.14],
                ['2007-01-12', 0.67],
                ['2007-01-16', 0.65],
                ['2007-01-17', 0.82],
                ['2007-01-18', 0.34],
                ['2007-01-19', 0.68],
                ['2007-01-22', 0.36],
                ['2007-01-23', 0.79],
                ['2007-01-24', 1.13],
                ['2007-01-25', 0.77],
                ['2007-01-26', 0.87],
                ['2007-01-29', 0.79],
                ['2007-01-30', 1.11],
                ['2007-01-31', 1.46],
                ['2007-02-01', 1.75],
                ['2007-02-02', 1.67],
                ['2007-02-05', 1.71],
                ['2007-02-06', 1.85],
                ['2007-02-07', 2.07],
                ['2007-02-08', 2.28],
                ['2007-02-09', 2.08],
                ['2007-02-12', 1.71],
                ['2007-02-13', 1.98],
                ['2007-02-14', 2.46],
                ['2007-02-15', 2.57],
                ['2007-02-16', 2.55],
                ['2007-02-20', 2.41],
                ['2007-02-21', 2.77],
                ['2007-02-22', 2.87],
                ['2007-02-23', 3.00],
                ['2007-02-26', 3.00],
                ['2007-02-27', 1.15],
                ['2007-02-28', 1.53],
                ['2007-03-01', 1.20],
                ['2007-03-02', 0.19],
                ['2007-03-05', -0.47],
                ['2007-03-06', 0.50],
                ['2007-03-07', 0.40],
                ['2007-03-08', 0.76],
                ['2007-03-09', 0.80],
                ['2007-03-12', 1.01],
                ['2007-03-13', 0.15],
                ['2007-03-14', 0.41],
                ['2007-03-15', 0.53],
                ['2007-03-16', 0.50],
                ['2007-03-19', 0.79],
                ['2007-03-20', 1.19],
                ['2007-03-21', 1.89],
                ['2007-03-22', 1.76],
                ['2007-03-23', 1.72],
                ['2007-03-26', 1.76],
                ['2007-03-27', 1.49],
                ['2007-03-28', 1.28],
                ['2007-03-29', 1.14],
                ['2007-03-30', 1.18],
                ['2007-04-02', 1.26],
                ['2007-04-03', 1.59],
                ['2007-04-04', 1.93],
                ['2007-04-05', 2.10],
                ['2007-04-09', 2.01],
                ['2007-04-10', 2.29],
                ['2007-04-11', 1.98],
                ['2007-04-12', 2.22],
                ['2007-04-13', 2.50],
                ['2007-04-16', 4.53],
                ['2007-04-17', 4.61],
                ['2007-04-18', 4.87],
                ['2007-04-19', 4.60],
                ['2007-04-20', 5.20],
                ['2007-04-23', 5.02],
                ['2007-04-24', 5.08],
                ['2007-04-25', 5.36],
                ['2007-04-26', 5.10],
                ['2007-04-27', 5.09],
                ['2007-04-30', 4.61],
                ['2007-05-01', 4.64],
                ['2007-05-02', 4.93],
                ['2007-05-03', 5.24],
                ['2007-05-04', 5.51],
                ['2007-05-07', 5.63],
                ['2007-05-08', 5.40],
                ['2007-05-09', 5.51],
                ['2007-05-10', 4.59],
                ['2007-05-11', 5.06],
                ['2007-05-14', 4.94],
                ['2007-05-15', 4.81],
                ['2007-05-16', 4.78],
                ['2007-05-17', 4.43],
                ['2007-05-18', 4.68],
                ['2007-05-21', 4.74],
                ['2007-05-22', 4.68],
                ['2007-05-23', 4.63],
                ['2007-05-24', 4.02],
                ['2007-05-25', 4.23],
                ['2007-05-29', 4.39],
                ['2007-05-30', 4.41],
                ['2007-05-31', 4.80],
                ['2007-06-01', 5.18],
                ['2007-06-04', 5.24],
                ['2007-06-05', 4.90],
                ['2007-06-06', 4.57],
                ['2007-06-07', 3.48],
                ['2007-06-08', 3.68],
                ['2007-06-11', 3.80],
                ['2007-06-12', 3.20],
                ['2007-06-13', 3.87],
                ['2007-06-14', 4.08],
                ['2007-06-15', 4.57],
                ['2007-06-18', 4.52],
                ['2007-06-19', 4.62],
                ['2007-06-20', 3.89],
                ['2007-06-21', 4.22],
                ['2007-06-22', 3.86],
                ['2007-06-25', 3.47],
                ['2007-06-26', 3.26],
                ['2007-06-27', 3.76],
                ['2007-06-28', 3.82],
                ['2007-06-29', 3.72],
                ['2007-07-02', 4.40],
                ['2007-07-03', 4.40],
                ['2007-07-05', 4.40],
                ['2007-07-06', 4.73],
                ['2007-07-09', 4.93],
                ['2007-07-10', 4.52],
                ['2007-07-11', 4.56],
                ['2007-07-12', 5.45],
                ['2007-07-13', 5.57],
                ['2007-07-16', 5.53],
                ['2007-07-17', 5.63],
                ['2007-07-18', 5.71],
                ['2007-07-19', 5.99],
                ['2007-07-20', 5.78],
                ['2007-07-23', 5.87],
                ['2007-07-24', 5.13],
                ['2007-07-25', 5.29],
                ['2007-07-26', 4.14],
                ['2007-07-27', 3.54],
                ['2007-07-30', 4.05],
                ['2007-07-31', 3.61],
                ['2007-08-01', 3.96],
                ['2007-08-02', 4.12],
                ['2007-08-03', 3.46],
                ['2007-08-06', 4.30],
                ['2007-08-07', 4.44],
                ['2007-08-08', 5.09],
                ['2007-08-09', 4.09],
                ['2007-08-10', 4.15],
                ['2007-08-13', 3.95],
                ['2007-08-14', 3.41],
                ['2007-08-15', 2.74],
                ['2007-08-16', 2.40],
                ['2007-08-17', 3.29],
                ['2007-08-20', 3.40],
                ['2007-08-21', 3.49],
                ['2007-08-22', 3.90],
                ['2007-08-23', 3.73],
                ['2007-08-24', 4.38],
                ['2007-08-27', 4.01],
                ['2007-08-28', 3.06],
                ['2007-08-29', 3.98],
                ['2007-08-30', 3.84],
                ['2007-08-31', 4.48],
                ['2007-09-04', 5.14],
                ['2007-09-05', 4.81],
                ['2007-09-06', 5.42],
                ['2007-09-07', 5.01],
                ['2007-09-10', 5.12],
                ['2007-09-11', 5.83],
                ['2007-09-12', 5.70],
                ['2007-09-13', 5.73],
                ['2007-09-14', 5.69],
                ['2007-09-17', 5.69],
                ['2007-09-18', 6.92],
                ['2007-09-19', 7.18],
                ['2007-09-20', 7.19],
                ['2007-09-21', 7.24],
                ['2007-09-24', 7.10],
                ['2007-09-25', 7.11],
                ['2007-09-26', 7.14],
                ['2007-09-27', 7.46],
                ['2007-09-28', 7.46],
                ['2007-10-01', 8.09],
                ['2007-10-02', 7.74],
                ['2007-10-03', 7.29],
                ['2007-10-04', 7.57],
                ['2007-10-05', 8.16],
                ['2007-10-08', 7.94],
                ['2007-10-09', 8.27],
                ['2007-10-10', 8.24],
                ['2007-10-11', 8.01],
                ['2007-10-12', 8.37],
                ['2007-10-15', 8.09],
                ['2007-10-16', 7.92],
                ['2007-10-17', 7.96],
                ['2007-10-18', 8.35],
                ['2007-10-19', 7.32],
                ['2007-10-22', 7.24],
                ['2007-10-23', 7.67],
                ['2007-10-24', 7.46],
                ['2007-10-25', 7.29],
                ['2007-10-26', 7.91],
                ['2007-10-29', 8.17],
                ['2007-10-30', 7.91],
                ['2007-10-31', 8.61],
                ['2007-11-01', 7.55],
                ['2007-11-02', 8.05],
                ['2007-11-05', 7.87],
                ['2007-11-06', 8.60],
                ['2007-11-07', 7.74],
                ['2007-11-08', 7.81],
                ['2007-11-09', 7.41],
                ['2007-11-12', 6.12],
                ['2007-11-13', 7.19],
                ['2007-11-14', 7.14],
                ['2007-11-15', 6.34],
                ['2007-11-16', 6.30],
                ['2007-11-19', 5.70],
                ['2007-11-20', 6.15],
                ['2007-11-21', 5.50],
                ['2007-11-23', 6.46],
                ['2007-11-26', 5.69],
                ['2007-11-27', 6.07],
                ['2007-11-28', 7.13],
                ['2007-11-29', 6.73],
                ['2007-11-30', 6.68],
                ['2007-12-03', 6.78],
                ['2007-12-04', 6.80],
                ['2007-12-05', 7.32],
                ['2007-12-06', 7.97],
                ['2007-12-07', 7.78],
                ['2007-12-10', 8.41],
                ['2007-12-11', 7.23],
                ['2007-12-12', 7.77],
                ['2007-12-13', 7.32],
                ['2007-12-14', 6.81],
                ['2007-12-17', 6.07],
                ['2007-12-18', 6.58],
                ['2007-12-19', 6.58],
                ['2007-12-20', 6.76],
                ['2007-12-21', 7.67],
                ['2007-12-24', 7.94],
                ['2007-12-26', 8.24],
                ['2007-12-27', 7.68],
                ['2007-12-28', 8.02],
                ['2007-12-31', 7.64],
                ['2008-01-02', 7.51],
                ['2008-01-03', 7.60],
                ['2008-01-04', 6.61],
                ['2008-01-07', 6.66],
                ['2008-01-08', 6.41],
                ['2008-01-09', 6.77],
                ['2008-01-10', 7.25],
                ['2008-01-11', 6.83],
                ['2008-01-14', 7.36],
                ['2008-01-15', 6.13],
                ['2008-01-16', 5.71],
                ['2008-01-17', 4.83],
                ['2008-01-18', 4.88],
                ['2008-01-22', 4.48],
                ['2008-01-23', 5.15],
                ['2008-01-24', 5.79],
                ['2008-01-25', 5.36],
                ['2008-01-28', 6.25],
                ['2008-01-29', 6.36],
                ['2008-01-30', 6.21],
                ['2008-01-31', 6.61],
                ['2008-02-01', 7.19],
                ['2008-02-04', 6.71],
                ['2008-02-05', 5.29],
                ['2008-02-06', 5.06],
                ['2008-02-07', 5.55],
                ['2008-02-08', 5.74],
                ['2008-02-11', 6.15],
                ['2008-02-12', 6.01],
                ['2008-02-13', 6.68],
                ['2008-02-14', 5.98],
                ['2008-02-15', 5.78],
                ['2008-02-19', 6.09],
                ['2008-02-20', 6.71],
                ['2008-02-21', 6.29],
                ['2008-02-22', 6.56],
                ['2008-02-25', 7.01],
                ['2008-02-26', 7.51],
                ['2008-02-27', 7.71],
                ['2008-02-28', 7.50],
                ['2008-02-29', 6.60],
                ['2008-03-03', 6.74],
                ['2008-03-04', 6.33],
                ['2008-03-05', 7.01],
                ['2008-03-06', 5.86],
                ['2008-03-07', 5.45],
                ['2008-03-10', 4.80],
                ['2008-03-11', 5.93],
                ['2008-03-12', 5.99],
                ['2008-03-13', 6.63],
                ['2008-03-14', 5.89],
                ['2008-03-17', 5.66],
                ['2008-03-18', 6.59],
                ['2008-03-19', 5.11],
                ['2008-03-20', 5.26],
                ['2008-03-24', 5.94],
                ['2008-03-25', 6.48],
                ['2008-03-26', 6.35],
                ['2008-03-27', 5.85],
                ['2008-03-28', 5.17],
                ['2008-03-31', 5.15],
                ['2008-04-01', 5.82],
                ['2008-04-02', 6.35],
                ['2008-04-03', 6.49],
                ['2008-04-04', 6.95],
                ['2008-04-07', 7.05],
                ['2008-04-08', 6.67],
                ['2008-04-09', 6.95],
                ['2008-04-10', 7.11],
                ['2008-04-11', 6.15],
                ['2008-04-14', 5.77],
                ['2008-04-15', 5.89],
                ['2008-04-16', 7.18],
                ['2008-04-17', 6.91],
                ['2008-04-18', 7.00],
                ['2008-04-21', 6.99],
                ['2008-04-22', 6.41],
                ['2008-04-23', 6.68],
                ['2008-04-24', 6.42],
                ['2008-04-25', 6.60],
                ['2008-04-28', 6.81],
                ['2008-04-29', 6.44],
                ['2008-04-30', 6.35],
                ['2008-05-01', 6.64],
                ['2008-05-02', 6.72],
                ['2008-05-05', 6.87],
                ['2008-05-06', 7.29],
                ['2008-05-07', 6.58],
                ['2008-05-08', 7.04],
                ['2008-05-09', 6.95],
                ['2008-05-12', 7.25],
                ['2008-05-13', 6.90],
                ['2008-05-14', 7.12],
                ['2008-05-15', 7.89],
                ['2008-05-16', 8.73],
                ['2008-05-19', 8.80],
                ['2008-05-20', 8.77],
                ['2008-05-21', 8.44],
                ['2008-05-22', 8.37],
                ['2008-05-23', 8.05],
                ['2008-05-27', 8.00],
                ['2008-05-28', 7.92],
                ['2008-05-29', 7.58],
                ['2008-05-30', 7.98],
                ['2008-06-02', 7.71],
                ['2008-06-03', 7.33],
                ['2008-06-04', 7.49],
                ['2008-06-05', 7.94],
                ['2008-06-06', 7.53],
                ['2008-06-09', 7.03],
                ['2008-06-10', 6.25],
                ['2008-06-11', 5.77],
                ['2008-06-12', 5.68],
                ['2008-06-13', 6.29],
                ['2008-06-16', 6.71],
                ['2008-06-17', 6.49],
                ['2008-06-18', 6.26],
                ['2008-06-19', 6.66],
                ['2008-06-20', 6.10],
                ['2008-06-23', 5.59],
                ['2008-06-24', 5.61],
                ['2008-06-25', 6.02],
                ['2008-06-26', 5.53],
                ['2008-06-27', 5.56],
                ['2008-06-30', 5.53],
                ['2008-07-01', 5.80],
                ['2008-07-02', 5.34],
                ['2008-07-03', 4.82],
                ['2008-07-07', 4.68],
                ['2008-07-08', 5.40],
                ['2008-07-09', 4.74],
                ['2008-07-10', 5.51],
                ['2008-07-11', 5.55],
                ['2008-07-14', 5.22],
                ['2008-07-15', 5.34],
                ['2008-07-16', 6.00],
                ['2008-07-17', 6.40],
                ['2008-07-18', 6.29],
                ['2008-07-21', 6.30],
                ['2008-07-22', 6.25],
                ['2008-07-23', 5.92],
                ['2008-07-24', 5.23],
                ['2008-07-25', 5.37],
                ['2008-07-28', 4.86],
                ['2008-07-29', 5.40],
                ['2008-07-30', 5.75],
                ['2008-07-31', 5.81],
                ['2008-08-01', 5.48],
                ['2008-08-04', 5.16],
                ['2008-08-05', 5.81],
                ['2008-08-06', 6.20],
                ['2008-08-07', 5.85],
                ['2008-08-08', 6.32],
                ['2008-08-11', 5.98],
                ['2008-08-12', 5.58],
                ['2008-08-13', 5.78],
                ['2008-08-14', 5.57],
                ['2008-08-15', 5.48],
                ['2008-08-18', 5.28],
                ['2008-08-19', 5.23],
                ['2008-08-20', 5.25],
                ['2008-08-21', 5.72],
                ['2008-08-22', 5.85],
                ['2008-08-25', 5.20],
                ['2008-08-26', 5.25],
                ['2008-08-27', 5.58],
                ['2008-08-28', 6.19],
                ['2008-08-29', 5.57],
                ['2008-09-02', 4.91],
                ['2008-09-03', 4.54],
                ['2008-09-04', 3.40],
                ['2008-09-05', 3.82],
                ['2008-09-08', 4.39],
                ['2008-09-09', 2.85],
                ['2008-09-10', 2.45],
                ['2008-09-11', 2.64],
                ['2008-09-12', 3.30],
                ['2008-09-15', 2.39],
                ['2008-09-16', 2.59],
                ['2008-09-17', 2.97],
                ['2008-09-18', 3.95],
                ['2008-09-19', 5.43],
                ['2008-09-22', 4.61],
                ['2008-09-23', 3.91],
                ['2008-09-24', 3.60],
                ['2008-09-25', 4.01],
                ['2008-09-26', 4.21],
                ['2008-09-29', 1.93],
                ['2008-09-30', 2.48],
                ['2008-10-01', 2.54],
                ['2008-10-02', 0.52],
                ['2008-10-03', 0.32],
                ['2008-10-06', -1.09],
                ['2008-10-07', -2.36],
                ['2008-10-08', -2.79],
                ['2008-10-09', -5.17],
                ['2008-10-10', -7.47],
                ['2008-10-13', -4.23],
                ['2008-10-14', -4.55],
                ['2008-10-15', -7.31],
                ['2008-10-16', -6.85],
                ['2008-10-17', -7.58],
                ['2008-10-20', -6.27],
                ['2008-10-21', -7.92],
                ['2008-10-22', -10.81],
                ['2008-10-23', -11.78],
                ['2008-10-24', -12.41],
                ['2008-10-27', -13.64],
                ['2008-10-28', -10.54],
                ['2008-10-29', -10.68],
                ['2008-10-30', -9.56],
                ['2008-10-31', -9.23],
                ['2008-11-03', -9.10],
                ['2008-11-04', -6.94],
                ['2008-11-05', -8.75],
                ['2008-11-06', -10.42],
                ['2008-11-07', -9.52],
                ['2008-11-10', -9.75],
                ['2008-11-11', -10.72],
                ['2008-11-12', -12.93],
                ['2008-11-13', -10.52],
                ['2008-11-14', -11.83],
                ['2008-11-17', -12.74],
                ['2008-11-18', -12.92],
                ['2008-11-19', -14.95],
                ['2008-11-20', -16.61],
                ['2008-11-21', -13.99],
                ['2008-11-24', -11.93],
                ['2008-11-25', -11.71],
                ['2008-11-26', -10.50],
                ['2008-11-28', -10.10],
                ['2008-12-01', -13.57],
                ['2008-12-02', -12.34],
                ['2008-12-03', -11.37],
                ['2008-12-04', -12.52],
                ['2008-12-05', -11.48],
                ['2008-12-08', -10.31],
                ['2008-12-09', -10.47],
                ['2008-12-10', -9.46],
                ['2008-12-11', -9.83],
                ['2008-12-12', -9.37],
                ['2008-12-15', -9.62],
                ['2008-12-16', -7.47],
                ['2008-12-17', -7.30],
                ['2008-12-18', -8.21],
                ['2008-12-19', -8.32],
                ['2008-12-22', -8.96],
                ['2008-12-23', -9.63],
                ['2008-12-24', -9.25],
                ['2008-12-26', -8.78],
                ['2008-12-29', -8.92],
                ['2008-12-30', -8.02],
                ['2008-12-31', -7.22],
                ['2009-01-02', -6.31],
                ['2009-01-05', -6.87],
                ['2009-01-06', -6.27],
                ['2009-01-07', -7.74],
                ['2009-01-08', -7.48],
                ['2009-01-09', -8.08],
                ['2009-01-12', -9.09],
                ['2009-01-13', -8.75],
                ['2009-01-14', -9.78],
                ['2009-01-15', -9.38],
                ['2009-01-16', -8.45],
                ['2009-01-20', -10.00],
                ['2009-01-21', -9.01],
                ['2009-01-22', -9.50],
                ['2009-01-23', -8.77],
                ['2009-01-26', -8.50],
                ['2009-01-27', -7.85],
                ['2009-01-28', -7.05],
                ['2009-01-29', -7.74],
                ['2009-01-30', -8.02],
                ['2009-02-02', -8.27],
                ['2009-02-03', -7.97],
                ['2009-02-04', -7.87],
                ['2009-02-05', -7.04],
                ['2009-02-06', -6.21],
                ['2009-02-09', -6.51],
                ['2009-02-10', -7.63],
                ['2009-02-11', -7.16],
                ['2009-02-12', -6.87],
                ['2009-02-13', -7.10],
                ['2009-02-17', -8.31],
                ['2009-02-18', -8.30],
                ['2009-02-19', -9.07],
                ['2009-02-20', -9.15],
                ['2009-02-23', -10.12],
                ['2009-02-24', -9.29],
                ['2009-02-25', -9.76],
                ['2009-02-26', -10.48],
                ['2009-02-27', -11.35],
                ['2009-03-02', -13.26],
                ['2009-03-03', -13.60],
                ['2009-03-04', -12.97],
                ['2009-03-05', -13.63],
                ['2009-03-06', -13.67],
                ['2009-03-09', -14.34],
                ['2009-03-10', -12.88],
                ['2009-03-11', -12.56],
                ['2009-03-12', -10.89],
                ['2009-03-13', -10.30],
                ['2009-03-16', -10.73],
                ['2009-03-17', -9.86],
                ['2009-03-18', -8.49],
                ['2009-03-19', -8.79],
                ['2009-03-20', -9.70],
                ['2009-03-23', -7.83],
                ['2009-03-24', -8.65],
                ['2009-03-25', -8.10],
                ['2009-03-26', -6.99],
                ['2009-03-27', -7.63],
                ['2009-03-30', -8.87],
                ['2009-03-31', -8.50],
                ['2009-04-01', -8.09],
                ['2009-04-02', -7.51],
                ['2009-04-03', -7.45],
                ['2009-04-06', -8.05],
                ['2009-04-07', -8.53],
                ['2009-04-08', -7.88],
                ['2009-04-09', -6.61],
                ['2009-04-13', -6.30],
                ['2009-04-14', -6.80],
                ['2009-04-15', -6.59],
                ['2009-04-16', -6.13],
                ['2009-04-17', -5.93],
                ['2009-04-20', -6.92],
                ['2009-04-21', -6.61],
                ['2009-04-22', -6.61],
                ['2009-04-23', -6.44],
                ['2009-04-24', -5.85],
                ['2009-04-27', -6.14],
                ['2009-04-28', -6.39],
                ['2009-04-29', -5.57],
                ['2009-04-30', -5.53],
                ['2009-05-01', -5.51],
                ['2009-05-04', -3.94],
                ['2009-05-05', -4.09],
                ['2009-05-06', -3.57],
                ['2009-05-07', -3.98],
                ['2009-05-08', -3.49],
                ['2009-05-11', -3.98],
                ['2009-05-12', -3.98],
                ['2009-05-13', -4.83],
                ['2009-05-14', -4.40],
                ['2009-05-15', -4.74],
                ['2009-05-18', -4.05],
                ['2009-05-19', -3.80],
                ['2009-05-20', -3.54],
                ['2009-05-21', -3.87],
                ['2009-05-22', -3.85],
                ['2009-05-26', -2.94],
                ['2009-05-27', -3.41],
                ['2009-05-28', -2.94],
                ['2009-05-29', -2.05],
                ['2009-06-01', -1.17],
                ['2009-06-02', -1.02],
                ['2009-06-03', -1.73],
                ['2009-06-04', -1.25],
                ['2009-06-05', -1.87],
                ['2009-06-08', -1.98],
                ['2009-06-09', -1.40],
                ['2009-06-10', -1.48],
                ['2009-06-11', -1.22],
                ['2009-06-12', -1.46],
                ['2009-06-15', -2.45],
                ['2009-06-16', -2.68],
                ['2009-06-17', -2.27],
                ['2009-06-18', -2.29],
                ['2009-06-19', -2.04],
                ['2009-06-22', -3.23],
                ['2009-06-23', -3.23],
                ['2009-06-24', -2.83],
                ['2009-06-25', -1.79],
                ['2009-06-26', -1.78],
                ['2009-06-29', -1.63],
                ['2009-06-30', -1.67],
                ['2009-07-01', -1.25],
                ['2009-07-02', -2.23],
                ['2009-07-06', -2.34],
                ['2009-07-07', -3.01],
                ['2009-07-08', -3.38],
                ['2009-07-09', -3.22],
                ['2009-07-10', -3.19],
                ['2009-07-13', -2.26],
                ['2009-07-14', -1.92],
                ['2009-07-15', -0.71],
                ['2009-07-16', -0.26],
                ['2009-07-17', -0.35],
                ['2009-07-20', 0.26],
                ['2009-07-21', 0.34],
                ['2009-07-22', 0.53],
                ['2009-07-23', 1.34],
                ['2009-07-24', 1.56],
                ['2009-07-27', 1.79],
                ['2009-07-28', 1.58],
                ['2009-07-29', 1.46],
                ['2009-07-30', 1.73],
                ['2009-07-31', 2.22],
                ['2009-08-03', 2.76],
                ['2009-08-04', 3.06],
                ['2009-08-05', 2.95],
                ['2009-08-06', 2.63],
                ['2009-08-07', 2.83],
                ['2009-08-10', 2.67],
                ['2009-08-11', 2.27],
                ['2009-08-12', 2.72],
                ['2009-08-13', 3.23],
                ['2009-08-14', 2.81],
                ['2009-08-17', 1.80],
                ['2009-08-18', 2.21],
                ['2009-08-19', 2.65],
                ['2009-08-20', 2.95],
                ['2009-08-21', 3.81],
                ['2009-08-24', 3.54],
                ['2009-08-25', 3.75],
                ['2009-08-26', 3.83],
                ['2009-08-27', 4.05],
                ['2009-08-28', 4.38],
                ['2009-08-31', 4.03],
                ['2009-09-01', 3.32],
                ['2009-09-02', 3.62],
                ['2009-09-03', 4.19],
                ['2009-09-04', 4.79],
                ['2009-09-08', 5.08],
                ['2009-09-09', 5.50],
                ['2009-09-10', 6.06],
                ['2009-09-11', 6.08],
                ['2009-09-14', 6.13],
                ['2009-09-15', 6.43],
                ['2009-09-16', 6.92],
                ['2009-09-17', 6.62],
                ['2009-09-18', 6.56],
                ['2009-09-21', 6.54],
                ['2009-09-22', 7.00],
                ['2009-09-23', 6.66],
                ['2009-09-24', 5.96],
                ['2009-09-25', 5.80],
                ['2009-09-28', 6.48],
                ['2009-09-29', 6.38],
                ['2009-09-30', 6.68],
                ['2009-10-01', 5.36],
                ['2009-10-02', 5.24],
                ['2009-10-05', 6.01],
                ['2009-10-06', 6.83],
                ['2009-10-07', 7.03],
                ['2009-10-08', 7.35],
                ['2009-10-09', 7.80],
                ['2009-10-12', 8.12],
                ['2009-10-13', 8.17],
                ['2009-10-14', 8.77],
                ['2009-10-15', 8.50],
                ['2009-10-16', 8.20],
                ['2009-10-19', 8.84],
                ['2009-10-20', 8.39],
                ['2009-10-21', 8.06],
                ['2009-10-22', 8.53],
                ['2009-10-23', 7.79],
                ['2009-10-26', 7.12],
                ['2009-10-27', 6.88],
                ['2009-10-28', 5.80],
                ['2009-10-29', 6.80],
                ['2009-10-30', 5.41],
                ['2009-11-02', 5.80],
                ['2009-11-03', 6.11],
                ['2009-11-04', 6.35],
                ['2009-11-05', 7.27],
                ['2009-11-06', 7.39],
                ['2009-11-09', 8.46],
                ['2009-11-10', 8.52],
                ['2009-11-11', 9.06],
                ['2009-11-12', 8.48],
                ['2009-11-13', 8.92],
                ['2009-11-16', 9.91],
                ['2009-11-17', 10.01],
                ['2009-11-18', 9.89],
                ['2009-11-19', 9.25],
                ['2009-11-20', 9.19],
                ['2009-11-23', 9.87],
                ['2009-11-24', 10.22],
                ['2009-11-25', 10.63],
                ['2009-11-27', 10.07],
                ['2009-11-30', 10.22],
                ['2009-12-01', 11.04],
                ['2009-12-02', 11.58],
                ['2009-12-03', 11.38],
                ['2009-12-04', 11.03],
                ['2009-12-07', 11.09],
                ['2009-12-08', 10.54],
                ['2009-12-09', 10.59],
                ['2009-12-10', 10.80],
                ['2009-12-11', 10.61],
                ['2009-12-14', 11.14],
                ['2009-12-15', 11.00],
                ['2009-12-16', 11.26],
                ['2009-12-17', 10.32],
                ['2009-12-18', 10.66],
                ['2009-12-21', 10.88],
                ['2009-12-22', 10.89],
                ['2009-12-23', 11.09],
                ['2009-12-24', 11.52],
                ['2009-12-28', 11.50],
                ['2009-12-29', 11.34],
                ['2009-12-30', 11.41],
                ['2009-12-31', 10.84],
                ['2010-01-04', 11.60],
                ['2010-01-05', 11.50],
                ['2010-01-06', 11.73],
                ['2010-01-07', 11.69],
                ['2010-01-08', 11.94],
                ['2010-01-11', 12.31],
                ['2010-01-12', 11.48],
                ['2010-01-13', 11.94],
                ['2010-01-14', 12.09],
                ['2010-01-15', 11.37],
                ['2010-01-19', 12.09],
                ['2010-01-20', 11.41],
                ['2010-01-21', 10.75],
                ['2010-01-22', 9.87],
                ['2010-01-25', 10.09],
                ['2010-01-26', 10.04],
                ['2010-01-27', 10.01],
                ['2010-01-28', 9.60],
                ['2010-01-29', 9.09],
                ['2010-02-01', 9.95],
                ['2010-02-02', 10.49],
                ['2010-02-03', 10.26],
                ['2010-02-04', 8.44],
                ['2010-02-05', 8.78],
                ['2010-02-08', 8.51],
                ['2010-02-09', 9.13],
                ['2010-02-10', 9.03],
                ['2010-02-11', 9.73],
                ['2010-02-12', 9.81],
                ['2010-02-16', 10.79],
                ['2010-02-17', 10.95],
                ['2010-02-18', 11.29],
                ['2010-02-19', 11.32],
                ['2010-02-22', 11.28],
                ['2010-02-23', 10.68],
                ['2010-02-24', 11.07],
                ['2010-02-25', 11.15],
                ['2010-02-26', 11.37],
                ['2010-03-01', 11.95],
                ['2010-03-02', 12.43],
                ['2010-03-03', 12.43],
                ['2010-03-04', 12.34],
                ['2010-03-05', 12.86],
                ['2010-03-08', 12.69],
                ['2010-03-09', 12.68],
                ['2010-03-10', 12.83],
                ['2010-03-11', 12.81],
                ['2010-03-12', 12.64],
                ['2010-03-15', 12.66],
                ['2010-03-16', 13.37],
                ['2010-03-17', 13.57],
                ['2010-03-18', 13.58],
                ['2010-03-19', 13.05],
                ['2010-03-22', 13.37],
                ['2010-03-23', 13.75],
                ['2010-03-24', 13.03],
                ['2010-03-25', 12.89],
                ['2010-03-26', 13.07],
                ['2010-03-29', 13.36],
                ['2010-03-30', 13.44],
                ['2010-03-31', 13.43],
                ['2010-04-01', 13.80],
                ['2010-04-05', 14.28],
                ['2010-04-06', 14.19],
                ['2010-04-07', 14.32],
                ['2010-04-08', 14.19],
                ['2010-04-09', 14.66],
                ['2010-04-12', 14.77],
                ['2010-04-13', 14.93],
                ['2010-04-14', 15.66],
                ['2010-04-15', 15.91],
                ['2010-04-16', 15.22],
                ['2010-04-19', 15.14],
                ['2010-04-20', 15.56],
                ['2010-04-21', 15.48],
                ['2010-04-22', 15.42],
                ['2010-04-23', 15.79],
                ['2010-04-26', 15.60],
                ['2010-04-27', 15.07],
                ['2010-04-28', 15.16],
                ['2010-04-29', 15.67],
                ['2010-04-30', 15.23],
                ['2010-05-03', 15.68],
                ['2010-05-04', 14.51],
                ['2010-05-05', 14.30],
                ['2010-05-06', 13.44],
                ['2010-05-07', 12.57],
                ['2010-05-10', 14.29],
                ['2010-05-11', 14.60],
                ['2010-05-12', 15.40],
                ['2010-05-13', 14.91],
                ['2010-05-14', 14.11],
                ['2010-05-17', 14.16],
                ['2010-05-18', 13.68],
                ['2010-05-19', 13.06],
                ['2010-05-20', 11.44],
                ['2010-05-21', 11.70],
                ['2010-05-24', 11.56],
                ['2010-05-25', 11.45],
                ['2010-05-26', 11.40],
                ['2010-05-27', 12.66],
                ['2010-05-28', 12.36],
                ['2010-06-01', 11.75],
                ['2010-06-02', 12.79],
                ['2010-06-03', 12.89],
                ['2010-06-04', 11.56],
                ['2010-06-07', 11.23],
                ['2010-06-08', 11.43],
                ['2010-06-09', 11.19],
                ['2010-06-10', 12.08],
                ['2010-06-11', 12.51],
                ['2010-06-14', 12.53],
                ['2010-06-15', 13.77],
                ['2010-06-16', 13.83],
                ['2010-06-17', 14.20],
                ['2010-06-18', 14.63],
                ['2010-06-21', 14.11],
                ['2010-06-22', 13.61],
                ['2010-06-23', 13.43],
                ['2010-06-24', 12.85],
                ['2010-06-25', 13.24],
                ['2010-06-28', 13.21],
                ['2010-06-29', 12.01],
                ['2010-06-30', 11.94],
                ['2010-07-01', 11.15],
                ['2010-07-02', 11.32],
                ['2010-07-06', 11.12],
                ['2010-07-07', 12.60],
                ['2010-07-08', 12.71],
                ['2010-07-09', 13.13],
                ['2010-07-12', 13.03],
                ['2010-07-13', 13.70],
                ['2010-07-14', 13.79],
                ['2010-07-15', 13.74],
                ['2010-07-16', 12.58],
                ['2010-07-19', 12.82],
                ['2010-07-20', 13.18],
                ['2010-07-21', 12.41],
                ['2010-07-22', 13.39],
                ['2010-07-23', 13.60],
                ['2010-07-26', 13.97],
                ['2010-07-27', 13.53],
                ['2010-07-28', 13.17],
                ['2010-07-29', 12.75],
                ['2010-07-30', 13.00],
                ['2010-08-02', 13.58],
                ['2010-08-03', 13.55],
                ['2010-08-04', 13.98],
                ['2010-08-05', 13.91],
                ['2010-08-06', 14.04],
                ['2010-08-09', 14.26],
                ['2010-08-10', 13.95],
                ['2010-08-11', 12.83],
                ['2010-08-12', 12.80],
                ['2010-08-13', 12.61],
                ['2010-08-16', 12.79],
                ['2010-08-17', 13.36],
                ['2010-08-18', 13.57],
                ['2010-08-19', 13.07],
                ['2010-08-20', 12.95],
                ['2010-08-23', 12.72],
                ['2010-08-24', 12.11],
                ['2010-08-25', 12.47],
                ['2010-08-26', 12.07],
                ['2010-08-27', 12.67],
                ['2010-08-30', 11.99],
                ['2010-08-31', 11.88],
                ['2010-09-01', 12.79],
                ['2010-09-02', 13.32],
                ['2010-09-03', 13.62],
                ['2010-09-07', 13.31],
                ['2010-09-08', 13.36],
                ['2010-09-09', 13.38],
                ['2010-09-10', 13.41],
                ['2010-09-13', 13.89],
                ['2010-09-14', 14.49],
                ['2010-09-15', 14.72],
                ['2010-09-16', 14.81],
                ['2010-09-17', 14.89],
                ['2010-09-20', 15.46],
                ['2010-09-21', 15.63],
                ['2010-09-22', 15.46],
                ['2010-09-23', 15.31],
                ['2010-09-24', 16.31],
                ['2010-09-27', 16.14],
                ['2010-09-28', 16.77],
                ['2010-09-29', 16.73],
                ['2010-09-30', 16.63],
                ['2010-10-01', 16.84],
                ['2010-10-04', 16.45],
                ['2010-10-05', 17.56],
                ['2010-10-06', 17.46],
                ['2010-10-07', 17.22],
                ['2010-10-08', 17.55],
                ['2010-10-11', 17.70],
                ['2010-10-12', 17.75],
                ['2010-10-13', 18.30],
                ['2010-10-14', 18.24],
                ['2010-10-15', 18.22],
                ['2010-10-18', 18.42],
                ['2010-10-19', 17.32],
                ['2010-10-20', 17.73],
                ['2010-10-21', 17.56],
                ['2010-10-22', 17.87],
                ['2010-10-25', 18.25],
                ['2010-10-26', 18.15],
                ['2010-10-27', 18.22],
                ['2010-10-28', 18.39],
                ['2010-10-29', 18.49],
                ['2010-11-01', 18.40],
                ['2010-11-02', 18.77],
                ['2010-11-03', 18.91],
                ['2010-11-04', 20.18],
                ['2010-11-05', 20.40],
                ['2010-11-08', 20.44],
                ['2010-11-09', 19.88],
                ['2010-11-10', 20.17],
                ['2010-11-11', 19.99],
                ['2010-11-12', 19.14],
                ['2010-11-15', 18.76],
                ['2010-11-16', 18.01],
                ['2010-11-17', 17.96],
                ['2010-11-18', 18.75],
                ['2010-11-19', 18.88],
                ['2010-11-22', 19.21],
                ['2010-11-23', 18.92],
                ['2010-11-24', 19.41],
                ['2010-11-26', 18.97],
                ['2010-11-29', 18.87],
                ['2010-11-30', 18.78],
                ['2010-12-01', 19.56],
                ['2010-12-02', 19.96],
                ['2010-12-03', 20.48],
                ['2010-12-06', 20.48],
                ['2010-12-07', 20.11],
                ['2010-12-08', 19.94],
                ['2010-12-09', 20.18],
                ['2010-12-10', 20.40],
                ['2010-12-13', 20.47],
                ['2010-12-14', 20.61],
                ['2010-12-15', 20.23],
                ['2010-12-16', 20.48],
                ['2010-12-17', 20.80],
                ['2010-12-20', 20.83],
                ['2010-12-21', 20.99],
                ['2010-12-22', 20.90],
                ['2010-12-23', 20.71],
                ['2010-12-27', 20.80],
                ['2010-12-28', 20.84],
                ['2010-12-29', 20.96],
                ['2010-12-30', 20.95],
                ['2010-12-31', 21.26],
                ['2011-01-03', 21.51],
                ['2011-01-04', 21.08],
                ['2011-01-05', 21.01],
                ['2011-01-06', 20.98],
                ['2011-01-07', 20.98],
                ['2011-01-10', 21.15],
                ['2011-01-11', 21.42],
                ['2011-01-12', 21.81],
                ['2011-01-13', 21.68],
                ['2011-01-14', 21.83],
                ['2011-01-18', 22.03],
                ['2011-01-19', 21.62],
                ['2011-01-20', 21.13],
                ['2011-01-21', 21.15],
                ['2011-01-24', 21.41],
                ['2011-01-25', 21.43],
                ['2011-01-26', 21.68],
                ['2011-01-27', 21.56],
                ['2011-01-28', 21.00],
                ['2011-01-31', 21.18],
                ['2011-02-01', 21.89],
                ['2011-02-02', 21.79],
                ['2011-02-03', 22.08],
                ['2011-02-04', 22.32],
                ['2011-02-07', 22.55],
                ['2011-02-08', 22.75],
                ['2011-02-09', 22.67],
                ['2011-02-10', 22.63],
                ['2011-02-11', 22.94],
                ['2011-02-14', 23.11],
                ['2011-02-15', 23.14],
                ['2011-02-16', 23.47],
                ['2011-02-17', 23.87],
                ['2011-02-18', 24.07],
                ['2011-02-22', 23.31],
                ['2011-02-23', 22.92],
                ['2011-02-24', 22.92],
                ['2011-02-25', 23.71],
                ['2011-02-28', 23.76],
                ['2011-03-01', 23.57],
                ['2011-03-02', 23.75],
                ['2011-03-03', 24.30],
                ['2011-03-04', 24.25],
                ['2011-03-07', 23.76],
                ['2011-03-08', 23.98],
                ['2011-03-09', 23.71],
                ['2011-03-10', 22.69],
                ['2011-03-11', 23.05],
                ['2011-03-14', 22.94],
                ['2011-03-15', 22.17],
                ['2011-03-16', 21.49],
                ['2011-03-17', 21.89],
                ['2011-03-18', 22.35],
                ['2011-03-21', 23.00],
                ['2011-03-22', 22.83],
                ['2011-03-23', 23.13],
                ['2011-03-24', 23.50],
                ['2011-03-25', 23.57],
                ['2011-03-28', 23.46],
                ['2011-03-29', 23.70],
                ['2011-03-30', 24.04],
                ['2011-03-31', 24.10],
                ['2011-04-01', 24.12],
                ['2011-04-04', 24.23],
                ['2011-04-05', 24.62],
                ['2011-04-06', 24.99],
                ['2011-04-07', 24.95],
                ['2011-04-08', 24.75],
                ['2011-04-11', 24.69],
                ['2011-04-12', 24.16],
                ['2011-04-13', 24.27],
                ['2011-04-14', 24.63],
                ['2011-04-15', 25.13],
                ['2011-04-18', 24.70],
                ['2011-04-19', 24.89],
                ['2011-04-20', 25.75],
                ['2011-04-21', 25.89],
                ['2011-04-25', 25.89],
                ['2011-04-26', 26.41],
                ['2011-04-27', 26.99],
                ['2011-04-28', 27.16],
                ['2011-04-29', 27.49],
                ['2011-05-02', 27.28],
                ['2011-05-03', 27.11],
                ['2011-05-04', 26.62],
                ['2011-05-05', 26.05],
                ['2011-05-06', 26.54],
                ['2011-05-09', 26.91],
                ['2011-05-10', 27.30],
                ['2011-05-11', 26.82],
                ['2011-05-12', 27.27],
                ['2011-05-13', 26.85],
                ['2011-05-16', 26.53],
                ['2011-05-17', 26.36],
                ['2011-05-18', 26.93],
                ['2011-05-19', 26.91],
                ['2011-05-20', 26.98],
                ['2011-05-23', 26.44],
                ['2011-05-24', 26.29],
                ['2011-05-25', 26.48],
                ['2011-05-26', 26.69],
                ['2011-05-27', 27.04],
                ['2011-05-31', 27.59],
                ['2011-06-01', 26.80],
                ['2011-06-02', 26.56],
                ['2011-06-03', 26.14],
                ['2011-06-06', 25.80],
                ['2011-06-07', 25.91],
                ['2011-06-08', 25.58],
                ['2011-06-09', 25.87],
                ['2011-06-10', 25.06],
                ['2011-06-13', 24.87],
                ['2011-06-14', 25.42],
                ['2011-06-15', 24.86],
                ['2011-06-16', 24.75],
                ['2011-06-17', 24.87],
                ['2011-06-20', 25.12],
                ['2011-06-21', 25.77],
                ['2011-06-22', 25.55],
                ['2011-06-23', 25.27],
                ['2011-06-24', 24.45],
                ['2011-06-27', 24.63],
                ['2011-06-28', 25.21],
                ['2011-06-29', 25.37],
                ['2011-06-30', 25.74],
                ['2011-07-01', 26.32],
                ['2011-07-05', 26.49],
                ['2011-07-06', 26.72],
                ['2011-07-07', 27.18],
                ['2011-07-08', 27.00],
                ['2011-07-11', 26.42],
                ['2011-07-12', 26.21],
                ['2011-07-13', 26.47],
                ['2011-07-14', 26.10],
                ['2011-07-15', 26.31],
                ['2011-07-18', 25.95],
                ['2011-07-19', 26.59],
                ['2011-07-20', 26.61],
                ['2011-07-21', 26.87],
                ['2011-07-22', 27.21],
                ['2011-07-25', 26.84],
                ['2011-07-26', 26.74],
                ['2011-07-27', 25.66],
                ['2011-07-28', 25.43],
                ['2011-07-29', 25.39],
                ['2011-08-01', 25.02],
                ['2011-08-02', 24.47],
                ['2011-08-03', 24.75],
                ['2011-08-04', 22.61],
                ['2011-08-05', 22.60],
                ['2011-08-08', 20.35],
                ['2011-08-09', 22.47],
                ['2011-08-10', 21.68],
                ['2011-08-11', 23.12],
                ['2011-08-12', 23.42],
                ['2011-08-15', 24.41],
                ['2011-08-16', 24.20],
                ['2011-08-17', 24.35],
                ['2011-08-18', 22.77],
                ['2011-08-19', 22.58],
                ['2011-08-22', 23.15],
                ['2011-08-23', 24.18],
                ['2011-08-24', 23.87],
                ['2011-08-25', 23.21],
                ['2011-08-26', 24.52],
                ['2011-08-29', 25.43],
                ['2011-08-30', 26.02],
                ['2011-08-31', 26.17],
                ['2011-09-01', 25.64],
                ['2011-09-02', 25.19],
                ['2011-09-06', 24.95],
                ['2011-09-07', 25.74],
                ['2011-09-08', 25.79],
                ['2011-09-09', 24.66],
                ['2011-09-12', 24.71],
                ['2011-09-13', 25.34],
                ['2011-09-14', 25.92],
                ['2011-09-15', 26.23],
                ['2011-09-16', 26.66],
                ['2011-09-19', 25.95],
                ['2011-09-20', 26.20],
                ['2011-09-21', 24.87],
                ['2011-09-22', 23.06],
                ['2011-09-23', 22.45],
                ['2011-09-26', 22.65],
                ['2011-09-27', 23.64],
                ['2011-09-28', 22.22],
                ['2011-09-29', 22.45],
                ['2011-09-30', 21.63],
                ['2011-10-03', 20.42],
                ['2011-10-04', 21.21],
                ['2011-10-05', 22.01],
                ['2011-10-06', 22.78],
                ['2011-10-07', 22.47],
                ['2011-10-10', 24.05],
                ['2011-10-11', 23.88],
                ['2011-10-12', 24.33],
                ['2011-10-13', 24.47],
                ['2011-10-14', 25.09],
                ['2011-10-17', 24.31],
                ['2011-10-18', 24.72],
                ['2011-10-19', 24.30],
                ['2011-10-20', 23.95],
                ['2011-10-21', 24.58],
                ['2011-10-24', 25.71],
                ['2011-10-25', 25.60],
                ['2011-10-26', 25.87],
                ['2011-10-27', 27.20],
                ['2011-10-28', 27.39],
                ['2011-10-31', 26.74],
                ['2011-11-01', 26.05],
                ['2011-11-02', 26.20],
                ['2011-11-03', 27.05],
                ['2011-11-04', 26.86],
                ['2011-11-07', 27.26],
                ['2011-11-08', 27.72],
                ['2011-11-09', 26.19],
                ['2011-11-10', 26.24],
                ['2011-11-11', 27.59],
                ['2011-11-14', 27.03],
                ['2011-11-15', 27.35],
                ['2011-11-16', 26.63],
                ['2011-11-17', 25.18],
                ['2011-11-18', 25.09],
                ['2011-11-21', 23.71],
                ['2011-11-22', 23.76],
                ['2011-11-23', 22.75],
                ['2011-11-25', 22.18],
                ['2011-11-28', 23.67],
                ['2011-11-29', 23.69],
                ['2011-11-30', 25.72],
                ['2011-12-01', 26.07],
                ['2011-12-02', 25.93],
                ['2011-12-05', 25.96],
                ['2011-12-06', 26.13],
                ['2011-12-07', 26.42],
                ['2011-12-08', 25.54],
                ['2011-12-09', 25.79],
                ['2011-12-12', 24.52],
                ['2011-12-13', 23.70],
                ['2011-12-14', 22.79],
                ['2011-12-15', 23.02],
                ['2011-12-16', 23.44],
                ['2011-12-19', 23.01],
                ['2011-12-20', 24.00],
                ['2011-12-21', 24.51],
                ['2011-12-22', 24.89],
                ['2011-12-23', 25.16],
                ['2011-12-27', 25.19],
                ['2011-12-28', 24.33],
                ['2011-12-29', 25.37],
                ['2011-12-30', 25.91],
                ['2012-01-03', 26.70],
                ['2012-01-04', 26.50],
                ['2012-01-05', 26.78],
                ['2012-01-06', 26.92],
                ['2012-01-09', 27.05],
                ['2012-01-10', 27.67],
                ['2012-01-11', 27.97],
                ['2012-01-12', 28.26],
                ['2012-01-13', 27.82],
                ['2012-01-17', 28.21],
                ['2012-01-18', 29.17],
                ['2012-01-19', 29.61],
                ['2012-01-20', 29.68],
                ['2012-01-23', 29.71],
                ['2012-01-24', 29.72],
                ['2012-01-25', 30.78],
                ['2012-01-26', 30.82],
                ['2012-01-27', 31.17],
                ['2012-01-30', 30.82],
                ['2012-01-31', 31.19],
                ['2012-02-01', 31.93],
                ['2012-02-02', 32.14],
                ['2012-02-03', 32.21],
                ['2012-02-06', 31.91],
                ['2012-02-07', 32.32],
                ['2012-02-08', 32.20],
                ['2012-02-09', 32.22],
                ['2012-02-10', 31.67],
                ['2012-02-13', 31.98],
                ['2012-02-14', 32.10],
                ['2012-02-15', 32.19],
                ['2012-02-16', 32.69],
                ['2012-02-17', 32.50],
                ['2012-02-21', 32.51],
                ['2012-02-22', 32.65],
                ['2012-02-23', 32.84],
                ['2012-02-24', 33.00],
                ['2012-02-27', 32.98],
                ['2012-02-28', 33.49],
                ['2012-02-29', 32.17],
                ['2012-03-01', 32.52],
                ['2012-03-02', 32.26],
                ['2012-03-05', 31.92],
                ['2012-03-06', 31.04],
                ['2012-03-07', 31.60],
                ['2012-03-08', 32.27],
                ['2012-03-09', 32.63],
                ['2012-03-12', 32.46],
                ['2012-03-13', 32.69],
                ['2012-03-14', 32.24],
                ['2012-03-15', 32.81],
                ['2012-03-16', 32.82],
                ['2012-03-19', 32.99],
                ['2012-03-20', 32.61],
                ['2012-03-21', 32.73],
                ['2012-03-22', 32.42],
                ['2012-03-23', 32.66],
                ['2012-03-26', 33.57],
                ['2012-03-27', 33.38],
                ['2012-03-28', 32.84],
                ['2012-03-29', 32.88],
                ['2012-03-30', 33.23],
                ['2012-04-02', 33.53],
                ['2012-04-03', 32.84],
                ['2012-04-04', 32.19],
                ['2012-04-05', 32.31],
                ['2012-04-09', 31.99],
                ['2012-04-10', 31.41],
                ['2012-04-11', 31.82],
                ['2012-04-12', 32.70],
                ['2012-04-13', 31.99],
                ['2012-04-16', 31.85],
                ['2012-04-17', 32.48],
                ['2012-04-18', 32.36],
                ['2012-04-19', 32.06],
                ['2012-04-20', 32.04],
                ['2012-04-23', 31.47],
                ['2012-04-24', 31.46],
                ['2012-04-25', 32.21],
                ['2012-04-26', 32.88],
                ['2012-04-27', 33.16],
                ['2012-04-30', 33.14],
                ['2012-05-01', 33.35],
                ['2012-05-02', 33.35],
                ['2012-05-03', 32.71],
                ['2012-05-04', 32.16],
                ['2012-05-07', 32.13],
                ['2012-05-08', 31.58],
                ['2012-05-09', 31.15],
                ['2012-05-10', 31.33],
                ['2012-05-11', 31.26],
                ['2012-05-14', 30.57],
                ['2012-05-15', 30.16],
                ['2012-05-16', 29.85],
                ['2012-05-17', 29.27],
                ['2012-05-18', 28.82],
                ['2012-05-21', 29.63],
                ['2012-05-22', 29.22],
                ['2012-05-23', 29.27],
                ['2012-05-24', 29.26],
                ['2012-05-25', 29.47],
                ['2012-05-29', 29.77],
                ['2012-05-30', 29.37],
                ['2012-05-31', 29.39],
                ['2012-06-01', 28.84],
                ['2012-06-04', 28.82],
                ['2012-06-05', 29.22],
                ['2012-06-06', 30.20],
                ['2012-06-07', 29.88],
                ['2012-06-08', 30.17],
                ['2012-06-11', 29.88],
                ['2012-06-12', 30.43],
                ['2012-06-13', 30.26],
                ['2012-06-14', 30.60],
                ['2012-06-15', 31.26],
                ['2012-06-18', 31.65],
                ['2012-06-19', 32.01],
                ['2012-06-20', 31.80],
                ['2012-06-21', 30.17],
                ['2012-06-22', 30.67],
                ['2012-06-25', 30.11],
                ['2012-06-26', 30.20],
                ['2012-06-27', 30.57],
                ['2012-06-28', 30.64],
                ['2012-06-29', 32.40],
                ['2012-07-02', 32.64],
                ['2012-07-03', 33.07],
                ['2012-07-05', 32.76],
                ['2012-07-06', 31.97],
                ['2012-07-09', 31.98],
                ['2012-07-10', 31.30],
                ['2012-07-11', 31.29],
                ['2012-07-12', 31.07],
                ['2012-07-13', 31.74],
                ['2012-07-16', 31.57],
                ['2012-07-17', 31.76],
                ['2012-07-18', 32.28],
                ['2012-07-19', 32.44],
                ['2012-07-20', 31.81],
                ['2012-07-23', 31.13],
                ['2012-07-24', 30.77],
                ['2012-07-25', 31.42],
                ['2012-07-26', 32.05],
                ['2012-07-27', 33.13],
                ['2012-07-30', 32.97],
                ['2012-07-31', 32.88],
                ['2012-08-01', 32.59],
                ['2012-08-02', 32.28],
                ['2012-08-03', 33.21],
                ['2012-08-06', 33.36],
                ['2012-08-07', 33.70],
                ['2012-08-08', 33.82],
                ['2012-08-09', 33.90],
                ['2012-08-10', 34.03],
                ['2012-08-13', 33.72],
                ['2012-08-14', 33.53],
                ['2012-08-15', 33.71],
                ['2012-08-16', 34.02],
                ['2012-08-17', 34.01],
                ['2012-08-20', 34.00],
                ['2012-08-21', 34.11],
                ['2012-08-22', 34.18],
                ['2012-08-23', 34.11],
                ['2012-08-24', 34.42],
                ['2012-08-27', 34.30],
                ['2012-08-28', 34.36],
                ['2012-08-29', 34.35],
                ['2012-08-30', 33.99],
                ['2012-08-31', 34.81],
                ['2012-09-04', 34.81],
                ['2012-09-05', 34.57],
                ['2012-09-06', 35.58],
                ['2012-09-07', 35.91],
                ['2012-09-10', 35.51],
                ['2012-09-11', 35.68],
                ['2012-09-12', 35.69],
                ['2012-09-13', 36.58],
                ['2012-09-14', 36.77],
                ['2012-09-17', 36.47],
                ['2012-09-18', 36.61],
                ['2012-09-19', 36.66],
                ['2012-09-20', 36.55],
                ['2012-09-21', 36.70],
                ['2012-09-24', 36.30],
                ['2012-09-25', 35.67],
                ['2012-09-26', 35.41],
                ['2012-09-27', 36.14],
                ['2012-09-28', 36.05],
                ['2012-10-01', 36.24],
                ['2012-10-02', 36.32],
                ['2012-10-03', 36.54],
                ['2012-10-04', 36.88],
                ['2012-10-05', 36.81],
                ['2012-10-08', 36.48],
                ['2012-10-09', 35.63],
                ['2012-10-10', 35.27],
                ['2012-10-11', 35.34],
                ['2012-10-12', 35.01],
                ['2012-10-15', 35.25],
                ['2012-10-16', 35.93],
                ['2012-10-17', 35.98],
                ['2012-10-18', 35.59],
                ['2012-10-19', 34.41],
                ['2012-10-22', 34.45],
                ['2012-10-23', 33.96],
                ['2012-10-24', 33.69],
                ['2012-10-25', 34.05],
                ['2012-10-26', 33.99],
                ['2012-10-31', 34.41],
                ['2012-11-01', 35.01],
                ['2012-11-02', 34.14],
                ['2012-11-05', 34.54],
                ['2012-11-06', 35.15],
                ['2012-11-07', 34.27],
                ['2012-11-08', 34.01],
                ['2012-11-09', 34.03],
                ['2012-11-12', 34.03],
                ['2012-11-13', 33.84],
                ['2012-11-14', 33.29],
                ['2012-11-15', 32.91],
                ['2012-11-16', 33.23],
                ['2012-11-19', 34.11],
                ['2012-11-20', 34.08],
                ['2012-11-21', 34.23],
                ['2012-11-23', 34.91],
                ['2012-11-26', 34.95],
                ['2012-11-27', 34.78],
                ['2012-11-28', 34.91],
                ['2012-11-29', 35.13],
                ['2012-11-30', 35.08],
                ['2012-12-03', 34.75],
                ['2012-12-04', 34.58],
                ['2012-12-05', 34.61],
                ['2012-12-06', 34.89],
                ['2012-12-07', 34.96],
                ['2012-12-10', 35.39],
                ['2012-12-11', 35.73],
                ['2012-12-12', 35.63],
                ['2012-12-13', 35.23],
                ['2012-12-14', 34.97],
                ['2012-12-17', 35.34],
                ['2012-12-18', 35.59],
                ['2012-12-19', 35.06],
                ['2012-12-20', 34.90],
                ['2012-12-21', 34.63],
                ['2012-12-24', 34.44],
                ['2012-12-26', 34.38],
                ['2012-12-27', 34.40],
                ['2012-12-28', 33.89],
                ['2012-12-31', 35.00],
                ['2013-01-02', 36.37],
                ['2013-01-03', 36.18],
                ['2013-01-04', 36.29],
                ['2013-01-07', 36.10],
                ['2013-01-08', 36.06],
                ['2013-01-09', 36.45],
                ['2013-01-10', 37.02],
                ['2013-01-11', 36.97],
                ['2013-01-14', 36.96],
                ['2013-01-15', 36.99],
                ['2013-01-16', 37.06],
                ['2013-01-17', 37.71],
                ['2013-01-18', 37.74],
                ['2013-01-22', 38.04],
                ['2013-01-23', 38.17],
                ['2013-01-24', 38.01],
                ['2013-01-25', 38.19],
                ['2013-01-28', 38.14],
                ['2013-01-29', 38.28],
                ['2013-01-30', 38.18],
                ['2013-01-31', 38.10],
                ['2013-02-01', 38.74],
                ['2013-02-04', 38.24],
                ['2013-02-05', 38.73],
                ['2013-02-06', 38.93],
                ['2013-02-07', 38.74],
                ['2013-02-08', 39.13],
                ['2013-02-11', 38.90],
                ['2013-02-12', 38.98],
                ['2013-02-13', 38.85],
                ['2013-02-14', 38.88],
                ['2013-02-15', 38.58],
                ['2013-02-19', 38.90],
                ['2013-02-20', 37.83],
                ['2013-02-21', 37.55],
                ['2013-02-22', 38.16],
                ['2013-02-25', 37.69],
                ['2013-02-26', 38.24],
                ['2013-02-27', 38.66],
                ['2013-02-28', 38.55],
                ['2013-03-01', 38.61],
                ['2013-03-04', 38.76],
                ['2013-03-05', 39.31],
                ['2013-03-06', 39.34],
                ['2013-03-07', 39.25],
                ['2013-03-08', 39.40],
                ['2013-03-11', 39.51],
                ['2013-03-12', 39.74],
                ['2013-03-13', 39.80],
                ['2013-03-14', 39.94],
                ['2013-03-15', 39.54],
                ['2013-03-18', 39.40],
                ['2013-03-19', 39.53],
                ['2013-03-20', 39.96],
                ['2013-03-21', 39.64],
                ['2013-03-22', 40.01],
                ['2013-03-25', 39.63],
                ['2013-03-26', 40.18],
                ['2013-03-27', 40.35],
                ['2013-03-28', 40.58],
                ['2013-04-01', 40.38],
                ['2013-04-02', 40.26],
                ['2013-04-03', 39.47],
                ['2013-04-04', 39.83],
                ['2013-04-05', 39.88],
                ['2013-04-08', 40.11],
                ['2013-04-09', 40.51],
                ['2013-04-10', 40.89],
                ['2013-04-11', 40.92],
                ['2013-04-12', 40.01],
                ['2013-04-15', 37.33],
                ['2013-04-16', 38.27],
                ['2013-04-17', 37.51],
                ['2013-04-18', 37.38],
                ['2013-04-19', 38.06],
                ['2013-04-22', 38.48],
                ['2013-04-23', 39.02],
                ['2013-04-24', 39.14],
                ['2013-04-25', 39.86],
                ['2013-04-26', 39.49],
                ['2013-04-29', 40.06],
                ['2013-04-30', 40.31],
                ['2013-05-01', 39.74],
                ['2013-05-02', 40.31],
                ['2013-05-03', 40.59],
                ['2013-05-06', 40.58],
                ['2013-05-07', 40.58],
                ['2013-05-08', 41.16],
                ['2013-05-09', 40.96],
                ['2013-05-10', 41.02],
                ['2013-05-13', 40.71],
                ['2013-05-14', 41.01],
                ['2013-05-15', 40.94],
                ['2013-05-16', 40.53],
                ['2013-05-17', 40.44],
                ['2013-05-20', 40.80],
                ['2013-05-21', 40.72],
                ['2013-05-22', 39.91],
                ['2013-05-23', 40.24],
                ['2013-05-24', 40.12],
                ['2013-05-28', 40.29],
                ['2013-05-29', 40.03],
                ['2013-05-30', 40.64],
                ['2013-05-31', 39.59],
                ['2013-06-03', 40.17],
                ['2013-06-04', 39.80],
                ['2013-06-05', 39.21],
                ['2013-06-06', 39.74],
                ['2013-06-07', 39.72],
                ['2013-06-10', 39.84],
                ['2013-06-11', 39.16],
                ['2013-06-12', 38.98],
                ['2013-06-13', 39.79],
                ['2013-06-14', 39.79],
                ['2013-06-17', 40.12],
                ['2013-06-18', 40.21],
                ['2013-06-19', 39.11],
                ['2013-06-20', 36.61],
                ['2013-06-21', 37.15],
                ['2013-06-24', 36.31],
                ['2013-06-25', 36.81],
                ['2013-06-26', 36.44],
                ['2013-06-27', 36.31],
                ['2013-06-28', 36.55],
                ['2013-07-01', 37.03],
                ['2013-07-02', 36.84],
                ['2013-07-03', 37.05],
                ['2013-07-05', 36.98],
                ['2013-07-08', 37.24],
                ['2013-07-09', 37.54],
                ['2013-07-10', 37.82],
                ['2013-07-11', 39.26],
                ['2013-07-12', 39.33],
                ['2013-07-15', 39.51],
                ['2013-07-16', 39.55],
                ['2013-07-17', 39.47],
                ['2013-07-18', 39.63],
                ['2013-07-19', 39.88],
                ['2013-07-22', 40.47],
                ['2013-07-23', 40.39],
                ['2013-07-24', 39.50],
                ['2013-07-25', 40.02],
                ['2013-07-26', 40.03],
                ['2013-07-29', 39.83],
                ['2013-07-30', 39.94],
                ['2013-07-31', 39.81],
                ['2013-08-01', 40.20],
                ['2013-08-02', 40.15],
                ['2013-08-05', 39.99],
                ['2013-08-06', 39.51],
                ['2013-08-07', 39.29],
                ['2013-08-08', 40.00],
                ['2013-08-09', 39.79],
                ['2013-08-12', 40.07],
                ['2013-08-13', 39.86],
                ['2013-08-14', 39.69],
                ['2013-08-15', 39.11],
                ['2013-08-16', 38.95],
                ['2013-08-19', 38.67],
                ['2013-08-20', 39.04],
                ['2013-08-21', 38.65],
                ['2013-08-22', 39.07],
                ['2013-08-23', 39.52],
                ['2013-08-26', 39.47],
                ['2013-08-27', 38.93],
                ['2013-08-28', 39.03],
                ['2013-08-29', 39.17],
                ['2013-08-30', 38.82],
                ['2013-09-03', 39.15],
                ['2013-09-04', 39.43],
                ['2013-09-05', 39.18],
                ['2013-09-06', 39.54],
                ['2013-09-09', 39.99],
                ['2013-09-10', 40.15],
                ['2013-09-11', 40.27],
                ['2013-09-12', 39.56],
                ['2013-09-13', 39.77],
                ['2013-09-16', 39.92],
                ['2013-09-17', 40.21],
                ['2013-09-18', 41.75],
                ['2013-09-19', 41.54],
                ['2013-09-20', 40.68],
                ['2013-09-23', 40.53],
                ['2013-09-24', 40.23],
                ['2013-09-25', 40.33],
                ['2013-09-26', 40.28],
                ['2013-09-27', 40.35],
                ['2013-09-30', 40.45],
                ['2013-10-01', 40.28],
                ['2013-10-02', 40.53],
                ['2013-10-03', 40.28],
                ['2013-10-04', 40.42],
                ['2013-10-07', 40.28],
                ['2013-10-08', 39.76],
                ['2013-10-09', 39.61],
                ['2013-10-10', 40.26],
                ['2013-10-11', 40.29],
                ['2013-10-14', 40.57],
                ['2013-10-15', 40.27],
                ['2013-10-16', 40.96],
                ['2013-10-17', 42.08],
                ['2013-10-18', 42.16],
                ['2013-10-21', 42.09],
                ['2013-10-22', 42.78],
                ['2013-10-23', 42.25],
                ['2013-10-24', 42.56],
                ['2013-10-25', 42.74],
                ['2013-10-28', 42.92],
                ['2013-10-29', 43.28],
                ['2013-10-30', 42.91],
                ['2013-10-31', 42.74],
                ['2013-11-01', 42.56],
                ['2013-11-04', 42.72],
                ['2013-11-05', 42.42],
                ['2013-11-06', 42.68],
                ['2013-11-07', 42.01],
                ['2013-11-08', 42.05],
                ['2013-11-11', 42.03],
                ['2013-11-12', 41.88],
                ['2013-11-13', 42.43],
                ['2013-11-14', 42.86],
                ['2013-11-15', 43.05],
                ['2013-11-18', 42.52],
                ['2013-11-19', 42.27],
                ['2013-11-20', 41.62],
                ['2013-11-21', 42.01],
                ['2013-11-22', 42.18],
                ['2013-11-25', 42.21],
                ['2013-11-26', 42.25],
                ['2013-11-27', 42.28],
                ['2013-11-29', 42.49],
                ['2013-12-02', 41.84],
                ['2013-12-03', 41.82],
                ['2013-12-04', 41.83],
                ['2013-12-05', 41.45],
                ['2013-12-06', 42.17],
                ['2013-12-09', 42.35],
                ['2013-12-10', 42.41],
                ['2013-12-11', 41.63],
                ['2013-12-12', 40.92],
                ['2013-12-13', 41.08],
                ['2013-12-16', 41.51],
                ['2013-12-17', 41.39],
                ['2013-12-18', 41.94],
                ['2013-12-19', 41.33],
                ['2013-12-20', 41.79],
                ['2013-12-23', 41.76],
                ['2013-12-24', 41.91],
                ['2013-12-26', 42.20],
                ['2013-12-27', 42.14],
                ['2013-12-30', 42.22],
                ['2013-12-31', 42.55],
                ['2014-01-02', 42.46],
                ['2014-01-03', 42.70],
                ['2014-01-06', 42.71],
                ['2014-01-07', 43.02],
                ['2014-01-08', 43.11],
                ['2014-01-09', 43.13],
                ['2014-01-10', 43.82],
                ['2014-01-13', 43.36],
                ['2014-01-14', 43.76],
                ['2014-01-15', 43.98],
                ['2014-01-16', 43.96],
                ['2014-01-17', 44.03],
                ['2014-01-21', 43.95],
                ['2014-01-22', 43.90],
                ['2014-01-23', 43.85],
                ['2014-01-24', 42.98],
                ['2014-01-27', 42.52],
                ['2014-01-28', 42.85],
                ['2014-01-29', 42.71],
                ['2014-01-30', 42.86],
                ['2014-01-31', 42.68],
                ['2014-02-03', 41.71],
                ['2014-02-04', 41.94],
                ['2014-02-05', 42.01],
                ['2014-02-06', 42.53],
                ['2014-02-07', 43.35],
                ['2014-02-10', 43.75],
                ['2014-02-11', 44.52],
                ['2014-02-12', 44.68],
                ['2014-02-13', 45.32],
                ['2014-02-14', 45.83],
                ['2014-02-18', 46.05],
                ['2014-02-19', 45.59],
                ['2014-02-20', 46.23],
                ['2014-02-21', 46.09],
                ['2014-02-24', 46.42],
                ['2014-02-25', 46.34],
                ['2014-02-26', 46.32],
                ['2014-02-27', 46.52],
                ['2014-02-28', 46.60],
                ['2014-03-03', 46.56],
                ['2014-03-04', 47.16],
                ['2014-03-05', 47.08],
                ['2014-03-06', 47.29],
                ['2014-03-07', 47.12],
                ['2014-03-10', 47.06],
                ['2014-03-11', 46.95],
                ['2014-03-12', 47.30],
                ['2014-03-13', 46.77],
                ['2014-03-14', 46.81],
                ['2014-03-17', 47.04],
                ['2014-03-18', 47.37],
                ['2014-03-19', 46.70],
                ['2014-03-20', 46.93],
                ['2014-03-21', 46.68],
                ['2014-03-24', 45.98],
                ['2014-03-25', 46.12],
                ['2014-03-26', 45.75],
                ['2014-03-27', 45.60],
                ['2014-03-28', 45.74],
                ['2014-03-31', 46.62],
                ['2014-04-01', 46.94],
                ['2014-04-02', 47.17],
                ['2014-04-03', 47.17],
                ['2014-04-04', 46.63],
                ['2014-04-07', 46.02],
                ['2014-04-08', 46.49],
                ['2014-04-09', 47.02],
                ['2014-04-10', 45.97],
                ['2014-04-11', 45.35],
                ['2014-04-14', 45.95],
                ['2014-04-15', 45.87],
                ['2014-04-16', 46.14],
                ['2014-04-17', 46.23],
                ['2014-04-21', 46.33],
                ['2014-04-22', 46.56],
                ['2014-04-23', 46.33],
                ['2014-04-24', 46.53],
                ['2014-04-25', 45.99],
                ['2014-04-28', 46.00],
                ['2014-04-29', 46.17],
                ['2014-04-30', 46.39],
                ['2014-05-01', 46.38],
                ['2014-05-02', 46.52],
                ['2014-05-05', 46.91],
                ['2014-05-06', 46.58],
                ['2014-05-07', 46.52],
                ['2014-05-08', 46.56],
                ['2014-05-09', 46.64],
                ['2014-05-12', 47.34],
                ['2014-05-13', 47.19],
                ['2014-05-14', 47.16],
                ['2014-05-15', 46.52],
                ['2014-05-16', 46.89],
                ['2014-05-19', 47.04],
                ['2014-05-20', 46.74],
                ['2014-05-21', 46.99],
                ['2014-05-22', 47.16],
                ['2014-05-23', 47.36],
                ['2014-05-27', 47.24],
                ['2014-05-28', 47.13],
                ['2014-05-29', 47.29],
                ['2014-05-30', 47.46],
                ['2014-06-02', 47.38],
                ['2014-06-03', 47.45],
                ['2014-06-04', 47.68],
                ['2014-06-05', 48.01],
                ['2014-06-06', 48.25],
                ['2014-06-09', 48.27],
                ['2014-06-10', 48.31],
                ['2014-06-11', 48.19],
                ['2014-06-12', 48.20],
                ['2014-06-13', 48.41],
                ['2014-06-16', 48.62],
                ['2014-06-17', 48.76],
                ['2014-06-18', 49.17],
                ['2014-06-19', 49.99],
                ['2014-06-20', 50.04],
                ['2014-06-23', 49.96],
                ['2014-06-24', 49.67],
                ['2014-06-25', 49.85],
                ['2014-06-26', 49.76],
                ['2014-06-27', 49.86],
                ['2014-06-30', 50.27],
                ['2014-07-01', 50.83],
                ['2014-07-02', 50.76],
                ['2014-07-03', 50.93],
                ['2014-07-07', 50.61],
                ['2014-07-08', 50.42],
                ['2014-07-09', 50.78],
                ['2014-07-10', 50.71],
                ['2014-07-11', 50.87],
                ['2014-07-14', 50.58],
                ['2014-07-15', 50.19],
                ['2014-07-16', 50.46],
                ['2014-07-17', 49.97],
                ['2014-07-18', 50.50],
                ['2014-07-21', 50.44],
                ['2014-07-22', 50.61],
                ['2014-07-23', 50.45],
                ['2014-07-24', 50.05],
                ['2014-07-25', 49.82],
                ['2014-07-28', 49.78],
                ['2014-07-29', 49.56],
                ['2014-07-30', 49.63],
                ['2014-07-31', 48.40],
                ['2014-08-01', 48.65],
                ['2014-08-04', 48.85],
                ['2014-08-05', 48.30],
                ['2014-08-06', 48.45],
                ['2014-08-07', 48.06],
                ['2014-08-08', 48.70],
                ['2014-08-11', 48.93],
                ['2014-08-12', 48.85],
                ['2014-08-13', 49.40],
                ['2014-08-14', 49.66],
                ['2014-08-15', 49.65],
                ['2014-08-18', 49.92],
                ['2014-08-19', 50.20],
                ['2014-08-20', 50.22],
                ['2014-08-21', 50.18],
                ['2014-08-22', 50.07],
                ['2014-08-25', 50.05],
                ['2014-08-26', 50.18],
                ['2014-08-27', 50.16],
                ['2014-08-28', 50.33],
                ['2014-08-29', 50.63],
                ['2014-09-02', 50.17],
                ['2014-09-03', 50.36],
                ['2014-09-04', 50.17],
                ['2014-09-05', 50.58],
                ['2014-09-08', 50.38],
                ['2014-09-09', 50.07],
                ['2014-09-10', 50.00],
                ['2014-09-11', 49.96],
                ['2014-09-12', 49.24],
                ['2014-09-15', 49.08],
                ['2014-09-16', 49.66],
                ['2014-09-17', 49.43],
                ['2014-09-18', 49.82],
                ['2014-09-19', 49.51],
                ['2014-09-22', 49.04],
                ['2014-09-23', 48.81],
                ['2014-09-24', 49.06],
                ['2014-09-25', 48.34],
                ['2014-09-26', 48.69],
                ['2014-09-29', 48.65],
                ['2014-09-30', 48.53],
                ['2014-10-01', 48.14],
                ['2014-10-02', 48.15],
                ['2014-10-03', 48.47],
                ['2014-10-06', 48.66],
                ['2014-10-07', 48.05],
                ['2014-10-08', 49.33],
                ['2014-10-09', 48.20],
                ['2014-10-10', 47.13],
                ['2014-10-13', 46.50],
                ['2014-10-14', 46.89],
                ['2014-10-15', 46.46],
                ['2014-10-16', 46.55],
                ['2014-10-17', 47.01],
                ['2014-10-20', 47.83],
                ['2014-10-21', 49.09],
                ['2014-10-22', 48.54],
                ['2014-10-23', 49.02],
                ['2014-10-24', 49.50],
                ['2014-10-27', 49.55],
                ['2014-10-28', 50.10],
                ['2014-10-29', 49.75],
                ['2014-10-30', 49.84],
                ['2014-10-31', 50.28],
                ['2014-11-03', 50.31],
                ['2014-11-04', 50.24],
                ['2014-11-05', 50.21],
                ['2014-11-06', 50.13],
                ['2014-11-07', 50.51],
                ['2014-11-10', 50.42],
                ['2014-11-11', 50.68],
                ['2014-11-12', 50.58],
                ['2014-11-13', 50.72],
                ['2014-11-14', 51.23],
                ['2014-11-17', 51.19],
                ['2014-11-18', 52.09],
                ['2014-11-19', 51.62],
                ['2014-11-20', 51.99],
                ['2014-11-21', 52.31],
                ['2014-11-24', 52.59],
                ['2014-11-25', 52.67],
                ['2014-11-26', 53.17],
                ['2014-11-28', 52.98],
                ['2014-12-01', 53.36],
                ['2014-12-02', 53.36],
                ['2014-12-03', 53.95],
                ['2014-12-04', 53.80],
                ['2014-12-05', 53.69],
                ['2014-12-08', 53.61],
                ['2014-12-09', 54.03],
                ['2014-12-10', 53.02],
                ['2014-12-11', 53.25],
                ['2014-12-12', 52.43],
                ['2014-12-15', 51.44],
                ['2014-12-16', 51.07],
                ['2014-12-17', 52.03],
                ['2014-12-18', 53.37],
                ['2014-12-19', 53.58],
                ['2014-12-22', 53.47],
                ['2014-12-23', 53.26],
                ['2014-12-24', 53.05],
                ['2014-12-26', 53.53],
                ['2014-12-29', 53.33],
                ['2014-12-30', 53.41],
                ['2014-12-31', 53.04],
                ['2015-01-02', 53.16],
                ['2015-01-05', 52.84],
                ['2015-01-06', 52.59],
                ['2015-01-07', 53.26],
                ['2015-01-08', 54.29],
                ['2015-01-09', 54.31],
                ['2015-01-12', 54.16],
                ['2015-01-13', 53.83],
                ['2015-01-14', 53.59],
                ['2015-01-15', 53.90],
                ['2015-01-16', 54.69],
                ['2015-01-20', 55.14],
                ['2015-01-21', 55.33],
                ['2015-01-22', 56.29],
                ['2015-01-23', 56.00],
                ['2015-01-26', 55.86],
                ['2015-01-27', 55.54],
                ['2015-01-28', 54.89],
                ['2015-01-29', 54.71],
                ['2015-01-30', 54.30],
                ['2015-02-02', 54.52],
                ['2015-02-03', 54.97],
                ['2015-02-04', 55.01],
                ['2015-02-05', 55.66],
                ['2015-02-06', 54.62],
                ['2015-02-09', 54.20],
                ['2015-02-10', 54.99],
                ['2015-02-11', 54.76],
                ['2015-02-12', 55.33],
                ['2015-02-13', 55.67],
                ['2015-02-17', 55.44],
                ['2015-02-18', 55.70],
                ['2015-02-19', 55.50],
                ['2015-02-20', 55.77],
                ['2015-02-23', 55.75],
                ['2015-02-24', 55.99],
                ['2015-02-25', 56.00],
                ['2015-02-26', 56.08],
                ['2015-02-27', 56.05],
                ['2015-03-02', 56.53],
                ['2015-03-03', 55.97],
                ['2015-03-04', 55.77],
                ['2015-03-05', 55.93],
                ['2015-03-06', 54.35],
                ['2015-03-09', 54.65],
                ['2015-03-10', 53.81],
                ['2015-03-11', 53.76],
                ['2015-03-12', 54.32],
                ['2015-03-13', 54.16],
                ['2015-03-16', 55.07],
                ['2015-03-17', 54.64],
                ['2015-03-18', 55.66],
                ['2015-03-19', 55.64],
                ['2015-03-20', 56.39],
                ['2015-03-23', 56.50],
                ['2015-03-24', 56.24],
                ['2015-03-25', 54.84],
                ['2015-03-26', 54.59],
                ['2015-03-27', 55.20],
                ['2015-03-30', 55.62],
                ['2015-03-31', 55.46],
                ['2015-04-01', 55.57],
                ['2015-04-02', 55.58],
                ['2015-04-06', 56.14],
                ['2015-04-07', 55.88],
                ['2015-04-08', 56.01],
                ['2015-04-09', 56.19],
                ['2015-04-10', 56.64],
                ['2015-04-13', 56.22],
                ['2015-04-14', 55.98],
                ['2015-04-15', 56.45],
                ['2015-04-16', 56.27],
                ['2015-04-17', 55.79],
                ['2015-04-20', 56.14],
                ['2015-04-21', 56.37],
                ['2015-04-22', 56.38],
                ['2015-04-23', 56.50],
                ['2015-04-24', 55.93],
                ['2015-04-27', 55.91],
                ['2015-04-28', 56.19],
                ['2015-04-29', 55.56],
                ['2015-04-30', 54.74],
                ['2015-05-01', 55.34],
                ['2015-05-04', 55.60],
                ['2015-05-05', 54.87],
                ['2015-05-06', 54.67],
                ['2015-05-07', 54.89],
                ['2015-05-08', 55.66],
                ['2015-05-11', 55.37],
                ['2015-05-12', 55.27],
                ['2015-05-13', 55.62],
                ['2015-05-14', 56.51],
                ['2015-05-15', 56.74],
                ['2015-05-18', 56.95],
                ['2015-05-19', 56.64],
                ['2015-05-20', 56.74],
                ['2015-05-21', 56.75],
                ['2015-05-22', 56.61],
                ['2015-05-26', 55.87],
                ['2015-05-27', 56.72],
                ['2015-05-28', 56.72],
                ['2015-05-29', 56.65],
                ['2015-06-01', 56.72],
                ['2015-06-02', 56.46],
                ['2015-06-03', 56.15],
                ['2015-06-04', 55.58],
                ['2015-06-05', 55.25],
                ['2015-06-08', 54.82],
                ['2015-06-09', 54.75],
                ['2015-06-10', 55.49],
                ['2015-06-11', 55.66],
                ['2015-06-12', 55.26],
                ['2015-06-15', 55.10],
                ['2015-06-16', 55.32],
                ['2015-06-17', 55.54],
                ['2015-06-18', 56.52],
                ['2015-06-19', 56.34],
                ['2015-06-22', 56.23],
                ['2015-06-23', 55.97],
                ['2015-06-24', 55.28],
                ['2015-06-25', 55.19],
                ['2015-06-26', 54.87],
                ['2015-06-29', 53.84],
                ['2015-06-30', 54.12],
                ['2015-07-01', 54.46],
                ['2015-07-02', 54.50],
                ['2015-07-06', 54.46],
                ['2015-07-07', 54.50],
                ['2015-07-08', 53.66],
                ['2015-07-09', 53.40],
                ['2015-07-10', 54.14],
                ['2015-07-13', 54.50],
                ['2015-07-14', 54.78],
                ['2015-07-15', 54.48],
                ['2015-07-16', 54.71],
                ['2015-07-17', 54.40],
                ['2015-07-20', 53.78],
                ['2015-07-21', 53.66],
                ['2015-07-22', 53.40],
                ['2015-07-23', 53.33],
                ['2015-07-24', 53.02],
                ['2015-07-27', 52.58],
                ['2015-07-28', 53.34],
                ['2015-07-29', 53.62],
                ['2015-07-30', 53.59],
                ['2015-07-31', 53.94],
                ['2015-08-03', 53.69],
                ['2015-08-04', 53.48],
                ['2015-08-05', 53.89],
                ['2015-08-06', 53.21],
                ['2015-08-07', 53.29],
                ['2015-08-10', 54.30],
                ['2015-08-11', 54.02],
                ['2015-08-12', 54.37],
                ['2015-08-13', 54.09],
                ['2015-08-14', 54.16],
                ['2015-08-17', 54.58],
                ['2015-08-18', 54.23],
                ['2015-08-19', 54.20],
                ['2015-08-20', 53.32],
                ['2015-08-21', 52.04],
                ['2015-08-24', 50.01],
                ['2015-08-25', 48.96],
                ['2015-08-26', 50.56],
                ['2015-08-27', 51.91],
                ['2015-08-28', 52.15],
                ['2015-08-31', 51.75],
                ['2015-09-01', 50.21],
                ['2015-09-02', 50.96],
                ['2015-09-03', 51.04],
                ['2015-09-04', 50.19],
                ['2015-09-08', 51.73],
                ['2015-09-09', 50.61],
                ['2015-09-10', 50.83],
                ['2015-09-11', 51.09],
                ['2015-09-14', 50.94],
                ['2015-09-15', 51.46],
                ['2015-09-16', 52.03],
                ['2015-09-17', 52.35],
                ['2015-09-18', 51.88],
                ['2015-09-21', 51.81],
                ['2015-09-22', 50.93],
                ['2015-09-23', 50.92],
                ['2015-09-24', 51.20],
                ['2015-09-25', 50.54],
                ['2015-09-28', 48.79],
                ['2015-09-29', 49.01],
                ['2015-09-30', 49.92],
                ['2015-10-01', 50.07],
                ['2015-10-02', 51.48],
                ['2015-10-05', 52.08],
                ['2015-10-06', 51.80],
                ['2015-10-07', 52.34],
                ['2015-10-08', 52.60],
                ['2015-10-09', 53.02],
                ['2015-10-12', 53.20],
                ['2015-10-13', 52.90],
                ['2015-10-14', 53.47],
                ['2015-10-15', 54.13],
                ['2015-10-16', 54.35],
                ['2015-10-19', 54.40],
                ['2015-10-20', 54.31],
                ['2015-10-21', 54.01],
                ['2015-10-22', 54.74],
                ['2015-10-23', 54.98],
                ['2015-10-26', 54.66],
                ['2015-10-27', 54.64],
                ['2015-10-28', 55.05],
                ['2015-10-29', 54.49],
                ['2015-10-30', 54.45],
                ['2015-11-02', 54.92],
                ['2015-11-03', 54.73],
                ['2015-11-04', 54.50],
                ['2015-11-05', 54.11],
                ['2015-11-06', 53.97],
                ['2015-11-09', 53.56],
                ['2015-11-10', 53.54],
                ['2015-11-11', 53.35],
                ['2015-11-12', 52.59],
                ['2015-11-13', 52.16],
                ['2015-11-16', 52.95],
                ['2015-11-17', 52.73],
                ['2015-11-18', 53.39],
                ['2015-11-19', 53.47],
                ['2015-11-20', 53.62],
                ['2015-11-23', 53.32],
                ['2015-11-24', 53.64],
                ['2015-11-25', 53.60],
                ['2015-11-27', 53.57],
                ['2015-11-30', 53.67],
                ['2015-12-01', 54.47],
                ['2015-12-02', 53.73],
                ['2015-12-03', 53.13],
                ['2015-12-04', 54.66],
                ['2015-12-07', 54.20],
                ['2015-12-08', 54.04],
                ['2015-12-09', 53.42],
                ['2015-12-10', 53.50],
                ['2015-12-11', 52.87],
                ['2015-12-14', 52.69],
                ['2015-12-15', 53.21],
                ['2015-12-16', 54.15],
                ['2015-12-17', 53.12],
                ['2015-12-18', 52.61],
                ['2015-12-21', 53.35],
                ['2015-12-22', 53.64],
                ['2015-12-23', 54.09],
                ['2015-12-24', 53.95],
                ['2015-12-28', 53.72],
                ['2015-12-29', 54.19],
                ['2015-12-30', 53.74],
                ['2015-12-31', 53.58],
                ['2016-01-04', 53.08],
                ['2016-01-05', 53.32],
                ['2016-01-06', 52.91],
                ['2016-01-07', 52.04],
                ['2016-01-08', 51.31],
                ['2016-01-11', 51.07],
                ['2016-01-12', 51.47],
                ['2016-01-13', 50.40],
                ['2016-01-14', 50.79],
                ['2016-01-15', 49.81],
                ['2016-01-19', 49.98],
                ['2016-01-20', 49.82],
                ['2016-01-21', 50.08],
                ['2016-01-22', 51.07],
                ['2016-01-25', 50.83],
                ['2016-01-26', 51.63],
                ['2016-01-27', 51.33],
                ['2016-01-28', 50.78],
                ['2016-01-29', 52.59],
                ['2016-02-01', 53.05],
                ['2016-02-02', 52.25],
                ['2016-02-03', 52.56],
                ['2016-02-04', 52.97],
                ['2016-02-05', 52.14],
                ['2016-02-08', 51.49],
                ['2016-02-09', 51.41],
                ['2016-02-10', 51.72],
                ['2016-02-11', 51.95],
                ['2016-02-12', 52.60],
                ['2016-02-16', 53.20],
                ['2016-02-17', 54.10],
                ['2016-02-18', 54.57],
                ['2016-02-19', 54.58],
                ['2016-02-22', 54.85],
                ['2016-02-23', 54.70],
                ['2016-02-24', 55.18],
                ['2016-02-25', 56.02],
                ['2016-02-26', 55.74],
                ['2016-02-29', 55.82],
                ['2016-03-01', 56.64],
                ['2016-03-02', 57.05],
                ['2016-03-03', 57.56],
                ['2016-03-04', 57.76],
                ['2016-03-07', 57.93],
                ['2016-03-08', 57.30],
                ['2016-03-09', 57.30],
                ['2016-03-10', 57.70],
                ['2016-03-11', 58.26],
                ['2016-03-14', 58.01],
                ['2016-03-15', 57.78],
                ['2016-03-16', 58.52],
                ['2016-03-17', 58.62],
                ['2016-03-18', 59.00],
                ['2016-03-21', 58.86],
                ['2016-03-22', 58.92],
                ['2016-03-23', 58.00],
                ['2016-03-24', 57.84],
                ['2016-03-28', 57.97],
                ['2016-03-29', 59.26],
                ['2016-03-30', 59.42],
                ['2016-03-31', 59.65],
                ['2016-04-01', 59.99],
                ['2016-04-04', 59.87],
                ['2016-04-05', 59.49],
                ['2016-04-06', 59.97],
                ['2016-04-07', 59.80],
                ['2016-04-08', 59.84],
                ['2016-04-11', 59.96],
                ['2016-04-12', 60.21],
                ['2016-04-13', 60.50],
                ['2016-04-14', 59.98],
                ['2016-04-15', 60.11],
                ['2016-04-18', 60.36],
                ['2016-04-19', 60.57],
                ['2016-04-20', 60.48],
                ['2016-04-21', 60.08],
                ['2016-04-22', 60.03],
                ['2016-04-25', 60.04],
                ['2016-04-26', 60.26],
                ['2016-04-27', 60.73],
                ['2016-04-28', 60.51],
                ['2016-04-29', 60.35],
                ['2016-05-02', 60.75],
                ['2016-05-03', 60.32],
                ['2016-05-04', 59.98],
                ['2016-05-05', 60.09],
                ['2016-05-06', 60.39],
                ['2016-05-09', 60.18],
                ['2016-05-10', 60.80],
                ['2016-05-11', 60.68],
                ['2016-05-12', 60.27],
                ['2016-05-13', 60.16],
                ['2016-05-16', 60.86],
                ['2016-05-17', 60.21],
                ['2016-05-18', 59.87],
                ['2016-05-19', 59.62],
                ['2016-05-20', 60.24],
                ['2016-05-23', 60.18],
                ['2016-05-24', 60.68],
                ['2016-05-25', 60.81],
                ['2016-05-26', 60.87],
                ['2016-05-27', 60.90],
                ['2016-05-31', 61.03],
                ['2016-06-01', 61.20],
                ['2016-06-02', 61.47],
                ['2016-06-03', 62.18],
                ['2016-06-06', 62.13],
                ['2016-06-07', 62.33],
                ['2016-06-08', 62.96],
                ['2016-06-09', 63.28],
                ['2016-06-10', 62.87],
                ['2016-06-13', 62.66],
                ['2016-06-14', 62.64],
                ['2016-06-15', 62.53],
                ['2016-06-16', 62.61],
                ['2016-06-17', 62.49],
                ['2016-06-20', 62.81],
                ['2016-06-21', 62.37],
                ['2016-06-22', 62.38],
                ['2016-06-23', 63.12],
                ['2016-06-24', 62.31],
                ['2016-06-27', 61.66],
                ['2016-06-28', 62.42],
                ['2016-06-29', 63.51],
                ['2016-06-30', 64.63],
                ['2016-07-01', 64.94],
                ['2016-07-05', 64.94],
                ['2016-07-06', 65.29],
                ['2016-07-07', 65.29],
                ['2016-07-08', 66.38],
                ['2016-07-11', 66.39],
                ['2016-07-12', 66.15],
                ['2016-07-13', 66.51],
                ['2016-07-14', 66.55],
                ['2016-07-15', 66.34],
                ['2016-07-18', 66.54],
                ['2016-07-19', 66.55],
                ['2016-07-20', 66.65],
                ['2016-07-21', 66.74],
                ['2016-07-22', 66.80],
                ['2016-07-25', 66.62],
                ['2016-07-26', 67.41],
                ['2016-07-27', 67.50],
                ['2016-07-28', 67.54],
                ['2016-07-29', 67.95],
                ['2016-08-01', 68.08],
                ['2016-08-02', 67.75],
                ['2016-08-03', 67.36],
                ['2016-08-04', 67.70],
                ['2016-08-05', 67.60],
                ['2016-08-08', 67.46],
                ['2016-08-09', 67.90],
                ['2016-08-10', 67.93],
                ['2016-08-11', 67.90],
                ['2016-08-12', 67.85],
                ['2016-08-15', 68.04],
                ['2016-08-16', 67.64],
                ['2016-08-17', 67.68],
                ['2016-08-18', 68.06],
                ['2016-08-19', 67.90],
                ['2016-08-22', 68.10],
                ['2016-08-23', 68.17],
                ['2016-08-24', 67.39],
                ['2016-08-25', 67.36],
                ['2016-08-26', 67.29],
                ['2016-08-29', 67.80],
                ['2016-08-30', 67.33],
                ['2016-08-31', 67.21],
                ['2016-09-01', 67.45],
                ['2016-09-02', 67.97],
                ['2016-09-06', 68.47],
                ['2016-09-07', 68.29],
                ['2016-09-08', 67.95],
                ['2016-09-09', 65.93],
                ['2016-09-12', 66.83],
                ['2016-09-13', 65.79],
                ['2016-09-14', 66.00],
                ['2016-09-15', 66.71],
                ['2016-09-16', 66.63],
                ['2016-09-19', 66.74],
                ['2016-09-20', 66.77],
                ['2016-09-21', 67.90],
                ['2016-09-22', 68.39],
                ['2016-09-23', 68.03],
                ['2016-09-26', 67.31],
                ['2016-09-27', 67.64],
                ['2016-09-28', 67.60],
                ['2016-09-29', 67.25],
                ['2016-09-30', 68.16],
                ['2016-10-03', 67.69],
                ['2016-10-04', 66.43],
                ['2016-10-05', 66.48],
                ['2016-10-06', 66.39],
                ['2016-10-07', 66.30],
                ['2016-10-10', 66.55],
                ['2016-10-11', 65.30],
                ['2016-10-12', 65.40],
                ['2016-10-13', 65.28],
                ['2016-10-14', 65.15],
                ['2016-10-17', 65.05],
                ['2016-10-18', 65.66],
                ['2016-10-19', 65.63],
                ['2016-10-20', 65.66],
                ['2016-10-21', 65.49],
                ['2016-10-24', 65.86],
                ['2016-10-25', 65.71],
                ['2016-10-26', 65.04],
                ['2016-10-27', 64.60],
                ['2016-10-28', 64.71],
                ['2016-10-31', 65.15],
                ['2016-11-01', 64.64],
                ['2016-11-02', 64.54],
                ['2016-11-03', 64.57],
                ['2016-11-04', 64.59],
                ['2016-11-07', 65.41],
                ['2016-11-08', 65.59],
                ['2016-11-09', 65.07],
                ['2016-11-10', 64.17],
                ['2016-11-11', 64.04],
                ['2016-11-14', 63.34],
                ['2016-11-15', 64.31],
                ['2016-11-16', 64.52],
                ['2016-11-17', 64.82],
                ['2016-11-18', 64.55],
                ['2016-11-21', 64.90],
                ['2016-11-22', 64.81],
                ['2016-11-23', 64.48],
                ['2016-11-25', 64.60],
                ['2016-11-28', 64.70],
                ['2016-11-29', 64.72],
                ['2016-11-30', 63.77],
                ['2016-12-01', 62.48],
                ['2016-12-02', 62.96],
                ['2016-12-05', 63.18],
                ['2016-12-06', 63.54],
                ['2016-12-07', 64.29],
                ['2016-12-08', 64.49],
                ['2016-12-09', 64.47],
                ['2016-12-12', 64.59],
                ['2016-12-13', 64.91],
                ['2016-12-14', 64.12],
                ['2016-12-15', 64.19],
                ['2016-12-16', 64.24],
                ['2016-12-19', 64.64],
                ['2016-12-20', 64.59],
                ['2016-12-21', 64.37],
                ['2016-12-22', 64.21],
                ['2016-12-23', 64.45],
                ['2016-12-27', 64.82],
                ['2016-12-28', 64.40],
                ['2016-12-29', 65.11],
                ['2016-12-30', 64.77],
                ['2017-01-03', 65.13],
                ['2017-01-04', 65.49],
                ['2017-01-05', 65.78],
                ['2017-01-06', 65.83],
                ['2017-01-09', 66.20],
                ['2017-01-10', 66.52],
                ['2017-01-11', 66.82],
                ['2017-01-12', 66.61],
                ['2017-01-13', 66.88],
                ['2017-01-17', 67.14],
                ['2017-01-18', 67.09],
                ['2017-01-19', 66.68],
                ['2017-01-20', 67.01],
                ['2017-01-23', 67.00],
                ['2017-01-24', 67.28],
                ['2017-01-25', 67.53],
                ['2017-01-26', 67.10],
                ['2017-01-27', 67.53],
                ['2017-01-30', 67.31],
                ['2017-01-31', 67.81],
                ['2017-02-01', 67.78],
                ['2017-02-02', 68.11],
                ['2017-02-03', 68.56],
                ['2017-02-06', 68.80],
                ['2017-02-07', 68.95],
                ['2017-02-08', 69.21],
                ['2017-02-09', 69.07],
                ['2017-02-10', 69.23],
                ['2017-02-13', 69.26],
                ['2017-02-14', 69.32],
                ['2017-02-15', 69.75],
                ['2017-02-16', 70.05],
                ['2017-02-17', 70.18],
                ['2017-02-21', 70.75],
                ['2017-02-22', 70.79],
                ['2017-02-23', 71.01],
                ['2017-02-24', 71.42],
                ['2017-02-27', 71.36],
                ['2017-02-28', 71.18],
                ['2017-03-01', 71.75],
                ['2017-03-02', 71.15],
                ['2017-03-03', 71.06],
                ['2017-03-06', 70.71],
                ['2017-03-07', 70.47],
                ['2017-03-08', 70.23],
                ['2017-03-09', 70.21],
                ['2017-03-10', 70.62],
                ['2017-03-13', 70.76],
                ['2017-03-14', 70.41],
                ['2017-03-15', 71.49],
                ['2017-03-16', 71.25],
                ['2017-03-17', 71.56],
                ['2017-03-20', 71.68],
                ['2017-03-21', 71.28],
                ['2017-03-22', 71.60],
                ['2017-03-23', 71.47],
                ['2017-03-24', 71.51],
                ['2017-03-27', 71.63],
                ['2017-03-28', 71.64],
                ['2017-03-29', 71.71],
                ['2017-03-30', 71.82],
                ['2017-03-31', 71.98],
                ['2017-04-03', 72.03],
                ['2017-04-04', 71.92],
                ['2017-04-05', 71.75],
                ['2017-04-06', 71.68],
                ['2017-04-07', 71.79],
                ['2017-04-10', 71.71],
                ['2017-04-11', 72.00],
                ['2017-04-12', 71.84],
                ['2017-04-13', 71.68],
                ['2017-04-17', 72.08],
                ['2017-04-18', 72.29],
                ['2017-04-19', 72.26],
                ['2017-04-20', 72.68],
                ['2017-04-21', 72.59],
                ['2017-04-24', 73.04],
                ['2017-04-25', 73.12],
                ['2017-04-26', 73.28],
                ['2017-04-27', 73.47],
                ['2017-04-28', 73.37],
                ['2017-05-01', 73.31],
                ['2017-05-02', 73.17],
                ['2017-05-03', 72.69],
                ['2017-05-04', 72.54],
                ['2017-05-05', 72.78],
                ['2017-05-08', 72.45],
                ['2017-05-09', 72.43],
                ['2017-05-10', 72.80],
                ['2017-05-11', 72.85],
                ['2017-05-12', 72.93],
                ['2017-05-15', 73.45],
                ['2017-05-16', 73.60],
                ['2017-05-17', 73.11],
                ['2017-05-18', 73.31],
                ['2017-05-19', 73.76],
                ['2017-05-22', 74.31],
                ['2017-05-23', 74.12],
                ['2017-05-24', 74.50],
                ['2017-05-25', 74.91],
                ['2017-05-26', 75.14],
                ['2017-05-30', 75.27],
                ['2017-05-31', 75.50],
                ['2017-06-01', 75.94],
                ['2017-06-02', 76.45],
                ['2017-06-05', 76.44],
                ['2017-06-06', 76.58],
                ['2017-06-07', 76.68],
                ['2017-06-08', 76.65],
                ['2017-06-09', 75.66],
                ['2017-06-12', 75.45],
                ['2017-06-13', 75.82],
                ['2017-06-14', 75.70],
                ['2017-06-15', 75.45],
                ['2017-06-16', 75.44],
                ['2017-06-19', 75.88],
                ['2017-06-20', 75.53],
                ['2017-06-21', 75.73],
                ['2017-06-22', 75.76],
                ['2017-06-23', 75.96],
                ['2017-06-26', 75.62],
                ['2017-06-27', 74.68],
                ['2017-06-28', 75.16],
                ['2017-06-29', 74.23],
                ['2017-06-30', 74.63],
                ['2017-07-03', 73.92],
                ['2017-07-05', 74.47],
                ['2017-07-06', 73.91],
                ['2017-07-07', 74.28],
                ['2017-07-10', 74.37],
                ['2017-07-11', 74.51],
                ['2017-07-12', 75.15],
                ['2017-07-13', 74.94],
                ['2017-07-14', 75.71],
                ['2017-07-17', 75.70],
                ['2017-07-18', 75.88],
                ['2017-07-19', 76.22],
                ['2017-07-20', 76.41],
                ['2017-07-21', 76.44],
                ['2017-07-24', 76.41],
                ['2017-07-25', 76.30],
                ['2017-07-26', 76.57],
                ['2017-07-27', 76.21],
                ['2017-07-28', 76.30],
                ['2017-07-31', 76.28],
                ['2017-08-01', 76.39],
                ['2017-08-02', 76.04],
                ['2017-08-03', 76.02],
                ['2017-08-04', 75.80],
                ['2017-08-07', 76.13],
                ['2017-08-08', 75.94],
                ['2017-08-09', 76.10],
                ['2017-08-10', 75.52],
                ['2017-08-11', 75.88],
                ['2017-08-14', 76.52],
                ['2017-08-15', 76.36],
                ['2017-08-16', 76.73],
                ['2017-08-17', 76.00],
                ['2017-08-18', 75.89],
                ['2017-08-21', 76.05],
                ['2017-08-22', 76.50],
                ['2017-08-23', 76.37],
                ['2017-08-24', 76.20],
                ['2017-08-25', 76.32],
                ['2017-08-28', 76.79],
                ['2017-08-29', 76.79],
                ['2017-08-30', 77.15],
                ['2017-08-31', 77.99],
                ['2017-09-01', 78.08],
                ['2017-09-05', 78.06],
                ['2017-09-06', 78.07],
                ['2017-09-07', 78.50],
                ['2017-09-08', 78.55],
                ['2017-09-11', 78.81],
                ['2017-09-12', 78.96],
                ['2017-09-13', 78.57],
                ['2017-09-14', 78.73],
                ['2017-09-15', 78.89],
                ['2017-09-18', 78.94],
                ['2017-09-19', 78.79],
                ['2017-09-20', 78.34],
                ['2017-09-21', 77.78],
                ['2017-09-22', 78.04],
                ['2017-09-25', 77.95],
                ['2017-09-26', 77.41],
                ['2017-09-27', 77.53],
                ['2017-09-28', 77.63],
                ['2017-09-29', 78.20],
                ['2017-10-02', 78.38],
                ['2017-10-03', 78.45],
                ['2017-10-04', 78.75],
                ['2017-10-05', 78.79],
                ['2017-10-06', 78.89],
                ['2017-10-09', 78.87],
                ['2017-10-10', 79.15],
                ['2017-10-11', 79.41],
                ['2017-10-12', 79.51],
                ['2017-10-13', 79.82],
                ['2017-10-16', 79.63],
                ['2017-10-17', 79.58],
                ['2017-10-18', 79.64],
                ['2017-10-19', 79.98],
                ['2017-10-20', 80.09],
                ['2017-10-23', 80.15],
                ['2017-10-24', 80.02],
                ['2017-10-25', 79.73],
                ['2017-10-26', 79.59],
                ['2017-10-27', 80.36],
                ['2017-10-30', 80.14],
                ['2017-10-31', 80.31],
                ['2017-11-01', 80.19],
                ['2017-11-02', 80.36],
                ['2017-11-03', 80.74],
                ['2017-11-06', 81.01],
                ['2017-11-07', 81.02],
                ['2017-11-08', 81.23],
                ['2017-11-09', 80.97],
                ['2017-11-10', 80.66],
                ['2017-11-13', 80.91],
                ['2017-11-14', 81.02],
                ['2017-11-15', 80.57],
                ['2017-11-16', 81.17],
                ['2017-11-17', 81.27],
                ['2017-11-20', 81.18],
                ['2017-11-21', 81.73],
                ['2017-11-22', 81.75],
                ['2017-11-24', 81.96],
                ['2017-11-27', 81.84],
                ['2017-11-28', 82.14],
                ['2017-11-29', 81.26],
                ['2017-11-30', 81.57],
                ['2017-12-01', 81.40],
                ['2017-12-04', 80.55],
                ['2017-12-05', 80.24],
                ['2017-12-06', 80.30],
                ['2017-12-07', 80.22],
                ['2017-12-08', 80.32],
                ['2017-12-11', 80.36],
                ['2017-12-12', 80.22],
                ['2017-12-13', 80.48],
                ['2017-12-14', 80.11],
                ['2017-12-15', 80.91],
                ['2017-12-18', 81.49],
                ['2017-12-19', 81.22],
                ['2017-12-20', 81.26],
                ['2017-12-21', 80.88],
                ['2017-12-22', 80.97],
                ['2017-12-26', 80.98],
                ['2017-12-27', 81.30],
                ['2017-12-28', 81.67],
                ['2017-12-29', 81.61],
                ['2018-01-02', 82.52],
                ['2018-01-03', 83.02],
                ['2018-01-04', 83.33],
                ['2018-01-05', 83.73],
                ['2018-01-08', 83.97],
                ['2018-01-09', 83.81],
                ['2018-01-10', 83.45],
                ['2018-01-11', 83.81],
                ['2018-01-12', 84.27],
                ['2018-01-16', 84.27],
                ['2018-01-17', 84.98],
                ['2018-01-18', 84.95],
                ['2018-01-19', 85.35],
                ['2018-01-22', 85.73],
                ['2018-01-23', 86.07],
                ['2018-01-24', 86.12],
                ['2018-01-25', 86.10],
                ['2018-01-26', 86.96],
                ['2018-01-29', 86.45],
                ['2018-01-30', 85.58],
                ['2018-01-31', 85.92],
                ['2018-02-01', 85.75],
                ['2018-02-02', 84.10],
                ['2018-02-05', 81.76],
                ['2018-02-06', 82.43],
                ['2018-02-07', 81.59],
                ['2018-02-08', 79.42],
                ['2018-02-09', 80.53],
                ['2018-02-12', 81.34],
                ['2018-02-13', 81.54],
                ['2018-02-14', 82.74],
                ['2018-02-15', 83.60],
                ['2018-02-16', 83.66],
                ['2018-02-20', 83.25],
                ['2018-02-21', 82.63],
                ['2018-02-22', 82.73],
                ['2018-02-23', 83.67],
                ['2018-02-26', 84.52],
                ['2018-02-27', 83.57],
                ['2018-02-28', 83.15],
                ['2018-03-01', 82.28],
                ['2018-03-02', 82.96],
                ['2018-03-05', 83.54],
                ['2018-03-06', 84.23],
                ['2018-03-07', 84.33],
                ['2018-03-08', 84.55],
                ['2018-03-09', 85.50],
                ['2018-03-12', 85.59],
                ['2018-03-13', 85.28],
                ['2018-03-14', 85.06],
                ['2018-03-15', 84.85],
                ['2018-03-16', 84.80],
                ['2018-03-19', 84.07],
                ['2018-03-20', 84.13],
                ['2018-03-21', 84.35],
                ['2018-03-22', 82.82],
                ['2018-03-23', 82.04],
                ['2018-03-26', 83.76],
                ['2018-03-27', 82.59],
                ['2018-03-28', 82.01],
                ['2018-03-29', 82.96],
                ['2018-04-02', 81.75],
                ['2018-04-03', 82.31],
                ['2018-04-04', 82.95],
                ['2018-04-05', 82.82],
                ['2018-04-06', 81.55],
                ['2018-04-09', 81.95],
                ['2018-04-10', 83.09],
                ['2018-04-11', 83.01],
                ['2018-04-12', 83.25],
                ['2018-04-13', 83.26],
                ['2018-04-16', 83.77],
                ['2018-04-17', 84.60],
                ['2018-04-18', 84.50],
                ['2018-04-19', 83.33],
                ['2018-04-20', 82.63],
                ['2018-04-23', 82.23],
                ['2018-04-24', 81.73],
                ['2018-04-25', 81.59],
                ['2018-04-26', 82.31],
                ['2018-04-27', 82.37],
                ['2018-04-30', 81.88],
                ['2018-05-01', 82.07],
                ['2018-05-02', 81.52],
                ['2018-05-03', 81.69],
                ['2018-05-04', 82.61],
                ['2018-05-07', 82.91],
                ['2018-05-08', 82.79],
                ['2018-05-09', 83.14],
                ['2018-05-10', 84.13],
                ['2018-05-11', 84.06],
                ['2018-05-14', 84.17],
                ['2018-05-15', 83.08],
                ['2018-05-16', 83.37],
                ['2018-05-17', 83.30],
                ['2018-05-18', 83.15],
                ['2018-05-21', 83.61],
                ['2018-05-22', 83.43],
                ['2018-05-23', 83.83],
                ['2018-05-24', 84.15],
                ['2018-05-25', 84.25],
                ['2018-05-29', 84.02],
                ['2018-05-30', 84.64],
                ['2018-05-31', 84.19],
                ['2018-06-01', 84.72],
                ['2018-06-04', 85.06],
                ['2018-06-05', 85.25],
                ['2018-06-06', 85.61],
                ['2018-06-07', 85.42],
                ['2018-06-08', 85.55],
                ['2018-06-11', 85.51],
                ['2018-06-12', 85.63],
                ['2018-06-13', 85.48],
                ['2018-06-14', 85.86],
                ['2018-06-15', 85.56],
                ['2018-06-18', 85.17],
                ['2018-06-19', 84.83],
                ['2018-06-20', 84.74],
                ['2018-06-21', 84.34],
                ['2018-06-22', 84.45],
                ['2018-06-25', 83.54],
                ['2018-06-26', 83.25],
                ['2018-06-27', 82.28],
                ['2018-06-28', 82.71],
                ['2018-06-29', 82.94],
                ['2018-07-02', 83.19],
                ['2018-07-03', 83.19],
                ['2018-07-05', 84.06],
                ['2018-07-06', 84.55],
                ['2018-07-09', 84.84],
                ['2018-07-10', 85.08],
                ['2018-07-11', 84.28],
                ['2018-07-12', 84.83],
                ['2018-07-13', 84.69],
                ['2018-07-16', 84.39],
                ['2018-07-17', 84.67],
                ['2018-07-18', 84.78],
                ['2018-07-19', 84.72],
                ['2018-07-20', 84.71],
                ['2018-07-23', 84.62],
                ['2018-07-24', 84.44],
                ['2018-07-25', 85.08],
                ['2018-07-26', 85.24],
                ['2018-07-27', 84.80],
                ['2018-07-30', 84.25],
                ['2018-07-31', 84.92],
                ['2018-08-01', 84.57],
                ['2018-08-02', 84.87],
                ['2018-08-03', 85.14],
                ['2018-08-06', 85.20],
                ['2018-08-07', 85.36],
                ['2018-08-08', 85.48],
                ['2018-08-09', 85.26],
                ['2018-08-10', 84.62],
                ['2018-08-13', 84.06],
                ['2018-08-14', 84.31],
                ['2018-08-15', 83.66],
                ['2018-08-16', 83.98],
                ['2018-08-17', 84.25],
                ['2018-08-20', 84.47],
                ['2018-08-21', 84.86],
                ['2018-08-22', 85.09],
                ['2018-08-23', 84.93],
                ['2018-08-24', 85.84],
                ['2018-08-27', 86.28],
                ['2018-08-28', 86.17],
                ['2018-08-29', 86.58],
                ['2018-08-30', 86.18],
                ['2018-08-31', 86.48],
                ['2018-09-04', 86.36],
                ['2018-09-05', 86.29],
                ['2018-09-06', 86.11],
                ['2018-09-07', 85.79],
                ['2018-09-10', 86.05],
                ['2018-09-11', 85.98],
                ['2018-09-12', 86.13],
                ['2018-09-13', 86.62],
                ['2018-09-14', 86.69],
                ['2018-09-17', 86.28],
                ['2018-09-18', 86.57],
                ['2018-09-19', 86.40],
                ['2018-09-20', 86.98],
                ['2018-09-21', 86.88],
                ['2018-09-24', 86.91],
                ['2018-09-25', 86.69],
                ['2018-09-26', 86.07],
                ['2018-09-27', 86.09],
                ['2018-09-28', 86.64],
                ['2018-10-01', 86.62],
                ['2018-10-02', 86.96],
                ['2018-10-03', 86.52],
                ['2018-10-04', 85.77],
                ['2018-10-05', 85.38],
                ['2018-10-08', 84.75],
                ['2018-10-09', 84.79],
                ['2018-10-10', 82.87],
                ['2018-10-11', 82.51],
                ['2018-10-12', 83.33],
                ['2018-10-15', 83.26],
                ['2018-10-16', 84.83],
                ['2018-10-17', 84.76],
                ['2018-10-18', 83.90],
                ['2018-10-19', 83.41],
                ['2018-10-22', 83.35],
                ['2018-10-23', 83.20],
                ['2018-10-24', 81.37],
                ['2018-10-25', 82.01],
                ['2018-10-26', 81.36],
                ['2018-10-29', 81.05],
                ['2018-10-30', 82.26],
                ['2018-10-31', 82.67],
                ['2018-11-01', 84.06],
                ['2018-11-02', 83.71],
                ['2018-11-05', 83.79],
                ['2018-11-06', 84.24],
                ['2018-11-07', 85.28],
                ['2018-11-08', 85.23],
                ['2018-11-09', 84.55],
                ['2018-11-12', 82.81],
                ['2018-11-13', 82.88],
                ['2018-11-14', 82.92],
                ['2018-11-15', 84.01],
                ['2018-11-16', 84.31],
                ['2018-11-19', 82.89],
                ['2018-11-20', 82.36],
                ['2018-11-21', 82.48],
                ['2018-11-23', 82.47],
                ['2018-11-26', 83.18],
                ['2018-11-27', 83.15],
                ['2018-11-28', 84.50],
                ['2018-11-29', 84.54],
                ['2018-11-30', 85.10],
                ['2018-12-03', 86.08],
                ['2018-12-04', 84.40],
                ['2018-12-06', 84.47],
                ['2018-12-07', 83.12],
                ['2018-12-10', 83.52],
                ['2018-12-11', 83.68],
                ['2018-12-12', 84.11],
                ['2018-12-13', 84.01],
                ['2018-12-14', 82.94],
                ['2018-12-17', 81.62],
                ['2018-12-18', 81.80],
                ['2018-12-19', 80.62],
                ['2018-12-20', 80.08],
                ['2018-12-21', 79.32],
                ['2018-12-24', 77.92],
                ['2018-12-26', 80.44],
                ['2018-12-27', 81.37],
                ['2018-12-28', 81.53],
                ['2018-12-31', 82.34],
                ['2019-01-02', 81.78],
                ['2019-01-03', 80.33],
                ['2019-01-04', 82.10],
                ['2019-01-07', 82.58],
                ['2019-01-08', 82.82],
                ['2019-01-09', 83.70],
                ['2019-01-10', 84.04],
                ['2019-01-11', 84.25],
                ['2019-01-14', 83.62],
                ['2019-01-15', 84.32],
                ['2019-01-16', 84.21],
                ['2019-01-17', 84.68],
                ['2019-01-18', 85.42],
                ['2019-01-22', 84.71],
                ['2019-01-23', 84.81],
                ['2019-01-24', 85.79],
                ['2019-01-25', 86.55],
                ['2019-01-28', 86.00],
                ['2019-01-29', 86.00],
                ['2019-01-30', 87.32],
                ['2019-01-31', 87.97],
                ['2019-02-01', 88.02],
                ['2019-02-04', 88.26],
                ['2019-02-05', 88.52],
                ['2019-02-06', 88.82],
                ['2019-02-07', 88.34],
                ['2019-02-08', 88.61],
                ['2019-02-11', 88.71],
                ['2019-02-12', 89.51],
                ['2019-02-13', 89.43],
                ['2019-02-14', 89.57],
                ['2019-02-15', 90.35],
                ['2019-02-19', 90.75],
                ['2019-02-20', 90.94],
                ['2019-02-21', 90.47],
                ['2019-02-22', 90.98],
                ['2019-02-25', 91.03],
                ['2019-02-26', 90.85],
                ['2019-02-27', 90.57],
                ['2019-02-28', 90.61],
                ['2019-03-01', 90.78],
                ['2019-03-04', 90.45],
                ['2019-03-05', 90.38],
                ['2019-03-06', 89.71],
                ['2019-03-07', 89.30],
                ['2019-03-08', 89.48],
                ['2019-03-11', 90.35],
                ['2019-03-12', 90.73],
                ['2019-03-13', 91.24],
                ['2019-03-14', 90.79],
                ['2019-03-15', 91.68],
                ['2019-03-18', 91.52],
                ['2019-03-19', 91.85],
                ['2019-03-20', 91.56],
                ['2019-03-21', 92.65],
                ['2019-03-22', 91.66],
                ['2019-03-25', 91.79],
                ['2019-03-26', 92.35],
                ['2019-03-27', 91.76],
                ['2019-03-28', 91.53],
                ['2019-03-29', 92.43],
                ['2019-04-01', 92.77],
                ['2019-04-02', 92.82],
                ['2019-04-03', 93.06],
                ['2019-04-04', 93.15],
                ['2019-04-05', 93.44],
                ['2019-04-08', 93.55],
                ['2019-04-09', 93.39],
                ['2019-04-10', 93.73],
                ['2019-04-11', 93.25],
                ['2019-04-12', 93.48],
                ['2019-04-15', 93.26],
                ['2019-04-16', 93.05],
                ['2019-04-17', 92.37],
                ['2019-04-18', 92.54],
                ['2019-04-22', 92.44],
                ['2019-04-23', 93.10],
                ['2019-04-24', 93.46],
                ['2019-04-25', 93.33],
                ['2019-04-26', 93.64],
                ['2019-04-29', 93.28],
                ['2019-04-30', 94.20],
                ['2019-05-01', 93.40],
                ['2019-05-02', 93.64],
                ['2019-05-03', 94.25],
                ['2019-05-06', 94.12],
                ['2019-05-07', 93.14],
                ['2019-05-08', 92.83],
                ['2019-05-09', 92.64],
                ['2019-05-10', 92.97],
                ['2019-05-13', 91.76],
                ['2019-05-14', 92.40],
                ['2019-05-15', 92.72],
                ['2019-05-16', 92.61],
                ['2019-05-17', 91.90],
                ['2019-05-20', 91.12],
                ['2019-05-21', 91.72],
                ['2019-05-22', 91.54],
                ['2019-05-23', 91.37],
                ['2019-05-24', 91.39],
                ['2019-05-28', 90.67],
                ['2019-05-29', 90.53],
                ['2019-05-30', 91.22],
                ['2019-05-31', 91.33],
                ['2019-06-03', 91.89],
                ['2019-06-04', 93.25],
                ['2019-06-05', 93.87],
                ['2019-06-06', 94.47],
                ['2019-06-07', 95.22],
                ['2019-06-10', 95.41],
                ['2019-06-11', 95.27],
                ['2019-06-12', 95.26],
                ['2019-06-13', 95.55],
                ['2019-06-14', 95.13],
                ['2019-06-17', 94.71],
                ['2019-06-18', 95.93],
                ['2019-06-19', 96.53],
                ['2019-06-20', 97.63],
                ['2019-06-21', 97.78],
                ['2019-06-24', 98.15],
                ['2019-06-25', 97.68],
                ['2019-06-26', 97.37],
                ['2019-06-27', 97.88],
                ['2019-06-28', 98.54],
                ['2019-07-01', 98.75],
                ['2019-07-02', 99.29],
                ['2019-07-03', 99.80],
                ['2019-07-05', 99.20],
                ['2019-07-08', 98.75],
                ['2019-07-09', 99.02],
                ['2019-07-10', 99.75],
                ['2019-07-11', 99.61],
                ['2019-07-12', 99.96],
                ['2019-07-15', 100.25],
                ['2019-07-16', 99.58],
                ['2019-07-17', 100.15],
                ['2019-07-18', 101.21],
                ['2019-07-19', 100.30],
                ['2019-07-22', 100.72],
                ['2019-07-23', 100.97],
                ['2019-07-24', 101.79],
                ['2019-07-25', 101.30],
                ['2019-07-26', 101.66],
                ['2019-07-29', 102.02],
                ['2019-07-30', 101.93],
                ['2019-07-31', 100.68],
                ['2019-08-01', 100.99],
                ['2019-08-02', 100.49],
                ['2019-08-05', 98.89],
                ['2019-08-06', 100.22],
                ['2019-08-07', 100.89],
                ['2019-08-08', 102.37],
                ['2019-08-09', 101.82],
                ['2019-08-12', 101.37],
                ['2019-08-13', 102.28],
                ['2019-08-14', 100.77],
                ['2019-08-15', 101.40],
                ['2019-08-16', 102.17],
                ['2019-08-19', 102.58],
                ['2019-08-20', 102.51],
                ['2019-08-21', 102.86],
                ['2019-08-22', 102.79],
                ['2019-08-23', 101.45],
                ['2019-08-26', 102.17],
                ['2019-08-27', 102.40],
                ['2019-08-28', 102.74],
                ['2019-08-29', 103.43],
                ['2019-08-30', 103.64],
                ['2019-09-03', 103.53],
                ['2019-09-04', 104.51],
                ['2019-09-05', 104.68],
                ['2019-09-06', 104.70],
                ['2019-09-09', 103.89],
                ['2019-09-10', 103.31],
                ['2019-09-11', 104.05],
                ['2019-09-12', 104.45],
                ['2019-09-13', 103.77],
                ['2019-09-16', 103.80],
                ['2019-09-17', 104.31],
                ['2019-09-18', 104.28],
                ['2019-09-19', 104.27],
                ['2019-09-20', 104.27],
                ['2019-09-23', 104.66],
                ['2019-09-24', 104.08],
                ['2019-09-25', 103.81],
                ['2019-09-26', 103.84],
                ['2019-09-27', 102.98],
                ['2019-09-30', 103.57],
                ['2019-10-01', 102.97],
                ['2019-10-02', 102.21],
                ['2019-10-03', 103.23],
                ['2019-10-04', 104.29],
                ['2019-10-07', 103.60],
                ['2019-10-08', 102.36],
                ['2019-10-09', 103.12],
                ['2019-10-10', 103.21],
                ['2019-10-11', 103.57],
                ['2019-10-14', 103.57],
                ['2019-10-15', 103.99],
                ['2019-10-16', 103.83],
                ['2019-10-17', 104.11],
                ['2019-10-18', 104.03],
                ['2019-10-21', 104.21],
                ['2019-10-22', 103.56],
                ['2019-10-23', 103.54],
                ['2019-10-24', 104.11],
                ['2019-10-25', 104.44],
                ['2019-10-28', 104.66],
                ['2019-10-29', 104.65],
                ['2019-10-30', 105.14],
                ['2019-10-31', 105.56],
                ['2019-11-01', 106.00],
                ['2019-11-04', 105.88],
                ['2019-11-05', 104.99],
                ['2019-11-06', 105.32],
                ['2019-11-07', 104.99],
                ['2019-11-08', 104.92],
                ['2019-11-11', 104.73],
                ['2019-11-12', 105.00],
                ['2019-11-13', 105.45],
                ['2019-11-14', 105.68],
                ['2019-11-15', 106.26],
                ['2019-11-18', 106.21],
                ['2019-11-19', 106.37],
                ['2019-11-20', 106.16],
                ['2019-11-21', 105.54],
                ['2019-11-22', 105.47],
                ['2019-11-25', 106.10],
                ['2019-11-26', 106.51],
                ['2019-11-27', 106.71],
                ['2019-11-29', 106.75],
                ['2019-12-02', 106.02],
                ['2019-12-03', 106.02],
                ['2019-12-04', 106.53],
                ['2019-12-05', 106.60],
                ['2019-12-06', 106.87],
                ['2019-12-09', 106.46],
                ['2019-12-10', 106.54],
                ['2019-12-11', 107.19],
                ['2019-12-12', 107.65],
                ['2019-12-13', 107.95],
                ['2019-12-16', 108.07],
                ['2019-12-17', 108.14],
                ['2019-12-18', 107.95],
                ['2019-12-19', 108.36],
                ['2019-12-20', 109.15],
                ['2019-12-23', 109.23],
                ['2019-12-24', 109.53],
                ['2019-12-26', 109.83],
                ['2019-12-27', 109.91],
                ['2019-12-30', 109.60],
                ['2019-12-31', 109.87],
                ['2020-01-02', 110.61],
                ['2020-01-03', 110.61],
                ['2020-01-06', 110.84],
                ['2020-01-07', 111.11],
                ['2020-01-08', 110.98],
                ['2020-01-09', 111.33],
                ['2020-01-10', 111.38],
                ['2020-01-13', 111.59],
                ['2020-01-14', 111.70],
                ['2020-01-15', 112.02],
                ['2020-01-16', 112.70],
                ['2020-01-17', 113.06],
                ['2020-01-21', 113.31],
                ['2020-01-22', 113.67],
                ['2020-01-23', 113.84],
                ['2020-01-24', 113.59],
                ['2020-01-27', 112.72],
                ['2020-01-28', 113.24],
                ['2020-01-29', 112.94],
                ['2020-01-30', 113.19],
                ['2020-01-31', 111.92],
                ['2020-02-03', 112.23],
                ['2020-02-04', 112.98],
                ['2020-02-05', 113.77],
                ['2020-02-06', 113.95],
                ['2020-02-07', 113.19],
                ['2020-02-10', 113.88],
                ['2020-02-11', 114.38],
                ['2020-02-12', 114.58],
                ['2020-02-13', 115.02],
                ['2020-02-14', 115.44],
                ['2020-02-18', 115.38],
                ['2020-02-19', 116.15],
                ['2020-02-20', 115.87],
                ['2020-02-21', 115.47],
                ['2020-02-24', 113.64],
                ['2020-02-25', 111.13],
                ['2020-02-26', 110.93],
                ['2020-02-27', 108.05],
                ['2020-02-28', 106.56],
                ['2020-03-02', 109.98],
                ['2020-03-03', 109.32],
                ['2020-03-04', 112.65],
                ['2020-03-05', 111.11],
                ['2020-03-06', 110.21],
                ['2020-03-09', 105.13],
                ['2020-03-10', 107.75],
                ['2020-03-11', 104.05],
                ['2020-03-12', 95.94],
                ['2020-03-13', 99.71],
                ['2020-03-16', 91.16],
                ['2020-03-17', 95.74],
                ['2020-03-18', 90.66],
                ['2020-03-19', 90.04],
                ['2020-03-20', 87.82],
                ['2020-03-23', 87.32],
                ['2020-03-24', 94.92],
                ['2020-03-25', 95.16],
                ['2020-03-26', 100.02],
                ['2020-03-27', 98.04],
                ['2020-03-30', 100.46],
                ['2020-03-31', 98.40],
                ['2020-04-01', 95.48],
                ['2020-04-02', 97.82],
                ['2020-04-03', 96.74],
                ['2020-04-06', 102.38],
                ['2020-04-07', 101.96],
                ['2020-04-08', 104.49],
                ['2020-04-09', 106.14],
                ['2020-04-13', 105.71],
                ['2020-04-14', 108.25],
                ['2020-04-15', 107.05],
                ['2020-04-16', 108.05],
                ['2020-04-17', 109.29],
                ['2020-04-20', 108.23],
                ['2020-04-21', 105.67],
                ['2020-04-22', 108.11],
                ['2020-04-23', 107.88],
                ['2020-04-24', 108.76],
                ['2020-04-27', 109.56],
                ['2020-04-28', 109.06],
                ['2020-04-29', 110.77],
                ['2020-04-30', 109.17],
                ['2020-05-01', 107.34],
                ['2020-05-04', 107.66],
                ['2020-05-05', 108.90],
                ['2020-05-06', 108.29],
                ['2020-05-07', 109.81],
                ['2020-05-08', 110.77],
                ['2020-05-11', 110.64],
                ['2020-05-12', 109.13],
                ['2020-05-13', 108.19],
                ['2020-05-14', 109.40],
                ['2020-05-15', 109.33],
                ['2020-05-18', 111.25],
                ['2020-05-19', 110.77],
                ['2020-05-20', 112.00],
                ['2020-05-21', 110.64],
                ['2020-05-22', 111.24],
                ['2020-05-26', 111.44],
                ['2020-05-27', 112.52],
                ['2020-05-28', 112.72],
                ['2020-05-29', 113.98],
                ['2020-06-01', 114.23],
                ['2020-06-02', 114.50],
                ['2020-06-03', 114.93],
                ['2020-06-04', 114.84],
                ['2020-06-05', 116.23],
                ['2020-06-08', 117.01],
                ['2020-06-09', 116.52],
                ['2020-06-10', 116.71],
                ['2020-06-11', 112.29],
                ['2020-06-12', 113.12],
                ['2020-06-15', 113.61],
                ['2020-06-16', 114.71],
                ['2020-06-17', 114.97],
                ['2020-06-18', 114.85],
                ['2020-06-19', 115.15],
                ['2020-06-22', 115.54],
                ['2020-06-23', 116.00],
                ['2020-06-24', 114.00],
                ['2020-06-25', 114.62],
                ['2020-06-26', 113.69],
                ['2020-06-29', 114.58],
                ['2020-06-30', 116.43],
                ['2020-07-01', 116.18],
                ['2020-07-02', 116.75],
                ['2020-07-06', 117.89],
                ['2020-07-07', 117.45],
                ['2020-07-08', 118.03],
                ['2020-07-09', 118.09],
                ['2020-07-10', 118.19],
                ['2020-07-13', 117.46],
                ['2020-07-14', 118.93],
                ['2020-07-15', 119.73],
                ['2020-07-16', 119.42],
                ['2020-07-17', 120.58],
                ['2020-07-20', 121.20],
                ['2020-07-21', 121.55],
                ['2020-07-22', 122.63],
                ['2020-07-23', 122.42],
                ['2020-07-24', 121.89],
                ['2020-07-27', 123.34],
                ['2020-07-28', 122.92],
                ['2020-07-29', 124.31],
                ['2020-07-30', 124.19],
                ['2020-07-31', 124.51],
                ['2020-08-03', 125.14],
                ['2020-08-04', 125.96],
                ['2020-08-05', 126.36],
                ['2020-08-06', 126.54],
                ['2020-08-07', 126.26],
                ['2020-08-10', 125.90],
                ['2020-08-11', 123.65],
                ['2020-08-12', 124.82],
                ['2020-08-13', 125.28],
                ['2020-08-14', 124.97],
                ['2020-08-17', 126.22],
                ['2020-08-18', 126.38],
                ['2020-08-19', 125.09],
                ['2020-08-20', 125.08],
                ['2020-08-21', 124.99],
                ['2020-08-24', 125.15],
                ['2020-08-25', 125.62],
                ['2020-08-26', 126.20],
                ['2020-08-27', 126.03],
                ['2020-08-28', 127.18],
                ['2020-08-31', 127.35],
                ['2020-09-01', 127.87],
                ['2020-09-02', 129.23],
                ['2020-09-03', 126.14],
                ['2020-09-04', 125.38],
                ['2020-09-08', 123.48],
                ['2020-09-09', 125.40],
                ['2020-09-10', 124.38],
                ['2020-09-11', 124.54],
                ['2020-09-14', 125.96],
                ['2020-09-15', 126.53],
                ['2020-09-16', 126.35],
                ['2020-09-17', 125.84],
                ['2020-09-18', 125.13],
                ['2020-09-21', 123.90],
                ['2020-09-22', 124.32],
                ['2020-09-23', 121.94],
                ['2020-09-24', 121.97],
                ['2020-09-25', 123.21],
                ['2020-09-28', 124.75],
                ['2020-09-29', 125.07],
                ['2020-09-30', 125.90],
                ['2020-10-01', 126.65],
                ['2020-10-02', 125.65],
                ['2020-10-05', 127.25],
                ['2020-10-06', 126.63],
                ['2020-10-07', 127.45],
                ['2020-10-08', 128.19],
                ['2020-10-09', 129.64],
                ['2020-10-12', 130.55],
                ['2020-10-13', 129.68],
                ['2020-10-14', 129.62],
                ['2020-10-15', 129.09],
                ['2020-10-16', 129.40],
                ['2020-10-19', 128.40],
                ['2020-10-20', 128.32],
                ['2020-10-21', 128.47],
                ['2020-10-22', 128.53],
                ['2020-10-23', 128.59],
                ['2020-10-26', 127.31],
                ['2020-10-27', 127.19],
                ['2020-10-28', 124.28],
                ['2020-10-29', 124.63],
                ['2020-10-30', 124.24],
                ['2020-11-02', 125.34],
                ['2020-11-03', 126.92],
                ['2020-11-04', 128.51],
                ['2020-11-05', 130.52],
                ['2020-11-06', 131.21],
                ['2020-11-09', 130.19],
                ['2020-11-10', 129.79],
                ['2020-11-11', 130.58],
                ['2020-11-12', 130.19],
                ['2020-11-13', 131.43],
                ['2020-11-16', 132.05],
                ['2020-11-17', 131.63],
                ['2020-11-18', 130.40],
                ['2020-11-19', 130.67],
                ['2020-11-20', 130.61],
                ['2020-11-23', 130.28],
                ['2020-11-24', 130.06],
                ['2020-11-25', 130.01],
                ['2020-11-26', 129.99],
                ['2020-11-27', 130.06],
                ['2020-11-30', 130.19],
                ['2020-12-01', 131.52],
                ['2020-12-02', 131.66],
                ['2020-12-03', 131.76],
                ['2020-12-04', 132.91],
                ['2020-12-07', 133.13],
                ['2020-12-08', 133.44],
                ['2020-12-09', 132.09],
                ['2020-12-10', 132.07],
                ['2020-12-11', 131.85],
                ['2020-12-14', 131.59],
                ['2020-12-15', 132.86],
                ['2020-12-16', 132.83],
                ['2020-12-17', 134.11],
                ['2020-12-18', 134.19],
                ['2020-12-21', 133.52],
                ['2020-12-22', 133.39],
                ['2020-12-23', 133.07],
                ['2020-12-24', 133.51],
                ['2020-12-25', 133.49],
                ['2020-12-28', 133.57],
                ['2020-12-29', 133.48],
                ['2020-12-30', 133.80],
                ['2020-12-31', 134.60],
                ['2021-01-01', 134.58],
                ['2021-01-04', 134.57],
                ['2021-01-05', 135.19],
                ['2021-01-06', 135.73],
                ['2021-01-07', 137.11],
                ['2021-01-08', 136.69],
                ['2021-01-11', 136.32],
                ['2021-01-12', 135.83],
                ['2021-01-13', 136.38],
                ['2021-01-14', 135.96],
                ['2021-01-15', 135.59],
                ['2021-01-18', 135.59],
                ['2021-01-19', 136.54],
                ['2021-01-20', 137.44],
                ['2021-01-21', 137.60],
                ['2021-01-22', 136.85],
                ['2021-01-25', 137.37],
                ['2021-01-26', 136.90],
                ['2021-01-27', 134.01],
                ['2021-01-28', 135.16],
                ['2021-01-29', 134.65],
                ['2021-02-01', 135.92],
                ['2021-02-02', 136.31],
                ['2021-02-03', 135.34],
                ['2021-02-04', 135.13],
                ['2021-02-05', 135.62],
                ['2021-02-08', 137.11],
                ['2021-02-09', 137.10],
                ['2021-02-10', 137.21],
                ['2021-02-11', 138.08],
                ['2021-02-12', 138.28],
                ['2021-02-15', 138.27],
                ['2021-02-16', 137.41],
                ['2021-02-17', 136.74],
                ['2021-02-18', 136.24],
                ['2021-02-19', 136.19],
                ['2021-02-22', 135.29],
                ['2021-02-23', 134.97],
                ['2021-02-24', 135.68],
                ['2021-02-25', 133.40],
                ['2021-02-26', 133.03],
                ['2021-03-01', 134.55],
                ['2021-03-02', 133.54],
                ['2021-03-03', 131.67],
                ['2021-03-04', 129.62],
                ['2021-03-05', 131.18],
                ['2021-03-08', 129.66],
                ['2021-03-09', 131.99],
                ['2021-03-10', 131.88],
                ['2021-03-11', 133.26],
                ['2021-03-12', 132.68],
                ['2021-03-15', 133.96],
                ['2021-03-16', 134.33],
                ['2021-03-17', 134.21],
                ['2021-03-18', 132.70],
                ['2021-03-19', 133.05],
                ['2021-03-22', 134.09],
                ['2021-03-23', 133.22],
                ['2021-03-24', 132.83],
                ['2021-03-25', 133.04],
                ['2021-03-26', 135.05],
                ['2021-03-29', 134.44],
                ['2021-03-30', 133.50],
                ['2021-03-31', 134.49],
                ['2021-04-01', 136.27],
                ['2021-04-02', 136.25],
                ['2021-04-05', 137.37],
                ['2021-04-06', 137.56],
                ['2021-04-07', 137.28],
                ['2021-04-08', 138.06],
                ['2021-04-09', 138.12],
                ['2021-04-12', 137.69],
                ['2021-04-13', 138.44],
                ['2021-04-14', 137.78],
                ['2021-04-15', 139.58],
                ['2021-04-16', 140.02],
                ['2021-04-19', 139.28],
                ['2021-04-20', 139.20],
                ['2021-04-21', 140.70],
                ['2021-04-22', 139.90],
                ['2021-04-23', 140.63],
                ['2021-04-26', 140.61],
                ['2021-04-27', 140.35],
                ['2021-04-28', 139.75],
                ['2021-04-29', 139.73],
                ['2021-04-30', 139.01],
                ['2021-05-03', 138.95],
                ['2021-05-04', 138.68],
                ['2021-05-05', 138.40],
                ['2021-05-06', 139.47],
                ['2021-05-07', 140.38],
                ['2021-05-10', 139.27],
                ['2021-05-11', 138.65],
                ['2021-05-12', 136.53],
                ['2021-05-13', 137.38],
                ['2021-05-14', 138.77],
                ['2021-05-17', 138.48],
                ['2021-05-18', 138.36],
                ['2021-05-19', 138.90],
                ['2021-05-20', 140.11],
                ['2021-05-21', 139.93],
                ['2021-05-24', 140.69],
                ['2021-05-25', 140.86],
                ['2021-05-26', 140.85],
                ['2021-05-27', 140.90],
                ['2021-05-28', 141.50],
                ['2021-05-31', 141.61],
                ['2021-06-01', 140.76],
                ['2021-06-02', 140.99],
                ['2021-06-03', 140.02],
                ['2021-06-04', 141.46],
                ['2021-06-07', 141.21],
                ['2021-06-08', 141.08],
                ['2021-06-09', 141.32],
                ['2021-06-10', 142.12],
                ['2021-06-11', 142.02],
                ['2021-06-14', 142.16],
                ['2021-06-15', 141.80],
                ['2021-06-16', 141.19],
                ['2021-06-17', 140.59],
                ['2021-06-18', 139.42],
                ['2021-06-21', 140.22],
                ['2021-06-22', 140.60],
                ['2021-06-23', 140.69],
                ['2021-06-24', 141.18],
                ['2021-06-25', 141.47],
                ['2021-06-28', 142.17],
                ['2021-06-29', 142.02],
                ['2021-06-30', 142.21],
                ['2021-07-01', 142.53],
                ['2021-07-02', 143.43],
                ['2021-07-05', 143.42],
                ['2021-07-06', 143.96],
                ['2021-07-07', 143.95],
                ['2021-07-08', 143.30],
                ['2021-07-09', 143.88],
                ['2021-07-12', 143.87],
                ['2021-07-13', 143.80],
                ['2021-07-14', 143.96],
                ['2021-07-15', 143.56],
                ['2021-07-16', 143.24],
                ['2021-07-19', 143.03],
                ['2021-07-20', 143.99],
                ['2021-07-21', 144.30],
                ['2021-07-22', 144.43],
                ['2021-07-23', 145.22],
                ['2021-07-26', 144.98],
                ['2021-07-27', 144.77],
                ['2021-07-28', 145.09],
                ['2021-07-29', 146.22],
                ['2021-07-30', 146.66],
                ['2021-08-02', 146.52],
                ['2021-08-03', 147.02],
                ['2021-08-04', 147.20],
                ['2021-08-05', 146.92],
                ['2021-08-06', 145.95],
                ['2021-08-09', 145.41],
                ['2021-08-10', 144.59],
                ['2021-08-11', 144.88],
                ['2021-08-12', 144.92],
                ['2021-08-13', 145.81],
                ['2021-08-16', 146.37],
                ['2021-08-17', 146.25],
                ['2021-08-18', 145.11],
                ['2021-08-19', 145.59],
                ['2021-08-20', 146.08],
                ['2021-08-23', 147.09],
                ['2021-08-24', 147.19],
                ['2021-08-25', 146.90],
                ['2021-08-26', 146.65],
                ['2021-08-27', 147.56],
                ['2021-08-30', 148.06],
                ['2021-08-31', 148.09],
                ['2021-09-01', 148.58],
                ['2021-09-02', 149.08],
                ['2021-09-03', 149.39],
                ['2021-09-06', 149.38],
                ['2021-09-07', 148.45],
                ['2021-09-08', 148.21],
                ['2021-09-09', 147.85],
                ['2021-09-10', 147.71],
                ['2021-09-13', 147.47],
                ['2021-09-14', 147.44],
                ['2021-09-15', 147.85],
                ['2021-09-16', 146.88],
                ['2021-09-17', 146.46],
                ['2021-09-20', 145.26],
                ['2021-09-21', 145.57],
                ['2021-09-22', 146.30],
                ['2021-09-23', 146.62],
                ['2021-09-24', 146.12],
                ['2021-09-27', 145.40],
                ['2021-09-28', 143.01],
                ['2021-09-29', 143.04],
                ['2021-09-30', 142.54],
                ['2021-10-01', 142.96],
                ['2021-10-04', 141.59],
                ['2021-10-05', 142.16],
                ['2021-10-06', 142.59],
                ['2021-10-07', 143.33],
                ['2021-10-08', 142.89],
                ['2021-10-11', 141.86],
                ['2021-10-12', 141.79],
                ['2021-10-13', 142.45],
                ['2021-10-14', 144.28],
                ['2021-10-15', 144.15],
                ['2021-10-18', 144.17],
                ['2021-10-19', 145.26],
                ['2021-10-20', 145.72],
                ['2021-10-21', 146.24],
                ['2021-10-22', 146.73],
                ['2021-10-25', 146.76],
                ['2021-10-26', 146.51],
                ['2021-10-27', 146.06],
                ['2021-10-28', 146.99],
                ['2021-10-29', 146.97],
                ['2021-11-01', 147.68],
                ['2021-11-02', 148.07],
                ['2021-11-03', 148.08],
                ['2021-11-04', 149.24],
                ['2021-11-05', 149.02],
                ['2021-11-08', 149.74],
                ['2021-11-09', 149.68],
                ['2021-11-10', 149.43],
                ['2021-11-11', 149.79],
                ['2021-11-12', 150.45],
                ['2021-11-15', 150.30],
                ['2021-11-16', 150.93],
                ['2021-11-17', 150.73],
                ['2021-11-18', 150.90],
                ['2021-11-19', 150.86],
                ['2021-11-22', 149.29],
                ['2021-11-23', 148.43],
                ['2021-11-24', 148.77],
                ['2021-11-25', 148.73],
                ['2021-11-26', 147.88],
                ['2021-11-29', 148.89],
                ['2021-11-30', 147.88],
                ['2021-12-01', 147.22],
                ['2021-12-02', 147.63],
                ['2021-12-03', 147.53],
                ['2021-12-06', 148.12],
                ['2021-12-07', 150.14],
                ['2021-12-08', 150.29],
                ['2021-12-09', 149.39],
                ['2021-12-10', 150.09],
                ['2021-12-13', 149.66],
                ['2021-12-14', 148.55],
                ['2021-12-15', 149.95],
                ['2021-12-16', 149.60],
                ['2021-12-17', 149.27],
                ['2021-12-20', 148.36],
                ['2021-12-21', 149.66],
                ['2021-12-22', 150.60],
                ['2021-12-23', 151.24],
                ['2021-12-24', 151.23],
                ['2021-12-27', 152.29],
                ['2021-12-28', 151.95],
                ['2021-12-29', 152.29],
                ['2021-12-30', 152.27],
                ['2021-12-31', 152.45],
                ['2022-01-03', 152.65],
                ['2022-01-04', 151.93],
                ['2022-01-05', 150.46],
                ['2022-01-06', 149.52],
                ['2022-01-07', 148.36],
                ['2022-01-10', 148.36],
                ['2022-01-11', 149.29],
                ['2022-01-12', 149.74],
                ['2022-01-13', 148.43],
                ['2022-01-14', 148.41],
                ['2022-01-17', 148.32],
                ['2022-01-18', 146.19],
                ['2022-01-19', 145.73],
                ['2022-01-20', 144.84],
                ['2022-01-21', 143.80],
                ['2022-01-24', 143.99],
                ['2022-01-25', 142.35],
                ['2022-01-26', 141.67],
                ['2022-01-27', 139.89],
                ['2022-01-28', 141.48],
                ['2022-01-31', 144.03],
                ['2022-02-01', 144.37],
                ['2022-02-02', 145.70],
                ['2022-02-03', 144.14],
                ['2022-02-04', 144.03],
                ['2022-02-07', 143.88],
                ['2022-02-08', 144.84],
                ['2022-02-09', 146.51],
                ['2022-02-10', 144.50],
                ['2022-02-11', 143.32],
                ['2022-02-14', 142.90],
                ['2022-02-15', 144.30],
                ['2022-02-16', 144.38],
                ['2022-02-17', 143.12],
                ['2022-02-18', 142.62],
                ['2022-02-22', 142.37],
                ['2022-02-23', 141.22],
                ['2022-02-24', 142.61],
                ['2022-02-25', 143.74],
                ['2022-02-28', 143.93],
                ['2022-03-01', 143.49],
                ['2022-03-02', 144.00],
                ['2022-03-03', 143.89],
                ['2022-03-04', 144.00],
                ['2022-03-07', 142.83],
                ['2022-03-08', 142.57],
                ['2022-03-09', 143.14],
                ['2022-03-10', 142.78],
                ['2022-03-11', 142.05],
                ['2022-03-14', 141.23],
                ['2022-03-15', 142.13],
                ['2022-03-16', 143.35],
                ['2022-03-17', 144.01],
                ['2022-03-18', 144.42],
                ['2022-03-21', 144.30],
                ['2022-03-22', 144.32],
                ['2022-03-23', 143.65],
                ['2022-03-24', 144.56],
                ['2022-03-25', 144.62],
                ['2022-03-28', 144.79],
                ['2022-03-29', 145.63],
                ['2022-03-30', 145.47],
                ['2022-03-31', 145.00],
                ['2022-04-01', 144.96],
                ['2022-04-04', 145.12],
                ['2022-04-05', 144.29],
                ['2022-04-06', 144.07],
                ['2022-04-07', 144.52],
                ['2022-04-08', 144.28],
                ['2022-04-11', 143.49],
                ['2022-04-12', 143.37],
                ['2022-04-13', 144.04],
                ['2022-04-14', 143.25],
                ['2022-04-18', 143.18],
                ['2022-04-19', 143.67],
                ['2022-04-20', 144.16],
                ['2022-04-21', 143.32],
                ['2022-04-22', 141.80],
                ['2022-04-25', 141.86],
                ['2022-04-26', 140.57],
                ['2022-04-27', 140.21],
                ['2022-04-28', 141.45],
                ['2022-04-29', 140.08],
                ['2022-05-02', 139.94],
                ['2022-05-03', 140.21],
                ['2022-05-04', 141.66],
                ['2022-05-05', 139.87],
                ['2022-05-06', 139.30],
                ['2022-05-09', 137.39],
                ['2022-05-10', 137.58],
                ['2022-05-11', 137.15],
                ['2022-05-12', 137.11],
                ['2022-05-13', 138.20],
                ['2022-05-16', 138.20],
                ['2022-05-17', 139.22],
                ['2022-05-18', 137.43],
                ['2022-05-19', 137.59],
                ['2022-05-20', 137.86],
                ['2022-05-23', 138.42],
                ['2022-05-24', 138.34],
                ['2022-05-25', 138.52],
                ['2022-05-26', 139.32],
                ['2022-05-27', 140.71],
                ['2022-05-31', 140.14],
                ['2022-06-01', 139.49],
                ['2022-06-02', 140.77],
                ['2022-06-03', 139.74],
                ['2022-06-06', 139.54],
                ['2022-06-07', 140.08],
                ['2022-06-08', 139.30],
                ['2022-06-09', 137.97],
                ['2022-06-10', 137.08],
                ['2022-06-13', 134.54],
                ['2022-06-14', 133.95],
                ['2022-06-15', 134.82],
                ['2022-06-16', 133.52],
                ['2022-06-17', 133.60],
                ['2022-06-21', 134.48],
                ['2022-06-22', 134.77],
                ['2022-06-23', 135.32],
                ['2022-06-24', 136.61],
                ['2022-06-27', 136.45],
                ['2022-06-28', 135.54],
                ['2022-06-29', 135.49],
                ['2022-06-30', 135.30],
                ['2022-07-01', 135.46],
                ['2022-07-05', 135.14],
                ['2022-07-06', 135.00],
                ['2022-07-07', 135.64],
                ['2022-07-08', 135.60],
                ['2022-07-11', 135.10],
                ['2022-07-12', 134.70],
                ['2022-07-13', 134.70],
                ['2022-07-14', 134.57],
                ['2022-07-15', 135.42],
                ['2022-07-18', 134.85],
                ['2022-07-19', 136.16],
                ['2022-07-20', 136.43],
                ['2022-07-21', 137.49],
                ['2022-07-22', 137.15],
                ['2022-07-25', 137.01],
                ['2022-07-26', 136.84],
                ['2022-07-27', 138.12],
                ['2022-07-28', 139.04],
                ['2022-07-29', 139.35],
                ['2022-08-01', 139.48],
                ['2022-08-02', 139.14],
                ['2022-08-03', 139.90],
                ['2022-08-04', 140.27],
                ['2022-08-05', 140.01],
                ['2022-08-08', 140.09],
                ['2022-08-09', 139.52],
                ['2022-08-10', 140.56],
                ['2022-08-11', 140.34],
                ['2022-08-12', 141.41],
                ['2022-08-15', 141.49],
                ['2022-08-16', 141.37],
                ['2022-08-17', 140.56],
                ['2022-08-18', 140.84],
                ['2022-08-19', 140.01],
                ['2022-08-22', 138.79],
                ['2022-08-23', 138.58],
                ['2022-08-24', 138.69],
                ['2022-08-25', 139.68],
                ['2022-08-26', 137.67],
                ['2022-08-29', 137.14],
                ['2022-08-30', 136.59],
                ['2022-08-31', 136.28],
                ['2022-09-01', 136.16],
                ['2022-09-02', 135.79],
                ['2022-09-06', 135.36],
                ['2022-09-07', 136.52],
                ['2022-09-08', 136.96],
                ['2022-09-09', 137.77],
                ['2022-09-12', 138.18],
                ['2022-09-13', 136.08],
                ['2022-09-14', 136.16],
                ['2022-09-15', 135.30],
                ['2022-09-16', 135.21],
                ['2022-09-19', 135.26],
                ['2022-09-20', 134.50],
                ['2022-09-21', 134.00],
                ['2022-09-22', 133.30],
                ['2022-09-23', 132.59],
                ['2022-09-26', 131.49],
                ['2022-09-27', 131.32],
                ['2022-09-28', 132.56],
                ['2022-09-29', 131.71],
                ['2022-09-30', 131.45],
                ['2022-10-03', 133.10],
                ['2022-10-04', 134.73],
                ['2022-10-05', 134.56],
                ['2022-10-06', 134.00],
                ['2022-10-07', 132.36],
                ['2022-10-10', 131.51],
                ['2022-10-11', 131.12],
                ['2022-10-12', 130.86],
                ['2022-10-13', 131.70],
                ['2022-10-14', 130.46],
                ['2022-10-17', 131.42],
                ['2022-10-18', 131.85],
                ['2022-10-19', 131.19],
                ['2022-10-20', 130.82],
                ['2022-10-21', 132.02],
                ['2022-10-24', 132.44],
                ['2022-10-25', 133.33],
                ['2022-10-26', 133.46],
                ['2022-10-27', 133.18],
                ['2022-10-28', 134.17],
                ['2022-10-31', 133.84],
                ['2022-11-01', 134.16],
                ['2022-11-02', 132.89],
                ['2022-11-03', 132.26],
                ['2022-11-04', 133.57],
                ['2022-11-07', 133.96],
                ['2022-11-08', 134.70],
                ['2022-11-09', 133.96],
                ['2022-11-10', 137.14],
                ['2022-11-11', 137.56],
                ['2022-11-14', 137.24],
                ['2022-11-15', 137.93],
                ['2022-11-16', 137.41],
                ['2022-11-17', 137.13],
                ['2022-11-18', 137.29],
                ['2022-11-21', 137.15],
                ['2022-11-22', 137.79],
                ['2022-11-23', 138.28],
                ['2022-11-25', 138.23],
                ['2022-11-28', 137.42],
                ['2022-11-29', 137.39],
                ['2022-11-30', 138.76],
                ['2022-12-01', 139.22],
                ['2022-12-02', 139.10],
                ['2022-12-05', 138.10],
                ['2022-12-06', 137.63],
                ['2022-12-07', 138.01],
                ['2022-12-08', 138.61],
                ['2022-12-09', 138.23],
                ['2022-12-12', 138.76],
                ['2022-12-13', 139.28],
                ['2022-12-14', 138.97],
                ['2022-12-15', 137.54],
                ['2022-12-16', 137.13],
                ['2022-12-19', 136.85],
                ['2022-12-20', 137.00],
                ['2022-12-21', 137.67],
                ['2022-12-22', 136.84],
                ['2022-12-23', 136.94],
                ['2022-12-27', 136.84],
                ['2022-12-28', 136.28],
                ['2022-12-29', 137.21],
                ['2022-12-30', 137.13],
                ['2023-01-03', 137.21],
                ['2023-01-04', 137.97],
                ['2023-01-05', 137.27],
                ['2023-01-06', 138.66],
                ['2023-01-09', 138.66],
                ['2023-01-10', 139.10],
                ['2023-01-11', 139.42],
                ['2023-01-12', 139.71],
                ['2023-01-13', 140.01],
                ['2023-01-17', 139.74],
                ['2023-01-18', 139.20],
                ['2023-01-19', 138.95],
                ['2023-01-20', 139.56],
                ['2023-01-23', 140.34],
                ['2023-01-24', 140.10],
                ['2023-01-25', 140.07],
                ['2023-01-26', 140.14],
                ['2023-01-27', 139.90],
                ['2023-01-30', 139.25],
                ['2023-01-31', 139.98],
                ['2023-02-01', 141.24],
                ['2023-02-02', 141.33],
                ['2023-02-03', 140.33],
                ['2023-02-06', 139.84],
                ['2023-02-07', 140.50],
                ['2023-02-08', 139.99],
                ['2023-02-09', 139.49],
                ['2023-02-10', 139.52],
                ['2023-02-13', 139.90],
                ['2023-02-14', 139.81],
                ['2023-02-15', 139.77],
                ['2023-02-16', 139.20],
                ['2023-02-17', 139.26],
                ['2023-02-21', 138.28],
                ['2023-02-22', 138.09],
                ['2023-02-23', 138.51],
                ['2023-02-24', 137.82],
                ['2023-02-27', 137.91],
                ['2023-02-28', 137.96],
                ['2023-03-01', 137.43],
                ['2023-03-02', 137.45],
                ['2023-03-03', 137.94],
                ['2023-03-06', 137.21],
                ['2023-03-07', 136.06],
                ['2023-03-08', 136.00],
                ['2023-03-09', 135.33],
                ['2023-03-10', 134.90],
                ['2023-03-13', 135.18],
                ['2023-03-14', 135.39],
                ['2023-03-15', 135.10],
                ['2023-03-16', 135.51],
                ['2023-03-17', 135.30],
                ['2023-03-20', 135.39],
                ['2023-03-21', 134.87],
                ['2023-03-22', 134.35],
                ['2023-03-23', 134.44],
                ['2023-03-24', 134.18],
                ['2023-03-27', 133.64],
                ['2023-03-28', 133.29],
                ['2023-03-29', 133.63],
                ['2023-03-30', 133.84],
                ['2023-03-31', 133.98],
                ['2023-04-03', 134.14],
                ['2023-04-04', 134.29],
                ['2023-04-05', 134.30],
                ['2023-04-06', 134.25],
                ['2023-04-10', 134.23],
                ['2023-04-11', 134.38],
                ['2023-04-12', 134.31],
                ['2023-04-13', 134.90],
                ['2023-04-14', 134.40],
                ['2023-04-17', 134.44],
                ['2023-04-18', 134.48],
                ['2023-04-19', 134.51],
                ['2023-04-20', 134.49],
                ['2023-04-21', 134.41],
                ['2023-04-24', 134.58],
                ['2023-04-25', 134.03],
                ['2023-04-26', 133.64],
                ['2023-04-27', 134.01],
                ['2023-04-28', 134.66],
                ['2023-05-01', 134.69],
                ['2023-05-02', 134.75],
                ['2023-05-03', 134.76],
                ['2023-05-04', 134.62],
                ['2023-05-05', 134.92],
                ['2023-05-08', 134.86],
                ['2023-05-09', 134.65],
                ['2023-05-10', 134.93],
                ['2023-05-11', 134.69],
                ['2023-05-12', 134.72],
                ['2023-05-15', 135.07],
                ['2023-05-16', 134.41],
                ['2023-05-17', 134.65],
                ['2023-05-18', 134.80],
                ['2023-05-19', 134.90],
                ['2023-05-22', 134.86],
                ['2023-05-23', 134.28],
                ['2023-05-24', 133.70],
                ['2023-05-25', 134.06],
                ['2023-05-26', 134.93],
                ['2023-05-30', 134.97],
                ['2023-05-31', 134.99],
                ['2023-06-01', 135.44],
                ['2023-06-02', 135.45],
                ['2023-06-05', 135.29],
                ['2023-06-06', 135.27],
                ['2023-06-07', 134.83],
                ['2023-06-08', 135.26],
                ['2023-06-09', 135.21],
                ['2023-06-12', 135.75],
                ['2023-06-13', 136.07],
                ['2023-06-14', 136.34],
                ['2023-06-15', 136.87],
                ['2023-06-16', 136.71],
                ['2023-06-20', 136.23],
                ['2023-06-21', 135.87],
                ['2023-06-22', 135.74],
                ['2023-06-23', 135.36],
                ['2023-06-26', 135.45],
                ['2023-06-27', 135.82],
                ['2023-06-28', 135.59],
                ['2023-06-29', 135.67],
                ['2023-06-30', 136.39],
                ['2023-07-03', 136.42],
                ['2023-07-05', 135.98],
                ['2023-07-06', 135.51],
                ['2023-07-07', 135.43],
                ['2023-07-10', 135.94],
                ['2023-07-11', 136.20],
                ['2023-07-12', 136.82],
                ['2023-07-13', 137.20],
                ['2023-07-14', 136.97],
                ['2023-07-17', 137.22],
                ['2023-07-18', 137.42],
                ['2023-07-19', 137.37],
                ['2023-07-20', 137.03],
                ['2023-07-21', 137.27],
                ['2023-07-24', 137.17],
                ['2023-07-25', 137.44],
                ['2023-07-26', 137.36],
                ['2023-07-27', 136.85],
                ['2023-07-28', 137.30],
                ['2023-07-31', 137.32],
                ['2023-08-01', 136.95],
                ['2023-08-02', 136.40],
                ['2023-08-03', 136.14],
                ['2023-08-04', 136.12],
                ['2023-08-07', 136.39],
                ['2023-08-08', 136.05],
                ['2023-08-09', 135.86],
                ['2023-08-10', 135.71],
                ['2023-08-11', 135.52],
                ['2023-08-14', 135.72],
                ['2023-08-15', 135.28],
                ['2023-08-16', 134.82],
                ['2023-08-17', 134.45],
                ['2023-08-18', 134.55],
                ['2023-08-21', 134.78],
                ['2023-08-22', 134.71],
                ['2023-08-23', 135.31],
                ['2023-08-24', 134.70],
                ['2023-08-25', 134.93],
                ['2023-08-28', 135.24],
                ['2023-08-29', 135.95],
                ['2023-08-30', 136.06],
                ['2023-08-31', 135.93],
            ]
        );
    }
    if (document.getElementById('apexcharts-RoundedChartIvyMoney')) {
        loadApexCharRounded(
            'RoundedChartIvyMoney',
            '#43d85c',
            [100],
            ['Hotovost']
        );
    }
    if (document.getElementById('apexcharts-RoundedChartIvyBalanced')) {
        loadApexCharRounded(
            'RoundedChartIvyBalanced',
            '#fab800',
            [20.0, 15.0, 11.5, 9.0, 6.5, 6.0, 5.5, 3.5, 2.0, 16.0, 5.0],
            ['Nemovitosti', 'Technologie', 'Komodity', 'Zdravotnictví', 'Státní cenné papíry', 'Finanční služby', 'Fixní výnos', 'Průmysl', 'Spotřební zboží necyklické', 'Ostatní', 'Hotovost']
        );
    }
    if (document.getElementById('apexcharts-RoundedChartIvyDynamic')) {
        loadApexCharRounded(
            'RoundedChartIvyDynamic',
            '#bd5574',
            [20.0, 11.5, 10.5, 10.0, 8.5, 7.0, 5.5, 3.5, 2.0, 17.0, 4.5],
            ['Nemovitosti', 'Technologie', 'Komodity', 'Zdravotnictví', 'Státní cenné papíry', 'Finanční služby', 'Fixní výnos', 'Průmysl', 'Spotřební zboží necyklické', 'Ostatní', 'Hotovost']
        );
    }
}