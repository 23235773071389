//document ready
document.addEventListener('DOMContentLoaded', () => {
    underNavShowAfterScroll();
    bootstrapModalCustom();
});

//window loaded
window.addEventListener('load', () => {
    scrollToFormError();
    scrollToAnchor();
});

// animated scroll to form first error on the page
let scrollToFormError = () => {
    if (document.querySelector('#contact-form .error')) {
        window.scrollTo({
            top: document.querySelector('#kontakt').offsetTop - 136,
            behavior: 'smooth'
        });
        setTimeout(() => {
            document.querySelector('#contact-form-switch').click();
        }, '1000');
    }
}

//animated scroll to #anchor on the same page
let scrollToAnchor = () => {
    //selects every <a> element whose href attribute value begins with '#' and exclude anchors with href exactly equal to '#'
    document.querySelectorAll('a[href^="#"]:not(a[href="#"])').forEach(anchor => {
        // if target exists
        if (document.querySelector(anchor.getAttribute('href'))) {
            // add event click on target
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                // hide menu after click on #anchor
                if (document.querySelector('#navbarNav') && document.querySelector('.navbar-toggler')) {
                    if (document.querySelector('#navbarNav').classList.contains('show')) {
                        document.querySelector('.navbar-toggler').click();
                    }
                }

                // set #hash to url and sroll
                window.location.hash = this.getAttribute('href');
                window.scrollTo({
                    top: document.querySelector(this.getAttribute('href')).offsetTop - 136,
                    behavior: 'smooth'
                });
            });
        }
    });

    //if window with #hash loaded and there isnt form error or form result (alert)
    if (!(document.querySelector('#contact-form .error'))) {
        if (window.location.hash.length > 0) {
            let hash = window.location.hash;

            // if hash(#anchor) exists
            if (document.querySelector(hash)) {
                window.scrollTo({
                    top: document.querySelector(hash).offsetTop - 136,
                    behavior: 'smooth'
                });
            }
        }
    }

}

//under-nav show after scrolled 100vh
let underNavShowAfterScroll = () => {
    if (document.querySelector('.js-under-nav')) {
        const elScrollable = document.querySelector("html");
        const elNav = document.querySelector(".js-under-nav");
        const elNavTarget = document.querySelector(".js-under-nav-target");

        let handleNav = () => {
            const elNavTargetOffsetTop = elNavTarget.offsetTop + elNavTarget.offsetParent.offsetTop + elNavTarget.clientHeight - 130;
            const scrollTop = elScrollable.scrollTop;

            if (scrollTop >= elNavTargetOffsetTop) {
                elNav.classList.add('active');
            } else {
                elNav.classList.remove('active');
            }
        };

        addEventListener("scroll", handleNav);
        addEventListener("resize", handleNav);
        //handleNav();
    }
}

// apexcharts charts init
let loadApexChart = (idOfChart, titleOfProduct, chartColor1, GradientColorFrom, GradientColorTo, strokeStyle, dateOfXlane, arrayOfvalues) => {

    let firstDateOfArrayOfValues = arrayOfvalues.slice(0)[0][0];
    let lastDateOfArrayOfValues = arrayOfvalues.slice(-1)[0][0];
    let lastValueOfArrayOfValues = arrayOfvalues.slice(-1)[0][1];

    // chart
    let optionsOfChart = {
        series: [{
            name: titleOfProduct,
            data: arrayOfvalues,
        }],
        grid: { padding: { top: 20, right: 20, bottom: 56, left: 20 } },
        stroke: { curve: strokeStyle, width: 3 },
        chart: { id: `-${idOfChart}-area-datetime`, type: 'line', height: 420, animations: { enabled: false }, zoom: { enabled: false }, toolbar: { show: false }, defaultLocale: 'cs', locales: [{ "name": "cs", "options": { "months": ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"], "shortMonths": ["Led", "Úno", "Bře", "Dub", "Kvě", "Čvn", "Čvc", "Srp", "Zář", "Říj", "Lis", "Pro"], "days": ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"], "shortDays": ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"], "toolbar": { "exportToSVG": "Stáhnout SVG", "exportToPNG": "Stáhnout PNG", "exportToCSV": "Stáhnout CSV", "menu": "Menu", "selection": "Vybrat", "selectionZoom": "Zoom: Vybrat", "zoomIn": "Zoom: Přiblížit", "zoomOut": "Zoom: Oddálit", "pan": "Přesouvat", "reset": "Resetovat" } } }] },
        annotations: {
            xaxis: [{
                x: new Date(dateOfXlane).getTime(), opacity: 1, strokeDashArray: 1, borderColor: '#e3e3e3',
            }],
            points: [{ x: new Date(lastDateOfArrayOfValues).getTime(), y: lastValueOfArrayOfValues, marker: { size: 6, fillColor: '#ffffff', strokeColor: chartColor1, radius: 4, cssClass: 'apexcharts-custom-point' }, label: { borderColor: chartColor1, borderRadius: 3, textAnchor: 'end', offsetX: -10, offsetY: -10, style: { fontFamily: 'Montserrat, Arial, sans-serif', color: '#ffffff', background: chartColor1, fontWeight: '700', fontSize: '20px', padding: { left: 10, right: 10, top: 5, bottom: 5, } }, text: `${(lastValueOfArrayOfValues.toFixed(2)).replace('.', ',')} %`, } }]
        },
        dataLabels: { enabled: false },
        //title: { text: titleOfProduct, align: 'left', offsetX: 10, style: { fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat, Arial, sans-serif', color: '#484e51' } },
        xaxis: { type: 'datetime', min: new Date(firstDateOfArrayOfValues).getTime(), max: new Date(lastDateOfArrayOfValues).getTime(), tickPlacement: 'on', labels: { show: true, rotate: -90, rotateAlways: true, offsetY: 10, style: { fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat, Arial, sans-serif', colors: ['#484e51'] } } },
        yaxis: [{ opposite: false, name: titleOfProduct, forceNiceScale: true, labels: { show: true, formatter: (value) => { return ((value.toFixed(2)) + ' %').replace('.', ',') }, style: { fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat, Arial, sans-serif', colors: ['#484e51'] } } }],
        tooltip: { enabled: true, x: { format: 'dd MMM yyyy' }, style: { fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat, Arial, sans-serif' } },
        colors: [chartColor1],
        fill: { type: 'gradient', colors: [GradientColorFrom], gradient: { shade: 'dark', gradientToColors: [GradientColorTo], shadeIntensity: 1, type: 'horizontal', opacityFrom: 1, opacityTo: 1, stops: [0, 100, 100, 100] }, },
    };
    let bigChart = new ApexCharts(document.getElementById(`apexcharts-${idOfChart}`), optionsOfChart);
    bigChart.render();
}

let loadApexCharRounded = (idOfChart, chartColor, arrayOfValues, arrayOfLabels) => {

    let optionsOfRoundedChart = {
        series: arrayOfValues,
        labels: arrayOfLabels,
        chart: {
            redrawOnWindowResize: true,
            width: '100%',
            type: 'donut'
        },
        grid: { padding: { top: 0, right: 0, bottom: 0, left: 0 } },
        theme: {
            monochrome: {
                enabled: true,
                color: chartColor,
                shadeTo: 'dark',
                shadeIntensity: 0.50
            }
        },
        //colors:['#292669', '#974c8f', '#bd5574', '#e94f1a', '#f2bb15', '#00d122', '#3c914a', '#59d5e3', '#7ea3eb', '#888f8f'],
        plotOptions: {
            pie: {
                startAngle: 0,
                donut: {
                    size: '54%',
                }
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter(val) {
                    return [
                        (((val===100) ? '' : '~') + val.toFixed(1) + ' %').replace(".", ",")
                    ]
                }
            },
            style: { fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat, Arial, sans-serif', color: '#484e51' }
        },
        stroke: {
            width: 2,
        },
        dataLabels: {
            enabled: true,
            dropShadow: { enabled: false },
            style: { fontSize: '13px', fontWeight: '700', fontFamily: 'Montserrat, Arial, sans-serif', colors: ['#ffffff'] },
            formatter(val) {
                return [
                    (((val===100) ? '' : '~') + val.toFixed(1) + ' %').replace(".", ",")
                ]
            },
        },
        legend: {
            show: false,
            position: 'bottom'
        }
    };

    let roundedChart = new ApexCharts(document.getElementById(`apexcharts-${idOfChart}`), optionsOfRoundedChart);
    roundedChart.render();
}


// bootstrap modal custom
let bootstrapModalCustom = () => {
    if ((document.getElementById('BootstrapCustomModal')) && (document.querySelector('.js-table-loadto-modal-header')) && (document.querySelector('.js-table-loadto-modal-body'))) {

        let customModal = document.getElementById('BootstrapCustomModal');

        customModal.addEventListener('show.bs.modal', function (event) {

            // Button that triggered the modal
            let trigger = event.relatedTarget

            // Extract info from data-bs-* attributes
            let highlight = trigger.getAttribute('data-bs-highlight');

            //load content
            let contentHeaderInnerHtml = document.querySelector('.js-table-loadto-modal-header').innerHTML;
            let contentBodyInnerHtml = document.querySelector('.js-table-loadto-modal-body').innerHTML;

            // Update the modal's content.
            let modalHeader = customModal.querySelector('.modal-header .js-modal-header');
            let modalBody = customModal.querySelector('.modal-body .js-modal-body');

            modalHeader.innerHTML = contentHeaderInnerHtml;
            modalBody.innerHTML = contentBodyInnerHtml;

            modalBody.querySelector('table').classList.add(highlight);
        });
    }
}
